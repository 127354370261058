import { forwardRef } from 'react';
import Icon, { IconProps } from '../Icon';
const BrowserRefreshIcon = forwardRef<SVGSVGElement, IconProps>(
  (props, ref) => (
    <Icon ref={ref} viewBox="0 0 24 24" fill="currentColor" {...props}>
      <path
        fillRule="evenodd"
        d="M19.65 12c-.525 0-1.05.375-1.05.9a6.721 6.721 0 0 1-1.65 4.05 6.289 6.289 0 0 1-3.825 2.1c-1.5.225-3 0-4.275-.675-1.35-.675-2.325-1.8-3-3.225-.6-1.35-.75-2.925-.375-4.35a6.566 6.566 0 0 1 2.4-3.6C9.075 6.3 10.5 5.775 12 5.85l2.25.075-.9.9c-.375.375-.375 1.05 0 1.425s1.05.375 1.425 0L17.25 5.7c.375-.375.375-1.05 0-1.425L14.775 1.8c-.375-.375-1.05-.375-1.425 0s-.375 1.05 0 1.425l.675.675-1.95-.075c-1.95 0-3.825.6-5.4 1.8-1.575 1.2-2.625 2.85-3.15 4.725-.45 1.875-.3 3.9.45 5.625.75 1.8 2.175 3.225 3.825 4.125 1.725.9 3.675 1.2 5.625.9 1.95-.375 3.675-1.275 4.95-2.775 1.275-1.425 2.1-3.3 2.175-5.25.075-.45-.3-.975-.9-.975Z"
        clipRule="evenodd"
      />
    </Icon>
  )
);
export default BrowserRefreshIcon;
