import { forwardRef } from 'react';
import Icon, { IconProps } from '../Icon';
const ActivityIcon = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Icon ref={ref} viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M9 2a1 1 0 0 1 .949.684l5.05 15.154 2.052-6.154A1 1 0 0 1 18 11h4a1 1 0 0 1 0 2h-3.28l-2.771 8.316a1 1 0 0 1-1.898 0L9 6.162l-2.051 6.154a1 1 0 0 1-.95.684H2a1 1 0 1 1 0-2h3.28l2.772-8.316A1 1 0 0 1 9 2Z"
      clipRule="evenodd"
    />
  </Icon>
));
export default ActivityIcon;
