import { Box, Fade, Stack, Typography } from '@mui/material';
import CircularProgress from '@watershed/ui-core/components/CircularProgress';
import { AnimationState } from '../../lottie/BigLoading';
import usePrevious from '../../../hooks/usePrevious';
import { useEffect, useState } from 'react';
import Image from 'next/image';
import fileProccessingIcon from '../../../svg/FileProcessingIcon.svg';

/**
 * Internal component for `FileUploadDropzone` that handles the loading state of the drop area, when
 * we're waiting for a file to upload. Supports a compact mode.
 */
export function DropAreaLoading({
  isCompact,
  inProgress,
  progressMessage,
}: {
  isCompact: boolean;
  progressMessage: LocalizedString;
  inProgress: boolean;
}) {
  const prevInProgress = usePrevious(inProgress);
  const [loadingAnimationState, setLoadingAnimationState] =
    useState<AnimationState | null>(null);

  useEffect(() => {
    if (inProgress && !prevInProgress) {
      setLoadingAnimationState(AnimationState.InProgress);
    }

    if (!inProgress && prevInProgress) {
      setLoadingAnimationState(null);
    }
  }, [inProgress, prevInProgress]);

  const loaderContent = isCompact ? (
    <Stack gap={1} direction="row" alignItems="center" height="fill-available">
      <CircularProgress size={16} />
      {progressMessage}
    </Stack>
  ) : (
    <Stack
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        gap: 1,
        padding: '24px 64px',
        width: '100%',
      }}
    >
      <Image
        src={fileProccessingIcon}
        alt="File uploading"
        width={40}
        height={40}
      />

      <Stack width="100%">
        <Typography variant="body1">{progressMessage}</Typography>
      </Stack>
    </Stack>
  );
  return (
    <Fade in={loadingAnimationState !== null}>
      <Box
        sx={(theme) => ({
          position: 'absolute',
          inset: 0,
          backgroundColor: isCompact
            ? theme.palette.background.paper
            : theme.palette.panel,
          zIndex: 1,
          minHeight: 0,
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'center',
          gap: 1,
        })}
      >
        {loaderContent}
      </Box>
    </Fade>
  );
}
