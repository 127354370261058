import { forwardRef } from 'react';
import Icon, { IconProps } from '../Icon';
const ReportIcon = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Icon ref={ref} viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M7 1a4 4 0 0 0-4 4v13a1 1 0 0 0 2 0V5a2 2 0 0 1 2-2h6v5a1 1 0 0 0 1 1h5.001v11a1 1 0 0 1-1 1h-1v-4.2a1 1 0 1 0-2 0V21H13v-6.6a1 1 0 1 0-2 0V21H9v-9.6a1 1 0 1 0-2 0V21H4a1 1 0 0 0 0 2h14.001a3 3 0 0 0 3-3V8a.998.998 0 0 0-.305-.718l-5.695-5.696A2 2 0 0 0 13.587 1H7Zm10.587 6L15 4.414V7h2.586Z"
      clipRule="evenodd"
    />
  </Icon>
));
export default ReportIcon;
