import { forwardRef } from 'react';
import Icon, { IconProps } from '../Icon';
const CleanPowerIcon = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Icon ref={ref} viewBox="0 0 24 25" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M14.113 1.043h-.003c-.923-.2-1.861.28-2.243 1.14l-2.2 4.907a2.973 2.973 0 0 0-.209 1.772L2.3 11.966a1.995 1.995 0 0 0-1.232 2.5l.38 1.18.003.01a2.009 2.009 0 0 0 2.084 1.37l.017-.001 5.346-.55.03-.003L8.058 23H1a1 1 0 1 0 0 2h22a1 1 0 0 0 0-2h-7.585l-.958-4.472 2.35 1.869c.818.711 2.06.647 2.795-.167l.829-.918c.651-.716.67-1.753.151-2.501l-.012-.017-3.15-4.36-.007-.01a3.052 3.052 0 0 0-1.393-1.047l.86-7.76a2.01 2.01 0 0 0-1.543-2.313l-1.224-.261ZM10.076 23h3.294l-1.348-6.29a.997.997 0 0 1-.02-.134l-1.033-.822c.03.12.04.248.022.378L10.076 23Zm3.982-12.074.838-7.566a1 1 0 0 1 .014-.09v-.009l-.001-.001-.002-.002-.002-.001-1.212-.259-2.2 4.912a.973.973 0 0 0-.07.578l.774 2.032.017.003 1.844.403Zm-1.086 1.81a.997.997 0 0 1-.203.404l-.747.896 6.05 4.811.048.04.827-.916a.05.05 0 0 0-.007-.018l-3.136-4.341a1.05 1.05 0 0 0-.496-.366l-2.336-.51Zm-9.914 1.081 7.085-3.072.423 1.111c.066.175.176.32.313.428l-1.641 1.97a.968.968 0 0 1-.53.23l-5.346.55h-.007v-.001h-.002v-.002s-.002-.001-.004-.006l-.377-1.17v-.005c.029-.01.058-.02.086-.033Z"
      clipRule="evenodd"
    />
  </Icon>
));
export default CleanPowerIcon;
