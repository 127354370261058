import { forwardRef } from 'react';
import Icon, { IconProps } from '../Icon';
const DashboardIcon = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Icon ref={ref} viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M16.707 9.293a1 1 0 0 1 0 1.414l-4 4a1 1 0 0 1-1.414-1.414l4-4a1 1 0 0 1 1.414 0Z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M12 5a9 9 0 0 0-7.794 13.5 1 1 0 0 1-1.732 1 11 11 0 1 1 19.052 0 1 1 0 0 1-1.732-1A9 9 0 0 0 12 5Z"
      clipRule="evenodd"
    />
  </Icon>
));
export default DashboardIcon;
