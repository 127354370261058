import { z } from 'zod';
import { GQPermissionObjectType, GQPermissionType } from '../generated/graphql';

// likely to be replaced a with gql enum
export enum Action {
  View = 'view',
  Edit = 'edit',
  Manage = 'manage',
  Approve = 'approve',
}

export type OrgLevelPermissionConfig = {
  permissionType: GQPermissionType;
};

export type ResourcePermissionConfig = {
  childResourceType?: ChildLevelResourceType;
  // gotta have at least one action
  actions: [Action, ...Array<Action>];
};

export const ORG_LEVEL_PERMISSION_TYPES = [
  GQPermissionType.AnyUser,
  GQPermissionType.WatershedAdmin,
  GQPermissionType.Admin,
  GQPermissionType.CorporateAdmin,
  GQPermissionType.FinanceAdmin,
  GQPermissionType.ManageMarketplacePurchases,
  GQPermissionType.ManageCompanyTags,
  GQPermissionType.ManageOrgHierarchy,
  GQPermissionType.ViewEmployeeReport,
  GQPermissionType.ViewFootprintDetail,
  GQPermissionType.ViewAuditDetail,
  GQPermissionType.ViewReductions,
  GQPermissionType.ViewLearningHub,
  GQPermissionType.ManageSingleSignOn,
  GQPermissionType.ManageMeasurement,
  GQPermissionType.ManageReductionPlans,
  GQPermissionType.ManageDisclosures,
  GQPermissionType.ManageSuppliers,
] as const;

export type OrgLevelPermissionType =
  (typeof ORG_LEVEL_PERMISSION_TYPES)[number];

export type ResourceSpecificPermissionType = Exclude<
  GQPermissionType,
  OrgLevelPermissionType
>;

export const CHILD_LEVEL_RESOURCES = [
  GQPermissionObjectType.Datasource,
  GQPermissionObjectType.ReportQuestionInstance,
] as const;

export type ChildLevelResourceType = (typeof CHILD_LEVEL_RESOURCES)[number];

export type ParentLevelResourceType = Exclude<
  GQPermissionObjectType,
  ChildLevelResourceType
>;

/**
 * NOTE: Below are tentative types that are likely to change as we implement the UX.
 */
/**
 * This is a data structure that holds all the permissions for a user or role.
 * It serves the Users and Roles pages.
 */
export type PermissionsForUserOrRole = {
  orgLevelPermissions: Array<OrgLevelPermissionType>;
  resourceLevelPermissions: {
    [key in GQPermissionObjectType]?: {
      [key in Action]?: {
        allowedResouceIds: { all: boolean; ids: Array<string> };
        subResourceAccess?: Record<string, Array<string>>;
      };
    };
  };
};

export type UserOrRoleId = {
  id: string;
  isRole: boolean;
};
export type OrgUsersWithResourceAccessSingle = {
  all: Array<UserOrRoleId>;
  specificIds: Map<string, Array<UserOrRoleId>>;
};
export type OrgUsersWithResourceAccessHierarchical =
  OrgUsersWithResourceAccessSingle & {
    subResourceAccess?: Map<string, OrgUsersWithResourceAccessSingle>;
  };
/**
 * This is a data structure that holds all the users and roles that
 * have any org or resource level access in a given org.
 * It serves the Permissions page.
 */
export type OrgUsersWithAccess = {
  orgLevelAccess: Map<OrgLevelPermissionType, Array<UserOrRoleId>>;
  resourceLevelAccess: Map<
    GQPermissionObjectType,
    OrgUsersWithResourceAccessHierarchical
  >;
};

export const MAX_NUM_ROLES_OR_USERS_TO_DISPLAY_FOR_RESOURCE = 4;

export const usersAndRolesWithPermissions = z.array(
  z.object({
    permissionInput: z.object({
      permission: z.nativeEnum(GQPermissionType),
      objectType: z.nativeEnum(GQPermissionObjectType).nullable(),
      objectId: z.string().nullable(),
    }),
    usersAndRoles: z.object({
      // we cap at 5 users or roles to limit the amount of data we pass over the wire
      examples: z
        .array(
          z.object({
            id: z.string(),
            name: z.string(),
            type: z.enum(['user', 'role']),
          })
        )
        .max(MAX_NUM_ROLES_OR_USERS_TO_DISPLAY_FOR_RESOURCE),
      total: z.number(),
    }),
  })
);

export type UsersAndRolesWithPermissions = z.infer<
  typeof usersAndRolesWithPermissions
>;

// These are the things we allow users to sort by in the permission resource list
export const PermissionResourceListSortModel = ['userOrRoleAccess'];
