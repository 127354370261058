import {
  Box,
  Divider,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from '@mui/material';
import { Trans } from '@lingui/react/macro';
import GearIcon from '@watershed/icons/components/Gear';
import RainbowIcon from '@watershed/icons/components/Rainbow';
import FriendsIcon from '@watershed/icons/components/Friends';
import ListIcon from '@watershed/icons/components/List';
import LogOutIcon from '@watershed/icons/components/LogOut';
import {
  routeForAuditLogs,
  routeForDemoPlatformIndex,
  routeForOrganizationUsers,
} from '@watershed/shared-universal/dashboardRoutes';
import useToggle from '@watershed/ui-core/hooks/useToggle';
import Button from '@watershed/ui-core/components/Button';
import UnstyledLink from '@watershed/ui-core/components/UnstyledLink';
import { useState } from 'react';
import useLogout from '@watershed/shared-frontend/hooks/useLogout';
import { useUserContext } from '../../utils/UserContext';
import { TestIds } from '@watershed/shared-universal/utils/testUtils';
import SwitchOrganizationDialog from './SwitchOrgDialog';
import { getSharedSidebarStyles } from './variants/sharedSidebarStyles';
import NavHelpButton from '../support/NavHelpButton';
import { LocaleDialog } from './LocaleDialog';
import LanguageIcon from '@watershed/icons/components/Language';
import { useFeatureFlag } from '../../utils/FeatureFlag';
import { GQFlags } from '@watershed/shared-universal/generated/graphql';

export default function UserBar({
  isOnFinancePage,
  appearsCollapsed,
  isAdmin,
  isWatershedEmployee,
}: {
  isOnFinancePage: boolean;
  appearsCollapsed: boolean;
  isAdmin: boolean;
  isWatershedEmployee: boolean;
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const userContext = useUserContext();
  const onLogout = useLogout();
  const [switchOrgDialogOpen, toggleSwitchOrgDialog] = useToggle();
  const [localeDialogOpen, setLocaleDialogOpen] = useState<boolean>(false);
  const localeEnabled = useFeatureFlag(GQFlags.LocalizationDynamicStrings);

  const sharedSidebarStyles = getSharedSidebarStyles(useTheme());
  const { COLORS, BOX_SHADOW_FOCUS } = sharedSidebarStyles;

  return (
    <Box
      sx={{
        margin: 'auto 0 0',
        backgroundColor: COLORS.BACKGROUND,
        color: COLORS.TEXT_PRIMARY,
        padding: 1,
        borderTop: (theme) => `1px solid ${theme.palette.grey80}`,
        display: 'flex',
        flexDirection: 'column',
        gap: '2px',
      }}
    >
      <NavHelpButton
        isOnFinancePage={isOnFinancePage}
        appearsCollapsed={appearsCollapsed}
      />
      <Button
        data-testid={TestIds.UserBarButton}
        variant="text"
        startIcon={
          <GearIcon
            color={COLORS.TEXT_PRIMARY}
            style={{
              transform: anchorEl === null ? undefined : 'rotate(-30deg)',
            }}
          />
        }
        aria-controls="user-menu"
        aria-haspopup="true"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        sx={{
          minWidth: 'unset',
          width: '100%',
          justifyContent: 'flex-start',
          textAlign: 'left',
          color: COLORS.TEXT_PRIMARY,
          height: 'auto', // Undo default 32px height
          borderRadius: 1,
          paddingInline: 1, // Match alignment of navbar icon
          paddingBlock: 1,
          gap: 1,

          '&:hover, &:focus': {
            backgroundColor: COLORS.BACKGROUND_ACTIVE,
          },
          '&:focus-visible': {
            boxShadow: `inset ${BOX_SHADOW_FOCUS}`,
          },

          '& .MuiButton-startIcon': {
            margin: 0,
            '& svg': {
              width: 16,
              height: 16,
              transition: (theme) => theme.transitions.create('transform'),
            },
            pb: 2,
          },
        }}
      >
        <Box
          component="span"
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            '[data-appears-collapsed="true"] &': {
              opacity: 0,
            },
          }}
        >
          {userContext.userName}
          <br />
          <Typography variant="body3" color="inherit">
            {userContext.orgName}
          </Typography>
        </Box>
      </Button>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: sharedSidebarStyles.menuPaperSxProps,
          'data-appears-collapsed': appearsCollapsed,
        }}
      >
        {isWatershedEmployee && (
          <Box>
            <Typography
              variant="body2"
              sx={{ padding: 1, color: (theme) => theme.palette.grey05 }}
            >
              <Trans context="Menu group heading">Watershed team only:</Trans>
            </Typography>
            <UnstyledLink href={routeForDemoPlatformIndex()}>
              <MenuItem
                onClick={() => {
                  setAnchorEl(null);
                }}
              >
                <RainbowIcon />
                <Trans context="Navbar menu item">Demo Platform</Trans>
              </MenuItem>
            </UnstyledLink>
            <Divider sx={{ borderColor: (theme) => theme.palette.grey70 }} />
          </Box>
        )}
        {isAdmin && (
          <UnstyledLink href={routeForOrganizationUsers()}>
            <MenuItem
              data-testid={TestIds.OrganizationSettingsItem}
              onClick={() => {
                setAnchorEl(null);
              }}
            >
              <GearIcon />
              <Trans context="Navbar item for Org settings">
                Organizational settings
              </Trans>
            </MenuItem>
          </UnstyledLink>
        )}

        {isAdmin && (
          <UnstyledLink href={routeForAuditLogs()}>
            <MenuItem
              onClick={() => {
                setAnchorEl(null);
              }}
            >
              <ListIcon />
              <Trans context="Navbar menu item for Audit log">Audit log</Trans>
            </MenuItem>
          </UnstyledLink>
        )}
        {userContext.accessibleOrgs.length > 1 && (
          <MenuItem
            onClick={() => {
              toggleSwitchOrgDialog();
              setAnchorEl(null);
            }}
            disabled={userContext.loginAsUser !== null}
          >
            <FriendsIcon />
            <Trans context="Navbar menu item for Switch organization">
              Switch organization
            </Trans>
          </MenuItem>
        )}
        {localeEnabled && (
          <MenuItem
            onClick={() => {
              setLocaleDialogOpen(true);
              setAnchorEl(null);
            }}
          >
            <LanguageIcon />
            <Trans context="The text of the button to open a dialog to switch the dashboard language">
              Change locale
            </Trans>
          </MenuItem>
        )}
        <MenuItem
          component="button"
          onClick={() => {
            // TODO: URGENT Please fix this by await-ing or void-ing this line.
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            onLogout();
            setAnchorEl(null);
          }}
          sx={{
            letterSpacing: 'inherit',
            width: '100% !important',
          }}
          data-testid={TestIds.LogoutButton}
        >
          <LogOutIcon />
          <Trans context="Navbar menu item for Sign out">Sign out…</Trans>
        </MenuItem>
      </Menu>
      <SwitchOrganizationDialog
        open={switchOrgDialogOpen}
        onClose={toggleSwitchOrgDialog}
      />
      <LocaleDialog
        open={localeDialogOpen}
        onClose={() => setLocaleDialogOpen(false)}
      />
    </Box>
  );
}
