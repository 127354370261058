import Button from '@watershed/ui-core/components/Button';
import { Dialog } from '@watershed/ui-core/components/Dialog';
import FileUploadDropzone, {
  RejectedFile,
  UploadFile,
} from '@watershed/ui-core/components/FileUpload/FileUploadDropzone';
import { Trans } from '@lingui/react/macro';
import some from 'lodash/some';
import { useEffect, useState } from 'react';
import { getGqlResultDataBang } from '@watershed/shared-frontend/utils/errorUtils';
import {
  isResponseOk,
  uploadWithRetries,
} from '@watershed/shared-frontend/utils/requestUtils';
import useSnackbar from '@watershed/shared-frontend/hooks/useSnackbar';
import { mapAsync } from '@watershed/shared-universal/utils/asyncUtils';
import Callout from '@watershed/shared-frontend/components/Callout';
import { TestIds } from '@watershed/shared-universal/utils/testUtils';
import useManageSupportCaseAttachments from './useManageSupportCaseAttachments';

const MAX_FILE_SIZE_MB = 10;
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

export default function SupportCaseAttachmentDialog({
  open,
  onClose,
  onSubmit,
  files,
  getDownloadFileUrl,
}: {
  open: boolean;
  onClose: () => void;
  onSubmit: (files: Array<UploadFile>) => void;
  files: Array<UploadFile>;
  removeUpload: (fileId: string) => void;
  getDownloadFileUrl: (fileMetadataId: string) => Promise<string | undefined>;
}) {
  const { executeCreateSupportCaseAttachments, executeDeleteAttachmentItems } =
    useManageSupportCaseAttachments({
      files,
      // Note: This dialog has its own temporary stash of "attached files"
      // and it calls the equivalent of setFiles on submit
      setFiles: () => {},
    });
  const [attachedFiles, setAttachedFiles] = useState<Array<UploadFile>>(files);
  const [fileUploadProgressMap, setFileUploadProgressMap] = useState<{
    [fileMetadataId: string]: {
      progress: number;
    };
  }>({});
  const [errors, setErrors] = useState<Array<string>>([]);

  const snackbar = useSnackbar();

  // Sync dialog state with parent state when dialog opens
  useEffect(() => {
    if (open) {
      setAttachedFiles(files); // Reset dialog files to match parent
    }
  }, [open, files]);

  useEffect(() => {
    // update attachedFiles' progress if it mismatches the fileUploadProgressMap
    let needsUpdate = false;
    for (const file of attachedFiles) {
      const progress = fileUploadProgressMap?.[file.fileId]?.progress ?? 0;
      if (progress === 100 && !file.isImported) {
        file.isImported = true;
        needsUpdate = true;
      }
    }

    if (needsUpdate) {
      setAttachedFiles([...attachedFiles]);
    }
  }, [fileUploadProgressMap, attachedFiles]);

  const removeUpload = async (fileId: string) => {
    const item = attachedFiles.find((file) => file.fileId === fileId);
    if (!item) {
      return;
    }
    await executeDeleteAttachmentItems({
      input: { fileMetadataIds: [fileId] },
    });
    setAttachedFiles(attachedFiles.filter((file) => file.fileId !== fileId));
  };

  const onDrop = async (acceptedFiles: Array<File>) => {
    if (acceptedFiles.length === 0) {
      return;
    }
    const fileInfo = acceptedFiles.map((file) => {
      return {
        fileName: file.name,
        sizeBytes: file.size,
      };
    });

    const result = await executeCreateSupportCaseAttachments({
      input: {
        files: fileInfo,
      },
    });

    const data = getGqlResultDataBang(result);
    const attachmentResponseFiles = data.createSupportCaseAttachments.files;

    // upload to GCS
    const uploadPromises = await mapAsync(
      attachmentResponseFiles,
      async ({ fileMetadataId, signedUrl }, i) => {
        return uploadWithRetries(
          signedUrl,
          acceptedFiles[i],
          fileMetadataId,
          (progress) =>
            setFileUploadProgressMap((prev) => ({
              ...prev,
              [fileMetadataId]: { progress },
            }))
        );
      }
    );

    // set the files before we start uploading so we can track their progress
    setAttachedFiles((prev) => [
      ...prev,
      ...attachmentResponseFiles.map((file) => {
        return {
          fileId: file.fileMetadataId,
          isImported: false,
          filename: file.fileName,
        };
      }),
    ]);

    const responses = await Promise.all(uploadPromises);
    responses.forEach(
      async (response: XMLHttpRequest | null, index: number) => {
        if (response === null || !isResponseOk(response)) {
          snackbar.enqueueSnackbar(
            <Trans context="file upload error">
              There was an error uploading "{acceptedFiles[index].name}"
            </Trans>,
            { variant: 'error' }
          );
          await executeDeleteAttachmentItems({
            input: {
              fileMetadataIds: [attachmentResponseFiles[index].fileMetadataId],
            },
          });
          return;
        }
      }
    );
  };

  const onDropRejected = (rejectedFiles: Array<RejectedFile>) => {
    setErrors((prev) => [
      ...prev,
      ...rejectedFiles.map((file) => file.message),
    ]);
  };

  const getDownloadUrl = (file: UploadFile) => {
    return getDownloadFileUrl(file.fileId);
  };

  return (
    <Dialog
      header={{
        title: (
          <Trans context="button to attach files to comment">
            Attach files
          </Trans>
        ),
      }}
      actions={
        <>
          <Button
            onClick={() => {
              setErrors([]);
              onClose();
            }}
          >
            <Trans context="button to cancel uploads">Cancel upload</Trans>
          </Button>
          <Button
            color="primary"
            onClick={() => {
              setErrors([]);
              onSubmit(attachedFiles);
            }}
            disabled={some(files.map((file) => !file.isImported))}
            data-testid={TestIds.SupportAttachmentSubmitButton}
          >
            <Trans context="button to attach files to comment">
              Attach files
            </Trans>
          </Button>
        </>
      }
      onClose={() => {
        setErrors([]);
        onClose();
      }}
      open={open}
    >
      <FileUploadDropzone
        files={attachedFiles}
        onDropAccepted={onDrop}
        onDropRejected={onDropRejected}
        fileUploadProgressMap={fileUploadProgressMap}
        removeUpload={removeUpload}
        maxSizeBytes={MAX_FILE_SIZE_BYTES}
        additionalUsageText={
          <Trans context="max file size">Maximum file size is 10MB</Trans>
        }
        getDownloadUrl={getDownloadUrl}
      />
      {errors.map((error, i) => (
        <Callout
          key={i}
          isDense
          variant="error"
          description={error}
          onDismiss={() =>
            setErrors((prev) => prev.filter((_, errIndex) => i !== errIndex))
          }
        />
      ))}
    </Dialog>
  );
}
