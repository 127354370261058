import { forwardRef } from 'react';
import Icon, { IconProps } from '../Icon';
const GavelIcon = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Icon ref={ref} viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M14.707 2.707a1 1 0 0 0-1.414-1.414l-1 1-5 5a1 1 0 0 0 1.414 1.414L9 8.414 10.086 9.5l-7.293 7.293a3.121 3.121 0 1 0 4.414 4.414l7.293-7.293L15.586 15l-.293.293a1 1 0 0 0 1.414 1.414l5-5 1-1a1 1 0 0 0-1.414-1.414L21 9.586 14.414 3l.293-.293ZM13 4.414 10.414 7 17 13.586 19.586 11 13 4.414ZM4.207 18.207l7.293-7.293 1.586 1.586-7.293 7.293a1.12 1.12 0 0 1-1.83-.364 1.12 1.12 0 0 1 .244-1.222Z"
      clipRule="evenodd"
    />
  </Icon>
));
export default GavelIcon;
