import { Stack, SxProps, Theme } from '@mui/material';
import { useLingui } from '@lingui/react/macro';

import Skeleton from '@watershed/ui-core/components/Skeleton';
import React, { useState } from 'react';
import { mixinSx } from '@watershed/style/styleUtils';
import {
  UploadFile,
  RejectedFile,
  ALL_FILE_TYPES,
  FileType,
} from '../../utils/FileUpload';
import { SheetsMap } from './FileUploadDropzoneInternal/SheetSelection';
import { FileItem } from './FileUploadDropzoneInternal/FileItem';
import { RejectedFileItem } from './FileUploadDropzoneInternal/RejectedFileItem';
import { DropArea } from './FileUploadDropzoneInternal/DropArea';
import Callout from '../Callout';
import InfoIcon from '@watershed/icons/components/Info';
import WarningIcon from '@watershed/icons/components/Warning';

export type { UploadFile, RejectedFile };
export { FileType };

export type FileDropEnabledState = 'enabled' | 'disabled' | 'hidden';
export type FileDropAlertMessage = {
  level: 'info' | 'warning';
  text: LocalizedString;
};
export interface FileUploadDropzoneProps {
  acceptedFileTypes?: Array<FileType>;
  files: Array<UploadFile>;
  filesBeingRemoved?: Array<string>;
  maxSizeBytes?: number;
  sheetsMap?: SheetsMap;
  onChangeSheetSelection?: (
    fileId: string,
    sheetSelections: Array<boolean>
  ) => void;
  removeUpload?: (fileId: string) => void;
  enabledState?: FileDropEnabledState;
  alertMessage?: FileDropAlertMessage;
  /**
   * Use this to provide any helpful context about the files being uploaded, like "Attach any documentation about this LCA."
   */
  additionalUsageText?: LocalizedString;
  onClickPreviewFile?: (file: UploadFile) => void;
  onDropAccepted?: (acceptedFiles: Array<File>) => void;
  onDropRejected?: (rejectedFiles: Array<RejectedFile>) => void;
  progressMessage?: LocalizedString;
  fileUploadProgressMap?: {
    [fileId: string]: { progress: number };
  };
  getDownloadUrl?: (file: UploadFile) => Promise<string | undefined>;
  isCompact?: boolean;
  renderFile?: (args: {
    file: UploadFile;
    onDownload: (() => void) | undefined;
    onDelete: (() => void) | undefined;
  }) => React.ReactNode;
  tablePreviewer?: (tableId: string) => React.ReactNode;
  /**
   * If specified, this action will render to the left of the delete action on the
   * rejected file row.
   */
  customRejectedAction?: (rejection: RejectedFile) => React.ReactNode;
  sx?: SxProps<Theme>;
}

/**
 * A component that does a lot! Allows you to drag and drop multiple files, or click to select files to upload. Handles uploading,
 * showing progress, and showing errors. Also allows you to preview files, and select specific sheets from Excel files to upload.
 * Very customizable, but defaults cover most use cases.
 */
export default function FileUploadDropzone({
  acceptedFileTypes = ALL_FILE_TYPES,
  files,
  filesBeingRemoved = [],
  maxSizeBytes,
  sheetsMap = {},
  onChangeSheetSelection = () => {},
  onClickPreviewFile,
  removeUpload,
  enabledState = 'enabled',
  alertMessage,
  additionalUsageText,
  onDropAccepted = () => {},
  onDropRejected = () => {},
  progressMessage,
  fileUploadProgressMap = {},
  getDownloadUrl = undefined,
  isCompact = false,
  renderFile = undefined,
  tablePreviewer = undefined,
  customRejectedAction = undefined,
  sx,
}: FileUploadDropzoneProps) {
  const { t } = useLingui();
  progressMessage ??= t({
    message: 'Please wait, this might take a minute...',
    context: 'Loading message when uploading a file',
  });
  const [rejectedFiles, setRejectedFiles] = useState<Array<RejectedFile>>([]);
  const removeRejectedFile = (fileName: string) => {
    setRejectedFiles(rejectedFiles.filter((file) => file.name !== fileName));
  };

  const inProgress = files.some((file) => !file.isImported && !file.error);
  const dropzoneDisabled = enabledState !== 'enabled' || inProgress;

  const rejectFiles = (files: Array<RejectedFile>) => {
    setRejectedFiles([...rejectedFiles, ...files]);
    onDropRejected(files);
  };

  return (
    <Stack
      gap={1}
      sx={mixinSx(
        {
          borderRadius: 0.5,
          mt: isCompact ? 0 : '24px',
          mb: isCompact ? 0 : '24px',
        },
        enabledState === 'disabled' &&
          ((theme) => ({
            cursor: 'no-drop',
            background: theme.palette.grey10,
          })),
        sx
      )}
    >
      {enabledState !== 'hidden' && (
        <DropArea
          acceptedFileTypes={acceptedFileTypes}
          maxSizeBytes={maxSizeBytes}
          additionalUsageText={additionalUsageText}
          onDropAccepted={onDropAccepted}
          rejectFiles={rejectFiles}
          progressMessage={progressMessage}
          inProgress={inProgress}
          dropzoneDisabled={dropzoneDisabled}
          isCompact={isCompact}
        />
      )}
      {alertMessage && (
        <Callout
          IconComponent={
            { info: InfoIcon, warning: WarningIcon }[alertMessage.level]
          }
          variant={alertMessage.level}
          title={alertMessage.text}
          sx={{
            // Aligning with file item icon and text.
            paddingInline: 3,
            gap: 1.5,
          }}
        />
      )}
      {files.length > 0 && (
        <Stack
          role="list"
          sx={{
            border: (theme) => `thin solid ${theme.palette.border}`,
            borderRadius: 1,
          }}
        >
          {rejectedFiles.map((file, idx) => (
            <RejectedFileItem
              key={idx}
              rejectedFile={file}
              remove={removeRejectedFile}
              customRejectedAction={customRejectedAction}
            />
          ))}
          {files.map((file) =>
            renderFile ? (
              renderFile({
                file,
                onDelete: () =>
                  removeUpload ? removeUpload(file.fileId) : undefined,
                onDownload: getDownloadUrl
                  ? async () => {
                      const downloadUrl = await getDownloadUrl(file);
                      window.open(downloadUrl, '_blank');
                    }
                  : undefined,
              })
            ) : (
              <FileItem
                key={file.fileId}
                file={file}
                progress={
                  fileUploadProgressMap.hasOwnProperty(file.fileId)
                    ? fileUploadProgressMap[file.fileId].progress
                    : 0
                }
                removeUpload={removeUpload}
                disabled={filesBeingRemoved.includes(file.fileId) || inProgress}
                sheets={sheetsMap[file.fileId]}
                onChangeSheetSelection={onChangeSheetSelection}
                getDownloadUrl={getDownloadUrl}
                onClickPreviewFile={onClickPreviewFile}
                tablePreviewer={tablePreviewer}
              />
            )
          )}
        </Stack>
      )}
    </Stack>
  );
}

FileUploadDropzone.Loading = () => (
  <Skeleton variant="rectangular" height="200px" width="100%" />
);
