import { forwardRef } from 'react';
import Icon, { IconProps } from '../Icon';
const DocumentIcon = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Icon ref={ref} viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M6 1a3 3 0 0 0-3 3v16a3 3 0 0 0 3 3h12a3 3 0 0 0 3-3V7.5a1 1 0 0 0-.293-.707l-5.5-5.5A1 1 0 0 0 14.5 1H6Zm7 2H6a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9h-5a1 1 0 0 1-1-1V3Zm5.086 4H15V3.914L18.086 7Z"
      clipRule="evenodd"
    />
  </Icon>
));
export default DocumentIcon;
