import { forwardRef } from 'react';
import Icon, { IconProps } from '../Icon';
const KickoffIcon = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Icon ref={ref} viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M6 1a1 1 0 0 0-1 1v1h-.5a3.5 3.5 0 1 0 0 7h.572A7 7 0 0 0 9 15.325v1.669a.257.257 0 0 1-.049.071 1.042 1.042 0 0 1-.338.236c-1.36.623-2.269 2.061-2.533 3.699H4a1 1 0 1 0 0 2h16a1 1 0 1 0 0-2h-2.08c-.264-1.638-1.172-3.075-2.532-3.698a1.045 1.045 0 0 1-.34-.237.256.256 0 0 1-.048-.071v-1.67A6.998 6.998 0 0 0 18.928 10h.572a3.5 3.5 0 0 0 0-7H19V2a1 1 0 0 0-1-1H6Zm13 4v3h.5a1.5 1.5 0 0 0 0-3H19Zm-6 10.928a6.99 6.99 0 0 1-2 0V17c0 1.15-.939 1.836-1.552 2.119h-.002c-.551.253-1.098.923-1.33 1.881h7.768c-.232-.958-.779-1.628-1.33-1.88l-.002-.002C13.94 18.837 13 18.15 13 17v-1.072ZM5 8V5h-.5a1.5 1.5 0 0 0 0 3H5Zm2 1V3h10v6A5 5 0 1 1 7 9Z"
      clipRule="evenodd"
    />
  </Icon>
));
export default KickoffIcon;
