import { condenseCurrency } from '@shopify/condense-number';
import { CurrencyCode, CurrencySymbol, CURRENCY_MAP } from './currencies';
import { SupportedLocale } from '@watershed/intl/constants';
import { i18n } from '@watershed/intl';
import { formatCurrency } from '@watershed/intl/formatters';

// Locale codes pulled from https://github.com/Shopify/condense-number?tab=readme-ov-file#condense-number
const LOCALE_CODES = [
  'da',
  'de',
  'el',
  'en',
  'es',
  'fi',
  'fr',
  'hi',
  'it',
  'ja',
  'ko',
  'ms',
  'nb-NO',
  'nl',
  'pl',
  'pt-BR',
  'ro',
  'ru',
  'sv',
  'th',
  'tr',
  'zh-CN',
  'zh-TW',
] as const;
type LocaleCode = (typeof LOCALE_CODES)[number];
const LocaleCodeMap: { [key in SupportedLocale]: LocaleCode } = {
  'en-US': 'en',
  'en-GB': 'en',
  'en-PL': 'en',
  'de-DE': 'de',
  'fr-FR': 'fr',
  'ja-JP': 'ja',
  'pt-BR': 'pt-BR',
  'es-ES': 'es',
  'pl-PL': 'pl',
  'tr-TR': 'tr',
  'zh-CN': 'zh-CN',
};

/**
 * Condense a monetary value based on the currency
 * @param currency Defaults to 'USD'
 * @param options Defaults to max precision of 2 and rounding to nearest number
 */
export function condenseMonetaryValue(
  value: number,
  currency?: string | null,
  options?: {
    locale?: SupportedLocale | null;
    maxPrecision?: number | null; // How many decimal places to show
    roundingRule?: 'auto' | 'up' | 'down'; // If not specified, rounds to the nearest number
  }
): string {
  const currencyCode = currency || 'USD';
  const maxPrecision = options?.maxPrecision || 2;
  const roundingRule = options?.roundingRule || 'auto';

  const locale = options?.locale || (i18n.locale as SupportedLocale);
  const localeCode = LocaleCodeMap[locale];

  return condenseCurrency(value, localeCode, currencyCode, {
    maxPrecision,
    roundingRule,
  });
}

/**
 * Format revenue per million. Defaults to displaying currency in USD
 */
export function condenseMoneyPerMillion(currency?: string | null): string {
  return condenseMonetaryValue(1_000_000, currency);
}

/**
 * Format a monetary value for display. Note that unlike condenseMonetaryValue,
 * this does not do any condensing
 * @param currency: defaults to USD
 */
export function formatMonetaryValue(
  value: number | string,
  currency?: string | null,
  options?: {
    locale?: SupportedLocale | null;
    minimumFractionDigits?: number;
    maximumFractionDigits?: number;
  }
): string {
  const locale = options?.locale || i18n.locale;
  const parsedValue = typeof value === 'string' ? parseFloat(value) : value;
  return formatCurrency(parsedValue, currency ?? 'USD', {
    locale,
    minimumFractionDigits: options?.minimumFractionDigits,
    maximumFractionDigits: options?.maximumFractionDigits,
  });
}

export function isCurrencyCode(
  currency: string | null
): currency is CurrencyCode {
  return currency !== null && currency in CURRENCY_MAP;
}

/**
 * Given a currency code (eg. 'GBP'), return the currency symbol (eg. '£').
 * If the currency code is not recognized, returns the currency code.
 */
export function getCurrencySymbol(
  currency: string | null
): CurrencySymbol | string {
  if (currency === null) {
    return '$';
  } else if (isCurrencyCode(currency)) {
    return CURRENCY_MAP[currency].symbol;
  } else {
    return currency;
  }
}
