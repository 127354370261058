import {
  GQPermissionObjectType,
  GQPermissionType,
  GQUserUploadForDatasourceFragment,
} from '../generated/graphql';

import { z } from 'zod';
import { UserUploadForDatasource } from '../measurement/userUploadUtils';

// List of objects that can have approval tasks point to them
export const ApprovalTaskTargets = ['UserUploadTask'] as const;
export const ApprovalTaskTargetKinds = z.enum(ApprovalTaskTargets);
export type ApprovalTaskTargetKind = z.infer<typeof ApprovalTaskTargetKinds>;

// List of objects that can have measurement approval events point to them -- includes ApprovalTargets
export const ApprovalEventTargets = [
  ...ApprovalTaskTargets,
  'UserUpload',
] as const;
export const ApprovalEventTargetKinds = z.enum(ApprovalEventTargets);
export type ApprovalEventTargetKind = z.infer<typeof ApprovalEventTargetKinds>;

// Currently the same as GQApprovalStatus in graphql
export type ApprovalStatus =
  | 'SubmittedForApproval'
  | 'Approved'
  | 'NotReadyForApproval';

export type ApprovalTaskTargetParams = {
  orgId: string;
  id: string;
  kind: ApprovalTaskTargetKind;
};

export type ApprovalEventTargetParams = {
  orgId: string;
  id: string;
  kind: ApprovalEventTargetKind;
};

// This is a combination of the ApprovalState enum and the user who's viewing the UI
export const DataGovApprovalViewState = {
  // 1a. Earliest state ex. no files have been uploaded or they're processing/errored
  CANNOT_SUBMIT: 'CANNOT_SUBMIT',
  // 1b. Cannot submit because you don't have permission
  CANNOT_SUBMIT_NO_PERMISSION: 'CANNOT_SUBMIT_NO_PERMISSION',
  // 2a. No files exist. User can submit empty data as "none to upload"
  CAN_SUBMIT_EMPTY: 'CAN_SUBMIT_EMPTY',
  // 2b. 1+ file exists, none are processing or errored, not yet approved or marked ready to approve
  // TODO: do we ever want to limit who can submit for approval? ie. approver can't or uploader must?
  CAN_SUBMIT: 'CAN_SUBMIT',
  // 3a. Marked ready to approve, but is not the approver
  SUBMITTED_BUT_NO_PERMISSION: 'SUBMITTED_BUT_NO_PERMISSION',
  // 3b. Marked ready to approve, is the approver
  SUBMITTED_HAS_PERMISSION: 'SUBMITTED_HAS_PERMISSION',
  // 3c. Is the approver, awaiting other approvers
  APPROVED_AWAITING_OTHERS: 'APPROVED_AWAITING_OTHERS',
  // TODO: 3??. Marked ready to approve and you were the submitter (just shows you're done, button disabled?)
  // TODO: 3??. If you're price gated out and don't have approvals, tooltip/dialog smth like "If you submit, it will auto-approve"
  // 4. Approved, no action needed
  APPROVED: 'APPROVED',
  // 4a. Approved, but can still reject (ie. if you're the approver)
  APPROVED_CAN_REJECT: 'APPROVED_CAN_REJECT',
  // TODO: do we want to show anything different for data that's been "unapproved"
  // by a step earlier in the chain? leaning no, activity log should handle this

  // These states are for when approvals are disabled for the project
  // 5. Approvals are disabled for the project, and the user is still uploading
  APPROVALS_DISABLED_UPLOADING: 'APPROVALS_DISABLED_UPLOADING',
  // 6. Approval are disabled for the project, and the user has incomplete facilities
  APPROVALS_DISABLED_FACILITIES_INCOMPLETE:
    'APPROVALS_DISABLED_FACILITIES_INCOMPLETE',
  // 7. Approvals are disabled for the project, and the user has uploaded all the data
  APPROVALS_DISABLED_COMPLETE: 'APPROVALS_DISABLED_COMPLETE',
  // TODO: how should data lock affect this?
  // TODO: relatedly, measurement is marked as complete? is it the same state as data locked?
};
// eslint-disable-next-line @typescript-eslint/no-redeclare -- intentionally naming the variable the same as the type to mirror enum behavior
export type DataGovApprovalViewState =
  (typeof DataGovApprovalViewState)[keyof typeof DataGovApprovalViewState];

export const DataGovApprovalsButtonType = {
  APPROVAL_DROPDOWN: 'APPROVAL_DROPDOWN',
  POST_APPROVAL_DROPDOWN: 'POST_APPROVAL_DROPDOWN',
  APPROVED: 'APPROVED',
  SUBMIT_FOR_APPROVAL: 'SUBMIT_FOR_APPROVAL',
  APPROVALS_DISABLED_COMPLETE_TASK: 'APPROVALS_DISABLED_COMPLETE_TASK',
  APPROVALS_DISABLED_UNDO: 'APPROVALS_DISABLED_UNDO',
};

export enum DataGovApprovalsLockingButtonType {
  LOCK_TOGGLE = 'LOCK_TOGGLE',
  UNLOCK_REQUEST = 'UNLOCK_REQUEST',
  // When the data has not been approved by anyone yet, instead of requested an unlock, the
  // uploader should be able to directly unlock the data
  UPLOADER_UNLOCK = 'UPLOADER_UNLOCK',
  APPROVALS_DISABLED_REOPEN = 'APPROVALS_DISABLED_REOPEN',
}

export type DataGovDatasourceUploadsApprovalSettings = {
  approvalViewState: string; // TODO: make type of values of obj
  buttonColor?: 'primary' | 'secondary' | undefined;
  buttonType: string; // TODO: make type of values of obj
  isDisabled: boolean;
  calloutText: string;
  tooltip?: string;
  variant?: 'text' | undefined;
};

export type DataGovUserPermissions = Array<DataGovUserPermission>;

type DataGovUserPermission = {
  permission: GQPermissionType;
  objectId?: string | null | undefined;
  objectType?: GQPermissionObjectType | null | undefined;
};

export type DataApprovalsFiles = Array<DataApprovalsFile>;

export type DataApprovalsFile = UserUploadForDatasource &
  Pick<GQUserUploadForDatasourceFragment, 'status' | 'processingMode'>;

export type ApprovalEmailRecipientType =
  | 'approvers'
  | 'uploaders'
  | 'admins'
  | 'manageMeasurement';

export type DataApprovalEventAction =
  | 'Approved'
  | 'Rejected'
  | 'Submitted'
  | 'UnlockRequested';
export type ApprovalEmailEventAction = DataApprovalEventAction | 'Unlocked';

////////////////////////////////////////////////////////////////////
// Types originating in the service layer
////////////////////////////////////////////////////////////////////

export type ApprovalDataSnapshot =
  | UserUploadTaskDataSnapshot
  | UserUploadSnapshot;
export const USER_UPLOAD_TASK_SNAPSHOT_CURRENT_VERSION = 0;

const userUploadTaskSnapshotVersionField = z
  .number()
  .int()
  .min(0)
  .max(USER_UPLOAD_TASK_SNAPSHOT_CURRENT_VERSION)
  // We didn't write version numbers early in the project, so missing version means v0.
  // Only Watershed devs and demo/test orgs should have any events missing version numbers.
  .default(0);

// Schema for the dataSnapshot field we store in the data approval event db
export const USER_UPLOAD_TASK_SNAPSHOT_SCHEMA = z
  .object({
    version: userUploadTaskSnapshotVersionField,
    userUploadIds: z.array(z.string()),
  })
  .or(
    z.object({
      version: userUploadTaskSnapshotVersionField,
      buildings: z.array(
        z.object({
          id: z.string(),
          revision: z.number().int().nonnegative(),
        })
      ),
    })
  );
export type UserUploadTaskDataSnapshot = z.infer<
  typeof USER_UPLOAD_TASK_SNAPSHOT_SCHEMA
>;

export const USER_UPLOAD_SNAPSHOT_SCHEMA = z.object({});
export type UserUploadSnapshot = z.infer<typeof USER_UPLOAD_SNAPSHOT_SCHEMA>;

export const REJECTION_EVENT_METADATA_SCHEMA = z.object({
  rejectionFromUnlocking: z.boolean().optional(),
});

// Utility type to extract the type from the union based on the presence of a property
type ExtractTypeWithProperty<T, K extends string> = T extends Record<K, any>
  ? T
  : never;

export function isUserUploadSnapshot(
  snapshot: UserUploadTaskDataSnapshot
): snapshot is ExtractTypeWithProperty<
  UserUploadTaskDataSnapshot,
  'userUploadIds'
> {
  return 'userUploadIds' in snapshot;
}

export function isBuildingSnapshot(
  snapshot: UserUploadTaskDataSnapshot
): snapshot is ExtractTypeWithProperty<
  UserUploadTaskDataSnapshot,
  'buildings'
> {
  return 'buildings' in snapshot;
}
