import React, { useEffect, useState } from 'react';
import { Trans } from '@lingui/react/macro';
import {
  Box,
  Fade,
  TextareaAutosize,
  Typography,
  Theme,
  SxProps,
} from '@mui/material';
import CircularProgress from '@watershed/ui-core/components/CircularProgress';
import { makeStyles, createStyles } from '@mui/styles';
import Button from './Button';
import TextLink from './TextLink';
import Well from './Well';
import CustomReactMarkdown from './CustomReactMarkdown';
import ViewIcon from '@watershed/icons/components/View';
import PencilIcon from '@watershed/icons/components/Pencil';
import CheckmarkIcon from '@watershed/icons/components/Checkmark';
import { getPaletteUtils, mixinSx } from '@watershed/style/styleUtils';
import { TestIds } from '@watershed/shared-universal/utils/testUtils';
import { t } from '@lingui/core/macro';

const useStyles = makeStyles((theme: Theme) => {
  const paletteUtils = getPaletteUtils(theme.palette);
  return createStyles({
    input: {
      ...paletteUtils.textFieldStyles,
      width: '100%',
      resize: 'vertical',
      '&[aria-invalid="true"]': {
        boxShadow: paletteUtils.boxShadowField.error,
      },
    },
  });
});

export default function MarkdownTextFieldWithPreview({
  id,
  name,
  value = '',
  onChange,
  onDidTogglePreview,
  minRows = 5,
  minHeight = 64,
  disabled = false,
  error = false,
  helperText,
  isPreviewing: isPreviewingInitial,
  defaultToPreview = false,
  placeholder,
  previewBlankslate = t`No text here yet`,
  previewBackground = 'secondary.light',
  isLoading = false,
  autoFocus = false,
  previewSx,
  ...textareaProps
}: {
  id?: string;
  name?: string;
  // Make this flexible because sometimes this is used with a Formik field and
  // the initial value is undefined, and typing this as `string` was not
  // catching undefined values when used as `<Field
  // as={MarkdownTextFieldWithPreview} />`.
  value?: string;
  onChange: (
    evt: React.ChangeEvent<HTMLTextAreaElement>,
    value: string
  ) => void;
  onDidTogglePreview?: (isPreviewing: boolean) => void;
  minRows?: number;
  minHeight?: number;
  disabled?: boolean;
  error?: boolean;
  helperText?: LocalizedString;
  /**
   * Allows controlling the preview state from outside the component.
   */
  isPreviewing?: boolean;
  defaultToPreview?: boolean;
  placeholder?: LocalizedString;
  previewBlankslate?: LocalizedString;
  previewBackground?: 'background.paper' | 'secondary.light';
  isLoading?: boolean;
  autoFocus?: boolean;
  previewSx?: SxProps<Theme>;
} & Omit<React.ComponentProps<typeof TextareaAutosize>, 'onChange'>) {
  const classes = useStyles();
  const [isPreviewing, setIsPreviewing] = useState(
    isPreviewingInitial ?? defaultToPreview
  );

  // Allows outside state to control preview state if needed
  useEffect(() => {
    if (
      isPreviewingInitial !== undefined &&
      isPreviewing !== isPreviewingInitial
    ) {
      setIsPreviewing(isPreviewingInitial);
      onDidTogglePreview?.(isPreviewingInitial);
    }
  }, [isPreviewingInitial, isPreviewing, onDidTogglePreview]);

  const togglePreview = () => {
    setIsPreviewing((value) => !value);
    onDidTogglePreview?.(!isPreviewing);
  };

  return (
    <Box width="100%" sx={{ position: 'relative', minHeight }}>
      {isPreviewing ? (
        <>
          <Well
            data-testid={TestIds.MarkdownTextFieldWithPreviewDisplay}
            onDoubleClick={
              isPreviewingInitial === undefined ? togglePreview : undefined
            }
            sx={mixinSx(
              {
                paddingY: 1,
                paddingX: 2,
                minHeight: 36,
                marginBottom: 0.5,
                backgroundColor: previewBackground,
              },
              previewSx
            )}
          >
            {value.length > 0 ? (
              <CustomReactMarkdown source={value} />
            ) : (
              <Typography variant="body2">{previewBlankslate}</Typography>
            )}
          </Well>

          {helperText && <Typography color="error">{helperText}</Typography>}
        </>
      ) : (
        <>
          <TextareaAutosize
            data-testid={TestIds.MarkdownTextFieldWithPreviewTextArea}
            autoFocus={autoFocus}
            className={classes.input}
            id={id}
            name={name}
            value={value}
            minRows={minRows}
            aria-invalid={error}
            disabled={disabled}
            placeholder={placeholder ?? previewBlankslate}
            onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) =>
              onChange(evt, evt.currentTarget.value)
            }
            {...textareaProps}
          />
          {helperText && <Typography color="error">{helperText}</Typography>}
        </>
      )}
      {!disabled && (
        <Box display="flex" alignItems="center" width="100%">
          {!isPreviewing && (
            <Typography variant="caption">
              <Trans context="Caption that indicates a text field will support markdown">
                Supports{' '}
                <TextLink
                  href="https://www.markdownguide.org/basic-syntax/"
                  target="_blank"
                  rel="noreferrer"
                  color="inherit"
                  underline="always"
                >
                  Markdown
                </TextLink>
              </Trans>
            </Typography>
          )}
          <Box flexGrow={1} />
          {isLoading && (
            <Fade in>
              <CircularProgress size={16} color="primary" />
            </Fade>
          )}
          {isPreviewingInitial === undefined && (
            <Button
              onClick={togglePreview}
              disabled={disabled}
              startIcon={
                isPreviewing ? (
                  <PencilIcon />
                ) : defaultToPreview ? (
                  <CheckmarkIcon />
                ) : (
                  <ViewIcon />
                )
              }
              variant="text"
              size="small"
              data-testid={TestIds.MarkdownTextFieldWithPreviewToggle}
            >
              {isPreviewing ? (
                <Trans context="Edit text markdown">Edit</Trans>
              ) : defaultToPreview ? (
                <Trans context="Done editing text markdown">Done</Trans>
              ) : (
                <Trans context="Preview text markdown">Preview</Trans>
              )}
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
}
