import { Backdrop, Box, useTheme } from '@mui/material';
import type { SxProps, Theme } from '@mui/material';
import { Trans } from '@lingui/react/macro';
import CheckIcon from '@watershed/icons/components/Checkmark';
import ChevronDownIcon from '@watershed/icons/components/ChevronDown';
import {
  routeForFinanceOverview,
  routeForHome,
  routeForMeasureProjects,
} from '@watershed/shared-universal/dashboardRoutes';
import { format as formatUrl } from 'url';
import Logo from '@watershed/ui-core/components/Logo';
import Wordmark from '@watershed/ui-core/components/Wordmark';
import { getPaletteUtils, mixinSx, sxType } from '@watershed/style/styleUtils';
import type { Dispatch, SetStateAction } from 'react';
import { memo, useCallback, useMemo, useState } from 'react';
import { transition } from './utils';
import { getSharedSidebarStyles } from './variants/sharedSidebarStyles';

function useSx() {
  const theme = useTheme();
  const { COLORS, BOX_SHADOW_FOCUS } = getSharedSidebarStyles(theme);

  const logoMarkSxProps = sxType({
    backgroundColor: COLORS.BACKGROUND,
    display: 'flex',
    alignItems: 'center',
    padding: '8px',
    textDecoration: 'none',
    cursor: 'pointer',
    fontWeight: 600,
    fontSize: 17,
    transition: transition('opacity'),
    border: '0.5px solid transparent',

    '&, &:focus': {
      color: COLORS.TEXT_PRIMARY,
    },
    '&:focus-visible': {
      boxShadow: BOX_SHADOW_FOCUS,
    },

    '&[aria-current="page"]': {
      borderColor: theme.palette.grey70,
      background: theme.palette.grey80,
      boxShadow: '0px 1px 0.5px 0px rgba(79, 89, 110, 0.08)',
    },
  });

  const popoverLogoMarkSxProps = sxType({
    ...logoMarkSxProps,
    '&:hover': {
      backgroundColor: COLORS.BACKGROUND_ACTIVE,
    },
  });

  return {
    logoMarkSxProps,

    popoverLogoMarkSxProps,

    popoverSxProps: sxType({
      zIndex: 3, // over isSticky NavSection + popover Backdrop
      backgroundColor: theme.palette.grey100,
      borderRadius: 1,
      boxShadow: (theme) => getPaletteUtils(theme.palette).boxShadowMenu,
    }),

    logoMarkTextProps: sxType({
      display: 'inline-flex',
      marginLeft: '4px',
      color: theme.palette.white,
      transition: transition('opacity'),
    }),

    logoColor: COLORS.TEXT_PRIMARY,
  };
}

const chevronSxProps: SxProps<Theme> = { marginLeft: '4px' };

const checkSxProps: SxProps<Theme> = { marginLeft: 'auto' };

/**
 * Renders a product name (wordmark/logo/otherwise) that fades in
 * and out when the appearsCollapsed variable changes.
 */
const FadingProductName = memo(function FadingProductName({
  appearsCollapsed,
  children,
}: {
  appearsCollapsed: boolean;
  children: React.ReactNode;
}) {
  const sx = useSx();
  return (
    <Box
      component="span"
      sx={mixinSx(sx.logoMarkTextProps, {
        opacity: appearsCollapsed ? 0 : 1,
      })}
    >
      {children}
    </Box>
  );
});

/**
 * Shows content for one of the products.
 */
function Content({
  showCheck,
  showChevron,
  appearsCollapsed,
  setPopoverOpen,
  shouldTargetFinancePage,
  hasHome,
}: {
  showCheck?: boolean;
  showChevron?: boolean;
  appearsCollapsed: boolean;
  popoverOpen: boolean;
  setPopoverOpen: Dispatch<SetStateAction<boolean>>;
  shouldTargetFinancePage: boolean;
  hasHome: boolean;
}) {
  const sx = useSx();
  const route = useMemo(() => {
    if (showChevron) {
      return undefined;
    }
    if (shouldTargetFinancePage) {
      return routeForFinanceOverview({});
    }
    return hasHome ? routeForHome() : routeForMeasureProjects();
  }, [hasHome, shouldTargetFinancePage, showChevron]);

  const handleClick = useCallback(() => {
    if (showChevron) {
      setPopoverOpen((popoverOpen) => !popoverOpen);
    }
  }, [setPopoverOpen, showChevron]);

  const innerBox = (
    <Box
      onClick={handleClick}
      sx={mixinSx(
        showChevron ? sx.logoMarkSxProps : sx.popoverLogoMarkSxProps,
        {
          borderRadius: showChevron
            ? shouldTargetFinancePage
              ? '0 0 6px 6px'
              : '6px 6px 0 0'
            : 1,
        }
      )}
      aria-current={showCheck ? 'page' : undefined}
    >
      <Logo
        size={24}
        color={shouldTargetFinancePage ? 'newDay' : 'periwinkle'}
      />
      <FadingProductName appearsCollapsed={appearsCollapsed}>
        {shouldTargetFinancePage ? (
          <Box marginLeft={0.25} lineHeight={1}>
            <Trans context="The name of Watershed's Finance product">
              Finance
            </Trans>
          </Box>
        ) : (
          <Wordmark size={12.45} color={sx.logoColor} />
        )}
      </FadingProductName>
      {showChevron && (
        <ChevronDownIcon sx={chevronSxProps} color={sx.logoColor} />
      )}
      {showCheck && <CheckIcon sx={checkSxProps} />}
    </Box>
  );

  if (!route) return innerBox;

  return <a href={formatUrl(route)}>{innerBox}</a>;
}

const LogoBar = memo(function LogoBar({
  isOnFinancePage,
  canToggle,
  ...props
}: {
  isOnFinancePage: boolean;
  canToggle: boolean;
  appearsCollapsed: boolean;
  hasHome: boolean;
}) {
  const sx = useSx();
  const [popoverOpen, setPopoverOpen] = useState(false);
  const contentProps = {
    ...props,
    isOnFinancePage,
    setPopoverOpen,
    popoverOpen,
  };

  return (
    <>
      <Content
        {...contentProps}
        showChevron={canToggle}
        shouldTargetFinancePage={isOnFinancePage}
      />
      {popoverOpen && (
        <>
          <Backdrop
            open={popoverOpen}
            onClick={popoverOpen ? () => setPopoverOpen(false) : undefined}
            sx={{ zIndex: 2 }}
          />
          <Box
            position="fixed"
            width={252}
            top={4}
            left={4}
            padding={0.5}
            display="grid"
            gridTemplateColumns="1fr"
            gap={0.5}
            sx={sx.popoverSxProps}
            zIndex={3}
          >
            <Content
              {...contentProps}
              showChevron={false}
              showCheck={true}
              shouldTargetFinancePage={isOnFinancePage}
            />
            <Content
              {...contentProps}
              showChevron={false}
              showCheck={false}
              shouldTargetFinancePage={!isOnFinancePage}
            />
          </Box>
        </>
      )}
    </>
  );
});

export default LogoBar;
