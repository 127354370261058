import { forwardRef } from 'react';
import Icon, { IconProps } from '../Icon';
const PrivateIcon = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Icon ref={ref} viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path d="M8.793 8.293a1 1 0 0 1 1.414 0L12 10.086l1.793-1.793a1 1 0 1 1 1.414 1.414L13.414 11.5l1.793 1.793a1 1 0 0 1-1.414 1.414L12 12.914l-1.793 1.793a1 1 0 0 1-1.414-1.414l1.793-1.793-1.793-1.793a1 1 0 0 1 0-1.414Z" />
    <path
      fillRule="evenodd"
      d="M11.649 1.064a1 1 0 0 1 .702 0l8 3A1 1 0 0 1 21 5v7c0 3.446-2.282 6.2-4.341 8.003a22.684 22.684 0 0 1-4.104 2.836 8.28 8.28 0 0 1-.076.04l-.022.01-.006.004h-.002s-.002.001-.449-.893l-.448.894-.003-.001-.006-.004-.022-.01a8.28 8.28 0 0 1-.35-.188 22.686 22.686 0 0 1-3.83-2.689C5.281 18.2 3 15.447 3 12V5a1 1 0 0 1 .649-.936l8-3ZM12 20.863a20.69 20.69 0 0 0 3.341-2.365C17.282 16.8 19 14.553 19 12V5.693l-7-2.625-7 2.625V12c0 2.554 1.718 4.8 3.659 6.497A20.692 20.692 0 0 0 12 20.863Z"
      clipRule="evenodd"
    />
    <path d="M11.552 22.894c.282.14.614.141.895 0L12 22l-.448.894Z" />
  </Icon>
));
export default PrivateIcon;
