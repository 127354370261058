import { GQFootprintsForFootprintListQuery } from '@watershed/shared-universal/generated/graphql';

/**
 * Updates the caches locally after generate auto footprint gets kicked off,
 * so that we mark the footprint as generating for our main footprints list
 * view.
 */
export function updateFootprintsAfterGenerateAutoFootprint(
  data: GQFootprintsForFootprintListQuery | null,
  footprintId: string
): GQFootprintsForFootprintListQuery | null {
  if (!data) {
    return data;
  }
  // Update the edge that references this footprint
  const edges = data.footprints.edges.map((edge) => {
    if (edge?.node?.id !== footprintId) {
      return edge;
    }
    return {
      ...edge,
      node: {
        ...edge.node,
        isGenerating: true,
      },
    };
  });
  return {
    ...data,
    footprints: {
      ...data.footprints,
      edges,
    },
  };
}
