import must from '../utils/must';
import { plural, t } from '@lingui/core/macro';
import {
  GQPermissionType,
  GQUserTablePermissionsFragment,
} from '../generated/graphql';
import { joinWithAnd } from '../utils/helpers';
import mapStrToEnum from '../utils/mapStrToEnum';
import groupBy from 'lodash/groupBy';
import assertNever from '@watershed/shared-util/assertNever';

import { SupportedLocale } from '@watershed/intl/constants';
import isNotNullish from '@watershed/shared-util/isNotNullish';
import { formatList } from '@watershed/intl/formatters';
import { Action } from './permissionTypes';

export function getPermissionTypeDisplayName(
  permission: GQPermissionType
): string {
  switch (permission) {
    case GQPermissionType.ManageDataset:
      return t`Manage datasets`;
    case GQPermissionType.ManageDatasource:
      return t`Manage data sources`;
    case GQPermissionType.ApproveDataset:
      return t`Approve datasets`;
    case GQPermissionType.ApproveDatasource:
      return t`Approve data sources`;
    case GQPermissionType.ManageCompanyTags:
      return t`Manage custom columns`;
    case GQPermissionType.ManageOrgHierarchy:
      return t`Manage organizational structure`;
    case GQPermissionType.Admin:
      return t`Admin`;
    case GQPermissionType.ManageMarketplacePurchases:
      return t`Manage Marketplace purchases`;
    case GQPermissionType.ManageMeasurement:
      return t`Manage measurement`;
    case GQPermissionType.ManageReductionPlans:
      return t`Manage reduction plans`;
    case GQPermissionType.ManageSingleSignOn:
      return t`Manage single sign on`;
    case GQPermissionType.ManageSuppliers:
      return t`Manage suppliers`;
    case GQPermissionType.ManageDisclosures:
      return t`Manage disclosures`;
    case GQPermissionType.ViewEmployeeReport:
      return t`View summary report`;
    case GQPermissionType.ViewFootprintDetail:
      return t`View footprint detail`;
    case GQPermissionType.ViewReductions:
      return t`View reductions`;
    case GQPermissionType.EditReport:
      return t({
        message: 'Edit report',
        context: 'Permission to view and manage a report',
      });
    case GQPermissionType.ViewReport:
      return t({
        message: 'View report',
        context: 'Permission to view a report',
      });
    case GQPermissionType.EditReportQuestionInstance:
      return t({
        message: 'Edit report question',
        context: 'Permission to view and edit a question in a report',
      });
    case GQPermissionType.ViewReportQuestionInstance:
      return t({
        message: 'View report question',
        context: 'Permission to view a specific report question',
      });
    case GQPermissionType.AnyUser:
      return t({
        message: 'Any user',
        context: 'Permission to view a specific report question',
      });
    case GQPermissionType.WatershedAdmin:
      return t`Watershed admin`;
    case GQPermissionType.FinanceAdmin:
      return t`Finance admin`;
    case GQPermissionType.ManageFund:
      return t({
        message: 'Manage fund',
        context: 'Permission to manage a finance fund',
      });
    case GQPermissionType.FinanceReadOnly:
      return t({
        message: 'Finance view-only',
        context: 'View-only access to the Finance product',
      });
    case GQPermissionType.ViewLearningHub:
      return t`View Learning Hub`;
    case GQPermissionType.CorporateAdmin:
      return t`Corporate admin`;
    case GQPermissionType.ViewAuditDetail:
      return t`View audit detail`;
    default:
      assertNever(permission);
  }
}

export function getActionDisplayName(action: Action): string {
  switch (action) {
    case Action.View:
      return t`View`;
    case Action.Edit:
      return t`Edit`;
    case Action.Manage:
      return t`Manage`;
    case Action.Approve:
      return t`Approve`;
  }
}

export function getPermissionDisplayName(
  permission: GQPermissionType,
  objectIds: Array<string | null> = [],
  objectName?: string,
  locale?: SupportedLocale
): string {
  const nonNullCount = objectIds.filter(Boolean).length;
  switch (permission) {
    case GQPermissionType.ManageDataset:
      if (nonNullCount === 0 || objectIds.includes(null)) {
        return t`Manage all datasets`;
      } else if (nonNullCount === 1 && objectName) {
        return t`Manage dataset: ${objectName}`;
      } else {
        const numDatasets = objectIds.length;
        return plural(numDatasets, {
          one: 'Manage # dataset',
          other: 'Manage # datasets',
        });
      }

    case GQPermissionType.ManageDatasource:
      if (nonNullCount === 0 || objectIds.includes(null)) {
        return t`Manage all data sources`;
      } else if (nonNullCount === 1 && objectName) {
        return t`Manage data source: ${objectName}`;
      } else {
        const numDatasources = objectIds.length;
        return plural(numDatasources, {
          one: 'Manage # data source',
          other: 'Manage # data sources',
        });
      }

    case GQPermissionType.ApproveDataset:
      if (nonNullCount === 0 || objectIds.includes(null)) {
        return t`Approve all datasources`;
      } else if (nonNullCount === 1 && objectName) {
        return t`Approve ${objectName} dataset`;
      } else {
        const numDatasources = objectIds.length;
        return plural(numDatasources, {
          one: 'Approve # dataset',
          other: 'Approve # dataset',
        });
      }

    case GQPermissionType.ApproveDatasource:
      if (nonNullCount === 0 || objectIds.includes(null)) {
        return t`Approve all data sources`;
      } else if (nonNullCount === 1 && objectName) {
        return t`Approve data source: ${objectName}`;
      } else {
        const numDatasources = objectIds.length;
        return plural(numDatasources, {
          one: 'Approve # data source',
          other: 'Approve # data sources',
        });
      }

    case GQPermissionType.ManageFund:
      if (nonNullCount === 0 || objectIds.includes(null)) {
        return t`Manage all funds`;
      } else if (nonNullCount === 1 && objectName) {
        return t`Manage fund: ${objectName}`;
      } else {
        const numFunds = objectIds.length;
        return plural(numFunds, {
          one: 'Manage # fund',
          other: 'Manage # funds',
        });
      }

    case GQPermissionType.FinanceReadOnly:
      if (nonNullCount === 0 || objectIds.includes(null)) {
        return `View all funds`;
      } else if (nonNullCount === 1 && objectName) {
        return t`View fund: ${objectName}`;
      } else {
        const numFunds = objectIds.length;
        return plural(numFunds, {
          one: 'View # fund',
          other: 'View # funds',
        });
      }

    case GQPermissionType.ManageSuppliers:
      if (nonNullCount === 0 || objectIds.includes(null)) {
        return t`Manage all suppliers`;
      } else if (nonNullCount === 1 && objectName) {
        return t`Manage supplier: ${objectName}`;
      } else {
        const numSuppliers = objectIds.length;
        return plural(numSuppliers, {
          one: 'Manage # supplier',
          other: 'Manage # suppliers',
        });
      }

    case GQPermissionType.EditReport:
      if (nonNullCount === 0 || objectIds.includes(null)) {
        return t`Edit all questions in all reports`;
      } else if (nonNullCount === 1 && objectName) {
        return t`Edit report: ${objectName}`;
      } else {
        const numReports = objectIds.length;
        return plural(numReports, {
          one: 'Edit # report',
          other: 'Edit # reports',
        });
      }

    case GQPermissionType.EditReportQuestionInstance:
      if (nonNullCount === 0 || objectIds.includes(null)) {
        return t`Edit all questions in all reports`;
      } else if (nonNullCount === 1 && objectName) {
        return t`Edit report question: ${objectName}`;
      } else {
        const numQuestions = objectIds.length;
        return plural(numQuestions, {
          one: 'Edit # report question',
          other: 'Edit # report questions',
        });
      }

    case GQPermissionType.ViewReport:
      if (nonNullCount === 0 || objectIds.includes(null)) {
        return t`View all questions in all reports`;
      } else if (nonNullCount === 1 && objectName) {
        return t`View report: ${objectName}`;
      } else {
        const numReports = objectIds.length;
        return plural(numReports, {
          one: 'View # report',
          other: 'View # reports',
        });
      }

    case GQPermissionType.ViewReportQuestionInstance:
      if (nonNullCount === 0 || objectIds.includes(null)) {
        return t`View all questions in all reports`;
      } else if (nonNullCount === 1 && objectName) {
        return t`View report question: ${objectName}`;
      } else {
        const numQuestions = objectIds.length;
        return plural(numQuestions, {
          one: 'View # report question',
          other: 'View # report questions',
        });
      }

    default:
      return getPermissionTypeDisplayName(permission);
  }
}

export function getPermissionDescription(
  permission?: GQPermissionType | null
): string | undefined {
  switch (permission) {
    case GQPermissionType.ManageDataset:
      return t`Allows viewing summaries and uploading files for selected datasets`;
    case GQPermissionType.ManageDatasource:
      return t`Allows viewing summaries and uploading files for selected data sources`;
    case GQPermissionType.ApproveDataset:
      return t`Allows viewing and approving selected datasets`;
    case GQPermissionType.ApproveDatasource:
      return t`Allows viewing and approving data sources`;
    case GQPermissionType.ManageCompanyTags:
      return t`Allows viewing and approving your custom columns`;
    case GQPermissionType.ManageOrgHierarchy:
      return t`Allows modifying your organizational structure`;
    case GQPermissionType.Admin:
      return t`Full access to the dashboard, including inviting new users`;
    case GQPermissionType.CorporateAdmin:
      return t`Full access to the Corporate product, including inviting new users`;
    case GQPermissionType.ManageMarketplacePurchases:
      return t`View and purchase offsets on the Marketplace`;
    case GQPermissionType.ManageMeasurement:
      return t`View and manage footprint measurement`;
    case GQPermissionType.ManageReductionPlans:
      return t`View and update reduction plans`;
    case GQPermissionType.ManageSingleSignOn:
      return t`Manage single sign on for the org`;
    case GQPermissionType.ManageSuppliers:
      return t`View and update suppliers`;
    case GQPermissionType.ManageDisclosures:
      return t`View and respond to disclosure requests (surveys) from customers`;
    case GQPermissionType.ViewEmployeeReport:
      return t`View the summary report if one exists`;
    case GQPermissionType.ViewFootprintDetail:
      return t`View the full footprint and insights`;
    case GQPermissionType.ViewAuditDetail:
      return t`View details about calculations and data (for e.g., auditors and verifiers)`;
    case GQPermissionType.ViewReductions:
      return t`View reduction plans`;
    case GQPermissionType.WatershedAdmin:
      return t`Superadmin permission for Watershed employees only`;
    case GQPermissionType.FinanceAdmin:
      return t`Full access to the Finance product, including inviting new users`;
    case GQPermissionType.ManageFund:
      return t`View and manage fund-level data and assets`;
    case GQPermissionType.FinanceReadOnly:
      return t`View-only access to the Finance product`;
    case GQPermissionType.ViewLearningHub:
      return t`View the Learning Hub (already accessible with any other permission)`;
    case GQPermissionType.AnyUser:
      return t`Permission that all users have`;
    case GQPermissionType.EditReport:
      return t`View and manage a report`;
    case GQPermissionType.ViewReport:
      return t`View a specific report. This allows a user to navigate to the report of interest and see any information in it without being able to answer questions or make changes to the report.`;
    case GQPermissionType.EditReportQuestionInstance:
      return t`View and edit a question in a report`;
    case GQPermissionType.ViewReportQuestionInstance:
      return t`View a specific question in a report. This allows a user to navigate to the report of interest and see only questions they have permissions to view.`;
    case null:
    case undefined:
      return undefined;
    default:
      assertNever(permission);
  }
}

export function getPermissionGroupDescription(
  permission: GQPermissionType,
  objectNames: Array<string | undefined>,
  locale: SupportedLocale
): string | undefined {
  const names = objectNames.filter(isNotNullish);
  const listOfNames = formatList(names, {
    type: 'conjunction',
    style: 'long',
    locale,
  });

  const objectNamesLength = objectNames.length;

  switch (permission) {
    case GQPermissionType.ManageDataset:
      return objectNamesLength === 0 || objectNames.includes(undefined)
        ? t`Upload and view all dataset summaries`
        : plural(objectNamesLength, {
            one: `Upload and view summary for the ${listOfNames} dataset`,
            other: `Upload and view summaries for the ${listOfNames} datasets`,
          });

    case GQPermissionType.ManageDatasource:
      return objectNamesLength === 0 || objectNames.includes(undefined)
        ? t`Upload and view all data source summaries`
        : plural(objectNamesLength, {
            one: `Upload and view summaries for the ${listOfNames} data source`,
            other: `Upload and view summaries for the ${listOfNames} data sources`,
          });

    case GQPermissionType.ApproveDatasource:
      return objectNamesLength === 0 || objectNames.includes(undefined)
        ? t`View and approve all data sources`
        : plural(objectNamesLength, {
            one: `View and approve summaries for the ${listOfNames} data source`,
            other: `View and approve summaries for the ${listOfNames} data sources`,
          });

    case GQPermissionType.ManageSuppliers:
      return objectNamesLength === 0 || objectNames.includes(undefined)
        ? t`View and update all suppliers`
        : t`View and update these suppliers: ${listOfNames}`;
    case GQPermissionType.EditReport:
      return objectNamesLength === 0 || objectNames.includes(undefined)
        ? t`View and edit all questions in all reports`
        : plural(objectNamesLength, {
            one: 'View and edit a report',
            other: `View and edit ${objectNamesLength} reports`,
          });
    case GQPermissionType.ViewReport:
      return objectNamesLength === 0 || objectNames.includes(undefined)
        ? t({ message: 'View report', context: 'Permission to view a report' })
        : plural(objectNamesLength, {
            one: 'View a report',
            other: `View ${objectNamesLength} reports`,
          });
    case GQPermissionType.EditReportQuestionInstance:
      return objectNamesLength === 0 || objectNames.includes(undefined)
        ? t`View and edit all questions in all reports`
        : plural(objectNamesLength, {
            one: 'View and edit one question in a report',
            other: `View and edit ${objectNamesLength} questions in reports`,
          });
    case GQPermissionType.ViewReportQuestionInstance:
      return objectNamesLength === 0 || objectNames.includes(undefined)
        ? t`View report questions`
        : plural(objectNamesLength, {
            one: 'View a question in a report',
            other: `View ${objectNamesLength} questions in reports`,
          });
    default:
      return getPermissionDescription(permission);
  }
}

export function getRolesAndPermissionsList(
  roles: Readonly<GQUserTablePermissionsFragment['roles']>,
  permissions: Readonly<
    Array<{
      permission: GQPermissionType;
      objectId: string | null;
      revokedAt: Date | null;
    }>
  >
): [Array<string>, Array<string>] {
  const roleNames = roles
    .filter(({ revokedAt, role }) => !revokedAt && role)
    .map(({ role }) => must(role).name);
  const permissionNames = Object.entries(
    groupBy(
      permissions.filter(({ revokedAt }) => !revokedAt),
      (p) => p.permission
    )
  ).map(([permission, entries]) =>
    getPermissionDisplayName(
      mapStrToEnum(permission, GQPermissionType),
      entries.map((e) => e.objectId)
    )
  );
  return [roleNames, permissionNames.reverse()];
}

export function getRolesAndPermissionsSummary(
  roles: Readonly<GQUserTablePermissionsFragment['roles']>,
  permissions: Readonly<GQUserTablePermissionsFragment['permissions']>
): string {
  return joinWithAnd(getRolesAndPermissionsList(roles, permissions).flat());
}
