import { forwardRef } from 'react';
import Icon, { IconProps } from '../Icon';
const EmissionFactorsIcon = forwardRef<SVGSVGElement, IconProps>(
  (props, ref) => (
    <Icon ref={ref} viewBox="0 0 24 24" fill="currentColor" {...props}>
      <path
        d="M5 4a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H5ZM2 5a3 3 0 0 1 3-3h14a3 3 0 0 1 3 3v14a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5Z"
        clipRule="evenodd"
      />
      <path
        d="M13.29 8.336c-.273.28-.49.806-.49 1.664v4.2c0 1.026-.226 2.026-.924 2.763C11.168 17.71 10.154 18 9 18a1 1 0 1 1 0-2c.846 0 1.232-.21 1.424-.413.202-.213.376-.613.376-1.387V10c0-1.142.283-2.266 1.06-3.061.807-.827 1.96-1.11 3.275-.93a1 1 0 1 1-.27 1.982c-.884-.12-1.332.097-1.575.345Z"
        clipRule="evenodd"
      />
      <path
        d="M8 11.2a1 1 0 0 1 1-1h5.7a1 1 0 1 1 0 2H9a1 1 0 0 1-1-1Z"
        clipRule="evenodd"
      />
    </Icon>
  )
);
export default EmissionFactorsIcon;
