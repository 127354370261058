import { forwardRef } from 'react';
import Icon, { IconProps } from '../Icon';
const TableIcon = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Icon ref={ref} viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M2 5a3 3 0 0 1 3-3h14a3 3 0 0 1 3 3v14a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V5Zm11 15h6a1 1 0 0 0 1-1v-2h-7v3Zm-2-3v3H5a1 1 0 0 1-1-1v-2h7Zm2-2h7v-3h-7v3Zm-2-3v3H4v-3h7Zm2-2h7V7h-7v3Zm-2-3v3H4V7h7Z"
      clipRule="evenodd"
    />
  </Icon>
));
export default TableIcon;
