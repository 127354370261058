import { Box, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Analytics } from '@watershed/analytics/analyticsUtils';
import ArrowLeftIcon from '@watershed/icons/components/ArrowLeft';
import { TestIds } from '@watershed/shared-universal/utils/testUtils';
import Link from '@watershed/ui-core/components/TextLink';
import { Url } from '@watershed/ui-core/types/LinkProps';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    breadcrumb: {
      height: 24,
      marginBottom: theme.spacing(0.5),
      overflow: 'hidden',
      '& svg': { flexShrink: 0 },
    },
    breadcrumbLink: {
      display: 'inline-flex',
      alignItems: 'center',
      whiteSpace: 'nowrap',
    },
  })
);

export interface BreadcrumbProps {
  name: string;
  path?: Url;
  onClick?: () => void;
}

export default function Breadcrumb({
  breadcrumb,
}: {
  breadcrumb: BreadcrumbProps;
}) {
  const classes = useStyles();
  const linkContent = (
    <Box alignItems="center" display="flex">
      <ArrowLeftIcon marginRight={0.5} size={16} />
      <Typography variant="h4" color="primary">
        {breadcrumb.name}
      </Typography>
    </Box>
  );
  const link = breadcrumb.path ? (
    <Link
      href={breadcrumb.path}
      className={classes.breadcrumbLink}
      color="primary"
      underline="none"
      data-testid={TestIds.Breadcrumb}
      onClick={() => {
        Analytics.action('breadcrumb', {
          label: breadcrumb.name,
          link: breadcrumb.path,
        });
      }}
    >
      {linkContent}
    </Link>
  ) : (
    <Link
      className={classes.breadcrumbLink}
      color="primary"
      underline="none"
      data-testid={TestIds.Breadcrumb}
      onClick={() => {
        if (breadcrumb.onClick) {
          breadcrumb.onClick();
        }
        Analytics.action('breadcrumb', {
          label: breadcrumb.name,
        });
      }}
    >
      {linkContent}
    </Link>
  );

  return <Box className={classes.breadcrumb}>{link}</Box>;
}
