import { forwardRef } from 'react';
import Icon, { IconProps } from '../Icon';
const ScienceIcon = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Icon ref={ref} viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M9 1a2 2 0 0 0-2 2v2.086A1.5 1.5 0 0 0 6 6.5V10c0 1.652 1.348 3 3 3a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2 3 3 0 0 0 3-3V9a6 6 0 1 1 0 12H3a1 1 0 1 0 0 2h18a1 1 0 1 0 0-2h-1.709A8 8 0 0 0 14 7v-.5a1.5 1.5 0 0 0-1-1.414V3a2 2 0 0 0-2-2H9Zm3 6H8v3c0 .548.452 1 1 1h2a1 1 0 0 0 1-1V7ZM9 5h2V3H9v2Z"
      clipRule="evenodd"
    />
    <path d="M6 17a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2H6Z" />
  </Icon>
));
export default ScienceIcon;
