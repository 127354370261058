import { useState, useEffect } from 'react';

/**
 * This hook takes a scriptUrl + optional predicate to load an external script
 * This can be handy for components that require a 3rd party script to be loaded
 */
export default function useLoadScript({
  scriptUrl,
  isScriptLoaded,
}: {
  scriptUrl: string;
  isScriptLoaded?: () => boolean;
}) {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (isScriptLoaded?.()) {
      setIsLoaded(true);
      return;
    }

    const script = document.createElement('script');
    script.onload = () => {
      setIsLoaded(true);
    };

    script.src = scriptUrl;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [scriptUrl, isScriptLoaded]);

  return [isLoaded];
}
