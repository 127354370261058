import { Stack } from '@mui/material';
import { useLingui } from '@lingui/react/macro';

import { IconElement } from '@watershed/icons/Icon';
import DeleteIcon from '@watershed/icons/components/Delete';
import React from 'react';
import IconButton from '../../IconButton';
import DownloadIcon from '@watershed/icons/components/Download';
import { TestIds } from '@watershed/shared-universal/utils/testUtils';
import { UploadFile } from '../../../utils/FileUpload';
import ViewIcon from '@watershed/icons/components/View';
import { FileItemRow } from './FileItemRow';
import { SheetSelection } from './SheetSelection';
import { SheetWithSelection } from './SheetSelection';
import CloseIcon from '@watershed/icons/components/Close';

export interface FileItemProps {
  file: UploadFile;
  removeUpload?: (fileId: string) => void;
  disabled: boolean;
  progress: number;
  sheets?: Array<SheetWithSelection>;
  onChangeSheetSelection: (
    fileId: string,
    sheetSelections: Array<boolean>
  ) => void;
  onClickPreviewFile?: (file: UploadFile) => void;
  getDownloadUrl?: (file: UploadFile) => Promise<string | undefined>;
  tablePreviewer?: (tableId: string) => React.ReactNode;
}

/**
 * Internal component used in `FileUploadDropzone`, exposing standard actions for a file item and the
 * ability to pick which sheets to import from an Excel file.
 */
export function FileItem({
  file,
  removeUpload,
  disabled,
  progress,
  sheets,
  onChangeSheetSelection,
  onClickPreviewFile,
  getDownloadUrl,
  tablePreviewer,
}: FileItemProps) {
  const { t } = useLingui();
  const { filename, fileId, isImported, error, uploadDate } = file;

  let statusMessage: LocalizedString;
  let statusIcon: 'success' | 'error' | 'loading';
  if (error) {
    statusIcon = 'error';
    statusMessage = error;
  } else if (isImported) {
    statusIcon = 'success';
    statusMessage = uploadDate
      ? t`Imported on ${uploadDate}`
      : t`File imported successfully`;
  } else {
    statusIcon = 'loading';
    statusMessage = progress < 100 ? t`Uploading…` : t`Processing…`;
  }

  const downloadFile = async () => {
    if (getDownloadUrl === undefined) {
      return;
    }
    const downloadUrl = await getDownloadUrl(file);
    if (downloadUrl === undefined) {
      return;
    }
    window.open(downloadUrl, '_new');
  };

  return (
    <FileItemRow
      icon={statusIcon}
      title={filename}
      subtitle={statusMessage}
      progress={progress}
      action={
        isImported ? (
          <Stack direction="row" gap={1}>
            {onClickPreviewFile !== undefined && (
              <ActionButton
                disabled={false}
                onClick={() => onClickPreviewFile(file)}
                Icon={ViewIcon}
              />
            )}
            {getDownloadUrl !== undefined && (
              <ActionButton
                disabled={disabled}
                onClick={downloadFile}
                Icon={DownloadIcon}
              />
            )}
            <ActionButton
              disabled={disabled}
              onClick={() => removeUpload?.(fileId)}
              Icon={DeleteIcon}
            />
          </Stack>
        ) : (
          // Cancel buttons aren't disabled ever, because it should be accessible any time it shows up
          <IconButton
            data-testid={TestIds.FileUploadMultipleCancel}
            onClick={() => removeUpload?.(fileId)}
            size="large"
            color="primary"
          >
            <CloseIcon size={16} />
          </IconButton>
        )
      }
    >
      {sheets && (
        <SheetSelection
          sheets={sheets}
          onChange={(selectedSheets) =>
            onChangeSheetSelection(fileId, selectedSheets)
          }
          tablePreviewer={tablePreviewer}
        />
      )}
    </FileItemRow>
  );
}

/**
 * Standard buttons for actions on a file item
 */
function ActionButton({
  Icon,
  onClick,
  disabled,
}: {
  Icon: IconElement;
  onClick: () => void;
  disabled: boolean;
}) {
  return (
    <IconButton
      disabled={disabled}
      onClick={onClick}
      color="primary"
      size="large"
      sx={(theme) => ({
        '&:hover, &:focus': {
          color: theme.palette.text.primary,
        },
        '&:hover': {
          backgroundColor: theme.palette.background.default,
        },
      })}
    >
      <Icon size={16} />
    </IconButton>
  );
}
