import { forwardRef } from 'react';
import Icon, { IconProps } from '../Icon';
const ConversationIcon = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Icon ref={ref} viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M5 11.505v.005a7.38 7.38 0 0 0 .648 3.036A2 2 0 0 1 5.721 16l-1.14 3.42 3.42-1.14a2 2 0 0 1 1.453.073 7.38 7.38 0 0 0 3.042.648h.005A7.5 7.5 0 1 0 5 11.5v.005Zm-2 0V11.5a9.5 9.5 0 1 1 5.634 8.676L3.316 21.95a1 1 0 0 1-1.265-1.265l1.773-5.318A9.38 9.38 0 0 1 3 11.508v-.003Z"
      clipRule="evenodd"
    />
  </Icon>
));
export default ConversationIcon;
