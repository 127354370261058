import { forwardRef } from 'react';
import Icon, { IconProps } from '../Icon';
const LanguageIcon = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Icon ref={ref} viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M6 2a1 1 0 0 1 1-1h1a1 1 0 0 1 0 2H7a1 1 0 0 1-1-1Zm5.986 2H2a1 1 0 0 0 0 2h8.132l-.91 1.363L7.5 9.086 5.707 7.293a1 1 0 0 0-1.414 1.414L6.086 10.5l-2.793 2.793a1 1 0 1 0 1.414 1.414L7.5 11.914l2.793 2.793a1 1 0 0 0 1.414-1.414L8.914 10.5l1.793-1.793a1.01 1.01 0 0 0 .125-.152L12.535 6H14a1 1 0 1 0 0-2h-2.014ZM17 11a1 1 0 0 1 .894.553l2.991 5.982.02.037 1.99 3.98a1 1 0 1 1-1.79.895L19.383 19h-4.764l-1.724 3.447a1 1 0 1 1-1.788-.894l1.99-3.98.019-.038 2.99-5.982A1 1 0 0 1 17 11Zm-1.382 6h2.764L17 14.236 15.618 17Z"
      clipRule="evenodd"
    />
  </Icon>
));
export default LanguageIcon;
