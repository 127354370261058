import { forwardRef } from 'react';
import Icon, { IconProps } from '../Icon';
const SendIcon = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Icon ref={ref} viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M22.707 1.293a.998.998 0 0 1 .23 1.058l-6.993 19.98a1 1 0 0 1-1.858.075l-3.844-8.648-8.648-3.844a1 1 0 0 1 .076-1.858l19.979-6.993a1 1 0 0 1 1.058.23ZM18.194 4.39 4.711 9.111l6.067 2.696 7.416-7.416Zm-6.001 8.83 7.415-7.415-4.719 13.483-2.696-6.068Z"
      clipRule="evenodd"
    />
  </Icon>
));
export default SendIcon;
