import { FileRejection, useDropzone } from 'react-dropzone';
import {
  RejectedFile,
  FileType,
  validateFile,
  getAcceptObject,
} from '../../../utils/FileUpload';
import { DropAreaContainer } from './DropAreaContainer';
import { formatList } from '@watershed/intl/formatters';
import useLocale from '@watershed/intl/frontend/useLocale';
import { useLingui } from '@lingui/react/macro';

export interface DropAreaProps {
  acceptedFileTypes: Array<FileType>;
  maxSizeBytes?: number;
  /**
   * Use this to provide any helpful context about the files being uploaded, like "Attach any documentation about this LCA."
   */
  additionalUsageText?: LocalizedString;
  onDropAccepted: (acceptedFiles: Array<File>) => void;
  rejectFiles: (rejectedFiles: Array<RejectedFile>) => void;
  progressMessage: LocalizedString;
  inProgress: boolean;
  dropzoneDisabled: boolean;
  isCompact: boolean;
}

/**
 * Internal component to `FileUploadDropzone` that exposes a dropzone
 * for files to be uploaded.
 */
export function DropArea({
  acceptedFileTypes,
  maxSizeBytes,
  onDropAccepted,
  rejectFiles,
  ...dropzoneProps
}: DropAreaProps) {
  const locale = useLocale();
  const { t } = useLingui();
  const onDropAcceptedWrapper = async (files: Array<File>) => {
    const rejectedFiles: Array<RejectedFile> = [];
    const acceptedFiles: Array<File> = [];

    await Promise.all(
      files.map(async (file) => {
        try {
          await validateFile(file, { maxSizeBytes });
          acceptedFiles.push(file);
        } catch (error) {
          rejectedFiles.push({
            name: file.name,
            message: (error as Error).message,
          });
        }
      })
    );

    if (rejectedFiles.length > 0) {
      rejectFiles(rejectedFiles);
    }
    if (acceptedFiles.length > 0) {
      onDropAccepted(acceptedFiles);
    }
  };

  /**
   * Handle rejections from react-dropzone, which we only use for filetype validation
   */
  const onDropRejectedWrapper = (fileRejections: Array<FileRejection>) =>
    rejectFiles(
      fileRejections.map((r) => {
        // We provide our own file invalid error messages for readability
        const errors = r.errors.map((e) => {
          if (e.code === 'file-invalid-type') {
            const list = formatList(
              acceptedFileTypes.map((ft) => ft.valueOf()),
              { locale }
            );
            const message = t({
              message: `This file type is not supported. Upload a file with one of these extensions: ${list}`,
            });
            return { ...e, message };
          }
          return e;
        });
        return {
          name: r.file.name,
          message: formatList(
            errors.map((e) => e.message),
            { locale }
          ),
        };
      })
    );

  const dropzoneState = useDropzone({
    accept: getAcceptObject(acceptedFileTypes),
    onDrop: onDropAcceptedWrapper,
    onDropRejected: onDropRejectedWrapper,
  });

  return (
    <DropAreaContainer
      dropzoneState={dropzoneState}
      {...dropzoneProps}
      acceptedFileTypes={acceptedFileTypes}
    />
  );
}
