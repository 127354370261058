import { forwardRef } from 'react';
import Icon, { IconProps } from '../Icon';
const StackIcon = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Icon ref={ref} viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      d="M10.755 1.27a3 3 0 0 1 2.49 0l8.585 3.908a2 2 0 0 1 0 3.654l-8.575 3.898a3.002 3.002 0 0 1-2.49 0L2.19 8.822a2 2 0 0 1 0-3.654l8.565-3.898ZM12 3a1 1 0 0 0-.415.09L3.003 6.995 3.015 7l8.58 3.91a1.002 1.002 0 0 0 .83 0l8.591-3.905-.01-.005-8.59-3.91A.995.995 0 0 0 12 3ZM1.09 17.237a1 1 0 0 1 1.323-.498l9.172 4.161a.999.999 0 0 0 .83 0h.002l9.17-4.16a1 1 0 1 1 .826 1.82l-9.168 4.16h-.001a3 3 0 0 1-2.488 0h-.001l-9.168-4.16a1 1 0 0 1-.498-1.323Z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M1.09 12.237a1 1 0 0 1 1.323-.498l9.172 4.161a1 1 0 0 0 .83 0h.002l9.17-4.16a1 1 0 0 1 .826 1.82l-9.168 4.16h-.001a3 3 0 0 1-2.488 0h-.001l-9.168-4.16a1 1 0 0 1-.498-1.323Z"
      clipRule="evenodd"
    />
  </Icon>
));
export default StackIcon;
