import { useEffect, useState, useRef, useId, useCallback } from 'react';
import intro from '../json/BigLoading/intro.json';
import loop from '../json/BigLoading/loop.json';
import fail from '../json/BigLoading/fail.json';
import success from '../json/BigLoading/success.json';
import { LottieAnimation } from '../types';
import invariant from 'invariant';
import LottieContainer, { LottieContainerProps } from '../LottieContainer';
import { TestIds } from '@watershed/shared-universal/utils/testUtils';

export enum AnimationState {
  InProgress = 'InProgress',
  Complete = 'Complete',
  Error = 'Error',
}

enum LottieStage {
  Intro = 'Intro',
  Loop = 'Loop',
  Success = 'Success',
  Error = 'Error',
}

function generateId(prefix: string, stage: LottieStage) {
  return `${prefix}-${stage}`;
}

export default function BigLoading({
  animationState,
  sx,
  onAnimationComplete,
}: {
  animationState?: AnimationState;
  onAnimationComplete?: () => void;
} & Omit<LottieContainerProps, 'children'>) {
  return (
    <LottieContainer sx={sx}>
      <BigLoadingInner
        animationState={animationState}
        onAnimationComplete={onAnimationComplete}
      />
    </LottieContainer>
  );
}

function BigLoadingInner({
  animationState,
  onAnimationComplete,
}: {
  animationState?: AnimationState;
  onAnimationComplete?: () => void;
}) {
  const lottieIntroRef = useRef<LottieAnimation | null>(null);
  const lottieLoopRef = useRef<LottieAnimation | null>(null);
  const lottieFinalRef = useRef<LottieAnimation | null>(null);

  const idPrefix = useId();

  // Storing the animationState in a ref allows us to mutate state outside the render cycle
  // and create functions that will always get the right versions of the animation state
  const animationStateRef = useRef(animationState);
  animationStateRef.current = animationState;

  invariant(
    !!window.lottie,
    'Lottie must be loaded. Did you forget to wrap your component in a <LottieContainer />?'
  );

  const [lottieStage, setLottieStage] = useState<LottieStage>(
    LottieStage.Intro
  );

  const onLoopComplete = useCallback(() => {
    if (
      animationStateRef.current === AnimationState.Error &&
      lottieStage !== LottieStage.Error
    ) {
      setLottieStage(LottieStage.Error);

      lottieFinalRef.current = window.lottie.loadAnimation({
        container: document.getElementById(
          generateId(idPrefix, LottieStage.Error)
        ) as HTMLElement,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        animationData: fail,
      });

      if (onAnimationComplete) {
        lottieFinalRef.current.addEventListener(
          'complete',
          onAnimationComplete
        );
      }

      lottieLoopRef.current?.destroy();
    }
    if (
      animationStateRef.current === AnimationState.Complete &&
      lottieStage !== LottieStage.Success
    ) {
      setLottieStage(LottieStage.Success);

      lottieFinalRef.current = window.lottie.loadAnimation({
        container: document.getElementById(
          generateId(idPrefix, LottieStage.Success)
        ) as HTMLElement,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        animationData: success,
      });

      if (onAnimationComplete) {
        lottieFinalRef.current.addEventListener(
          'complete',
          onAnimationComplete
        );
      }

      lottieLoopRef.current?.destroy();
    }
  }, [idPrefix, lottieStage, onAnimationComplete]);

  const onIntroComplete = useCallback(() => {
    if (lottieStage === LottieStage.Intro && !lottieLoopRef.current) {
      setLottieStage(LottieStage.Loop);

      lottieLoopRef.current = window.lottie.loadAnimation({
        container: document.getElementById(
          generateId(idPrefix, LottieStage.Loop)
        ) as HTMLElement,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: loop,
      });

      lottieLoopRef.current?.addEventListener('loopComplete', onLoopComplete);
      lottieIntroRef.current?.destroy();
    }
  }, [idPrefix, lottieStage, onLoopComplete]);

  useEffect(() => {
    if (lottieIntroRef.current) {
      return;
    }

    lottieIntroRef.current = window.lottie.loadAnimation({
      container: document.getElementById(
        generateId(idPrefix, LottieStage.Intro)
      ) as HTMLElement,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      animationData: intro,
    });

    lottieIntroRef.current?.addEventListener('complete', onIntroComplete);
  }, [idPrefix, onIntroComplete]);

  // destroy all animations on unmount
  useEffect(() => {
    return function cleanup() {
      lottieIntroRef.current?.destroy();
      lottieLoopRef.current?.destroy();
      lottieFinalRef.current?.destroy();
    };
  }, []);

  return (
    <div
      style={{ position: 'relative', width: '100%', height: '100%' }}
      data-testid={TestIds.LoadingStateBigLoading}
    >
      {Object.values(LottieStage).map((stage) => (
        <div
          key={stage}
          id={generateId(idPrefix, stage)}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: lottieStage === stage ? 'block' : 'none',
          }}
        />
      ))}
    </div>
  );
}
