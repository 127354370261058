import { Box, Stack, Typography } from '@mui/material';
import { Trans } from '@lingui/react/macro';
import Button from '../../Button';
import { DropzoneState } from 'react-dropzone';
import fileUploadIcon from '../../../svg/FileUploadIcon.svg';
import fileDragOverAcceptIcon from '../../../svg/FileDragOverAcceptIcon.svg';
import fileDragOverFailIcon from '../../../svg/FileDragOverFailIcon.svg';
import { TestIds } from '@watershed/shared-universal/utils/testUtils';
import { FileType } from '../../../utils/FileUpload';
import UploadIcon from '@watershed/icons/components/Upload';
import { DropAreaLoading } from './DropAreaLoading';
import Image from 'next/image';
import useLocale from '@watershed/intl/frontend/useLocale';

/**
 * Internal component to `FileUploadDropzone` that exposes the container that takes the props from `useDropzone` and applies them,
 * with a file input and content to show when the dropzone is not loading.
 */
export function DropAreaContainer({
  dropzoneState,
  isCompact,
  dropzoneDisabled,
  inProgress,
  progressMessage,
  acceptedFileTypes,
  additionalUsageText,
}: {
  dropzoneState: Pick<
    DropzoneState,
    'getInputProps' | 'getRootProps' | 'isDragAccept' | 'isDragReject'
  >;
  isCompact: boolean;
  dropzoneDisabled: boolean;
  inProgress: boolean;
  progressMessage: LocalizedString;
  acceptedFileTypes: Array<FileType>;
  additionalUsageText?: LocalizedString;
}) {
  const { getRootProps, getInputProps, isDragAccept, isDragReject } =
    dropzoneState;
  return (
    <Box
      {...getRootProps()}
      sx={[
        (theme) => ({
          cursor: 'pointer',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          padding: `24px 64px`,
          textAlign: 'center',
          position: 'relative',
          overflow: 'hidden',
          backgroundColor: isCompact ? null : theme.palette.white,
          borderRadius: 1,
          border: `1px dashed ${theme.palette.grey30}`,
          transition: theme.transitions.create([
            'background-color',
            'border-color',
          ]),
          '&:hover, &:focus': {
            backgroundColor: isCompact ? null : theme.palette.grey10,
          },
        }),
        isDragAccept &&
          !isCompact &&
          ((theme) => ({
            backgroundColor: theme.palette.cobalt10,
            border: `1px solid ${theme.palette.cobalt}`,
            '&:hover, &:focus': {
              backgroundColor: theme.palette.cobalt10,
            },
          })),
        isDragReject &&
          !isCompact &&
          ((theme) => ({
            backgroundColor: theme.palette.marigold05,
            borderColor: theme.palette.marigold20,
            '&:hover, &:focus': {
              backgroundColor: theme.palette.marigold05,
            },
          })),
        dropzoneDisabled && { pointerEvents: 'none' },
        inProgress &&
          ((theme) => ({
            backgroundColor: isCompact ? null : theme.palette.panel,
            '&:hover, &:focus': {
              backgroundColor: isCompact ? null : theme.palette.panel,
            },
          })),
        dropzoneDisabled &&
          !inProgress && {
            // Disabled and not loading is visually grey
            filter: 'saturate(0) opacity(0.75)',
          },
        isCompact && {
          height: 'auto',
          padding: '8px',
          border: 'none',
        },
      ]}
    >
      <DropAreaLoading
        isCompact={isCompact}
        progressMessage={progressMessage}
        inProgress={inProgress}
      />
      <>
        <input
          data-testid={TestIds.FileUploadInput}
          {...getInputProps({ disabled: dropzoneDisabled })}
        />
        {!isCompact && (
          <DropAreaContent
            isDragAccept={isDragAccept}
            isDragReject={isDragReject}
            acceptedFileTypes={acceptedFileTypes}
            additionalUsageText={additionalUsageText}
          />
        )}
        {isCompact && (
          <Box marginBottom={1}>
            <CompactDropAreaContent />
          </Box>
        )}
      </>
    </Box>
  );
}

/**
 * The elements that are shown in the dropzone when it's not loading, and
 * not compact. Doesn't include the actual input element for the dropzone itself.
 */
function DropAreaContent({
  additionalUsageText,
  isDragAccept,
  isDragReject,
  acceptedFileTypes,
}: {
  additionalUsageText?: LocalizedString;
  isDragAccept: boolean;
  isDragReject: boolean;
  acceptedFileTypes: Array<FileType>;
}) {
  const locale = useLocale();
  const imageSrc = (() => {
    if (isDragAccept) {
      return fileDragOverAcceptIcon.src;
    }
    if (isDragReject) {
      return fileDragOverFailIcon.src;
    }
    return fileUploadIcon.src;
  })();

  const title = (() => {
    if (isDragAccept) {
      return (
        <Trans context="Label for the file upload drop">Drop to import</Trans>
      );
    }
    if (isDragReject) {
      return <Trans>File type not supported</Trans>;
    }
    return (
      <>
        <Trans>
          Drag files here or{' '}
          {/* No onclick, since the underlying dropzone click handles this */}
          <Button
            variant="text"
            sx={{
              // Some resets to make the button look more like an inline link
              padding: 0,
              minWidth: 0,
              height: 'auto',
              color: (theme) => theme.palette.cobalt,
              '&:hover, &:focus': {
                color: (theme) => theme.palette.cobalt70,
                background: 'none',
              },
            }}
          >
            browse
          </Button>
        </Trans>
      </>
    );
  })();

  const listFormatter = new Intl.ListFormat(locale, {
    style: 'short',
    type: 'conjunction',
  });
  const listOfAcceptedFileTypes = listFormatter.format(
    acceptedFileTypes.map((ft) => ft.valueOf())
  );

  return (
    <Stack gap={1} alignItems="center">
      <Image src={imageSrc} alt="File icon" height={40} width={40} />
      <Stack alignItems="center" width="100%">
        <Typography
          variant="body1"
          sx={{
            display: 'flex',
            gap: 0.5,
          }}
        >
          {title}
        </Typography>
        {additionalUsageText && (
          <Typography variant="body3">{additionalUsageText}</Typography>
        )}
        <Typography variant="body3">
          <Trans>{listOfAcceptedFileTypes} accepted</Trans>
        </Typography>
      </Stack>
    </Stack>
  );
}

/**
 * In compact mode, we show just an "import" button instead of a full dropzone. Don't need a click
 * handler since it'll bubble up to the drop zone itself to pick a file.
 */
function CompactDropAreaContent() {
  return (
    <Button
      data-testid={TestIds.FileUploadMultipleImport}
      startIcon={<UploadIcon size={24} />}
      size="small"
    >
      <Trans context="Button text">Import</Trans>
    </Button>
  );
}
