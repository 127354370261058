import { pipe, tap } from 'wonka';
import { Exchange } from 'urql';

export type HeaderGetter = () => string | null;

/**
 * A registry of header names and their corresponding getter functions.
 * If the getter returns a non-null value, the header will be added to the request.
 * Otherwise, it will be skipped and not added.
 *
 */
export type HeaderRegistry = Record<string, HeaderGetter>;
export type AddRequestHeadersExchange = (registry: HeaderRegistry) => Exchange;

/**
 * This exchange adds the specified headers to all GraphQL requests. The
 * reason why this isn't just part of the fetchOptions is because it turns out
 * that if a query is refetched due to staleness, the fetchOptions are not
 * recalculated, so the Accept-Language header would not be updated.
 *
 * So, for localization, if we want to support changing the language on the fly,
 *  we need another exchange to inject the accept-language header correctly.
 */
const addRequestHeadersExchange: AddRequestHeadersExchange =
  (registry): Exchange =>
  ({ forward }) => {
    return (ops$) =>
      pipe(
        ops$,
        tap((op) => {
          if (typeof op.context.fetchOptions === 'function') {
            op.context.fetchOptions = op.context.fetchOptions();
          }

          const pairs: Array<[string, string]> = [];
          for (const [key, getter] of Object.entries(registry)) {
            const value = getter();
            if (value) {
              pairs.push([key, value]);
            }
          }

          op.context.fetchOptions = {
            ...(op.context.fetchOptions || {}),
            headers: {
              ...(op.context.fetchOptions?.headers || {}),
              ...Object.fromEntries(pairs),
            },
          };
        }),
        forward
      );
  };

export default addRequestHeadersExchange;
