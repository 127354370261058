import { SnackbarMessage, VariantType } from 'notistack';
import type { SnackbarType } from '@watershed/shared-frontend/hooks/useSnackbar';
import { Stack } from '@mui/material';
import CircularProgress from '@watershed/ui-core/components/CircularProgress';
import { OperationResult } from 'urql';

type MutationSnackbarOptions<Data> = {
  errorMessage?: SnackbarMessage | ((error: Error) => SnackbarMessage);
  successMessage?: SnackbarMessage | ((data: Data) => SnackbarMessage);
  /**
   * Optional callback which is run immediately iff the mutation returns data
   * successfully. Mainly for lightweight post-success actions, like setting
   * state or redirecting. The benefit of this is this function encapsulates
   * the logic to determine whether the `data` is there, while the downside is
   * that it's not snackbar-related.
   *
   * It would be better if we fixed our urql types to require `data` if there
   * is no `error`! But this is convenient for now.
   */
  onSuccess?: (data: Data) => any;
  onError?: (error: Error) => any;
  // override snackbar's styling
  successVariant?: VariantType;
  errorVariant?: VariantType;
};

// Ideally this could be a hook itself and would define its own snackbar. After
// upgrading notistack to >2.0, useSnackbar() is returning null when called
// within a hook, so we've changed away from that to fix the bugs until we can
// figure out why that's happening.
export default async function enqueueMutationSnackbar<Data>(
  snackbar: SnackbarType,
  result: OperationResult<Data>,
  {
    errorMessage,
    successMessage,
    onSuccess,
    onError,
    successVariant = 'success',
    errorVariant = 'error',
  }: MutationSnackbarOptions<Data> = {}
): Promise<void> {
  const IS_ADMIN_APP =
    process.env.NEXT_PUBLIC_ADMIN_HOST === window.location.origin;

  if (result.error || !result.data) {
    const error =
      result.error || new Error('No error returned, but no data found.');

    console.error(error);

    const userErrorInputMessage =
      result.error?.graphQLErrors?.[0]?.extensions.code === 'BAD_USER_INPUT'
        ? result.error.graphQLErrors[0].message
        : undefined;

    snackbar.enqueueSnackbar(
      errorMessage
        ? typeof errorMessage === 'function'
          ? errorMessage(error)
          : errorMessage
        : // If we're in the admin app, then it's okay to default to showing the
          // raw error message from the API.  If not, then be conservative and
          // show the generic error message.
          IS_ADMIN_APP
          ? error.message
          : // If this is specifically a `UserInputError` / `BAD_USER_INPUT`, then
            // we explicitly do want to show that error message to the user.
            (userErrorInputMessage ??
            'Something went wrong. Please try again, and if the issue continues, contact Watershed.'),
      { variant: errorVariant }
    );
    await onError?.(error);
  } else {
    if (successMessage) {
      snackbar.enqueueSnackbar(
        typeof successMessage === 'function'
          ? successMessage(result.data)
          : successMessage,
        { variant: successVariant }
      );
    }

    if (onSuccess) {
      await onSuccess(result.data);
    }
  }
}

/**
 * A variant of enqueue mutation snackbar that shows a loading message while the
 * mutation is in flight. This is useful for mutations that take a long time.
 */
export async function enqueueMutationSnackbarWithLoadingMessage<Data>(
  snackbar: SnackbarType,
  resultPromise: Promise<OperationResult<Data>>,
  options: MutationSnackbarOptions<Data> & {
    loadingMessage: SnackbarMessage;
  }
): Promise<void> {
  const snackbarKey = snackbar.enqueueSnackbar(
    <Stack direction="row" alignItems="center">
      {options.loadingMessage}
      <CircularProgress size={20} color="inherit" sx={{ ml: 2 }} />
    </Stack>,
    { variant: 'default', autoHideDuration: null }
  );
  const result = await resultPromise;
  snackbar.closeSnackbar(snackbarKey);
  return enqueueMutationSnackbar(snackbar, result, options);
}
