import { Stack } from '@mui/material';
import DeleteIcon from '@watershed/icons/components/Delete';
import React from 'react';
import IconButton from '../../IconButton';
import { RejectedFile } from '../../../utils/FileUpload';
import { FileItemRow } from './FileItemRow';

/**
 * Internal component used in `FileUploadDropzone`, allowing a user to delete a failed file item
 * and possibly add other custom actions along with it.
 */
export function RejectedFileItem({
  disabled = false,
  rejectedFile,
  remove,
  customRejectedAction,
}: {
  disabled?: boolean;
  rejectedFile: RejectedFile;
  remove: (name: string) => void;
  customRejectedAction?: (rejectedFile: RejectedFile) => React.ReactNode;
}) {
  const deleteAction = (
    <IconButton
      onClick={() => remove(rejectedFile.name)}
      color="primary"
      size="large"
      disabled={disabled}
      sx={(theme) => ({
        '&:hover, &:focus': {
          color: theme.palette.text.primary,
        },
        '&:hover': {
          backgroundColor: theme.palette.background.default,
        },
      })}
    >
      <DeleteIcon size={20} />
    </IconButton>
  );
  const action = customRejectedAction ? (
    <Stack direction="row" gap={1}>
      {customRejectedAction(rejectedFile)}
      {deleteAction}
    </Stack>
  ) : (
    deleteAction
  );
  return (
    <FileItemRow
      icon="error"
      title={rejectedFile.name}
      subtitle={rejectedFile.message}
      action={action}
    />
  );
}
