import { forwardRef } from 'react';
import Icon, { IconProps } from '../Icon';
const RainbowIcon = forwardRef<SVGSVGElement, IconProps>((props, ref) => (
  <Icon ref={ref} viewBox="0 0 24 24" fill="currentColor" {...props}>
    <path d="M12 8a9 9 0 0 0-9 9 1 1 0 1 1-2 0C1 10.925 5.925 6 12 6s11 4.925 11 11a1 1 0 1 1-2 0 9 9 0 0 0-9-9Z" />
    <path d="M12 12a5 5 0 0 0-5 5 1 1 0 1 1-2 0 7 7 0 1 1 14 0 1 1 0 1 1-2 0 5 5 0 0 0-5-5Z" />
    <path d="M11 17a1 1 0 1 1 2 0 1 1 0 1 0 2 0 3 3 0 1 0-6 0 1 1 0 1 0 2 0Z" />
  </Icon>
));
export default RainbowIcon;
