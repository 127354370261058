import { Trans, useLingui } from '@lingui/react/macro';

import DialogForm from '@watershed/ui-core/components/DialogForm';
import { routeForSupportCase } from '@watershed/shared-universal/dashboardRoutes';
import {
  GQCreateSupportCaseInput,
  GQSupportCasePriority,
  GQSupportCaseIssueType,
} from '@watershed/shared-universal/generated/graphql';
import TextField from '@watershed/ui-core/components/Form/TextField';
import SelectField from '@watershed/ui-core/components/Form/SelectField';
import SupportCasePriorityIcon from './SupportCasePriorityIcon';
import DocumentIcon from '@watershed/icons/components/Document';
import CloseIcon from '@watershed/icons/components/Close';
import Button from '@watershed/ui-core/components/Button';
import Box from '@mui/material/Box';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import ErrorIcon from '@watershed/icons/components/Error';
import Callout from '@watershed/shared-frontend/components/Callout';
import {
  MentionedUser,
  TextFieldMultilineForMentions,
} from '@watershed/shared-frontend/components/Mentions';
import {
  SalesforceCaseIssueType,
  getIssueTypeLabel,
  getPriorityDescription,
  getPriorityLabel,
} from '@watershed/shared-universal/entFound/supportUtils';
import { TestIds } from '@watershed/shared-universal/utils/testUtils';
import { UploadFile } from '@watershed/ui-core/components/FileUpload/FileUploadDropzone';
import SupportCaseAttachmentDialog from './SupportCaseAttachmentDialog';
import useManageSupportCaseAttachments from './useManageSupportCaseAttachments';
import Well from '@watershed/ui-core/components/Well';
import IconButton from '@watershed/ui-core/components/IconButton';
import AttachmentIcon from '@watershed/icons/components/Attachment';
import { CODE_FONT_FAMILY } from '@watershed/style/styleUtils';
import { createDialogHook } from '@watershed/ui-core/hooks/useDialog';
import { Formik } from 'formik';
import gql from 'graphql-tag';
import { useRouter } from 'next/router';
import { useState } from 'react';

gql`
  mutation CreateSupportCase(
    $input: CreateSupportCaseInput!
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    createSupportCase(input: $input) {
      supportCase {
        ...SupportCaseForSupportCasePage
      }
    }
  }
`;

const MAX_SUPPORT_CASE_DESCRIPTION_LENGTH = 32000;

export type SupportCaseDialogProps = {
  onClose: () => void;
  issueType: GQSupportCaseIssueType;
  objectId?: string | null;
};

export default function NewSupportCaseDialog({
  onClose,
  objectId,
  issueType,
}: SupportCaseDialogProps) {
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [showAttachmentDialog, setShowAttachmentDialog] = useState(false);
  const [files, setFiles] = useState<Array<UploadFile>>([]);
  const {
    executeSubmit,
    isSubmitting,
    removeUpload,
    removeAllUploads,
    getDownloadFileUrl,
  } = useManageSupportCaseAttachments({ files, setFiles });
  const router = useRouter();
  const theme = useTheme();
  const { t } = useLingui();
  const [selectedPriority, setSelectedPriority] =
    useState<GQSupportCasePriority | null>(null);
  const [mentionedUsers, setMentionedUsers] = useState<Array<MentionedUser>>(
    []
  );

  return (
    <>
      <Formik<GQCreateSupportCaseInput>
        initialValues={{
          subject: '',
          description: '',
          priority: GQSupportCasePriority.Low,
          issueType,
          objectId,
          mentionedUserIds: [],
        }}
        onSubmit={async (input) => {
          if (errorMsg) {
            setErrorMsg(null);
          }
          const result = await executeSubmit({
            input: {
              ...input,
              mentionedUserIds: mentionedUsers.map((user) => user.id),
              attachments: files.map((file) => {
                return {
                  fileMetadataId: file.fileId,
                  fileName: file.filename,
                };
              }),
            },
            after: null,
            before: null,
            first: 100,
            last: null,
          });
          if (result.data?.createSupportCase?.supportCase) {
            void router.push(
              routeForSupportCase(
                result.data.createSupportCase.supportCase.id,
                {
                  conf: true,
                }
              )
            );
          } else {
            if (result.error) {
              setErrorMsg(
                'There was an error submitting your support case. Watershed has been notified.'
              );
            }
          }
          onClose();
        }}
      >
        {(form) => (
          <DialogForm
            onClose={() => {
              onClose();
              // Give yourself a breath for the animation
              setTimeout(() => {
                form.resetForm();
                setErrorMsg(null);
              }, 300);
            }}
            maxWidth="sm"
            header={{
              title: (
                <Trans context="form header copy for creating support cases">
                  Help
                </Trans>
              ),
            }}
            sx={{
              // Dialogs play me close like butter plays toast
              '& .MuiDialogContent-root': { rowGap: 2 },
            }}
            isSubmitting={isSubmitting}
            submit={
              <Button
                color="primary"
                type="submit"
                disabled={
                  isSubmitting ||
                  !form.values.subject ||
                  form.values.subject === '' ||
                  !form.values.description ||
                  form.values.description === '' ||
                  !form.values.issueType
                }
                data-testid={TestIds.SupportCaseSubmitButton}
              >
                <Trans context="Button copy to submit a support case">
                  Submit
                </Trans>
              </Button>
            }
          >
            <Stack
              sx={{
                borderBottom: `1px dashed ${theme.palette.divider}`,
                pb: 2,
              }}
            >
              <Typography variant="h4">
                <Trans context="Section header for a support case submission form">
                  What seems to be the issue?
                </Trans>
              </Typography>
              <Typography variant="body2">
                <Trans>
                  If you've found a bug or something isn't working as expected,
                  please tell us the steps you took so we can reproduce the
                  issue and help fix it.
                </Trans>
              </Typography>
            </Stack>

            {errorMsg && (
              <Callout
                title={
                  <Trans>
                    There was an error submitting your support case.
                  </Trans>
                }
                variant="error"
                IconComponent={ErrorIcon}
                onDismiss={() => setErrorMsg(null)}
                description={errorMsg}
              />
            )}

            <TextField
              id="subject"
              required
              sx={{ gap: 1 }}
              label={
                <Trans context="Form label for support case subject">
                  Subject
                </Trans>
              }
              data-testid={TestIds.SupportCaseSubjectInput}
            />
            <SelectField
              id="issueType"
              required
              sx={{ gap: 1 }}
              label={
                <Trans context="Form label for support case feature area">
                  Product area
                </Trans>
              }
              data-testid={TestIds.SupportCaseProductAreaSelect}
            >
              {(
                Object.keys(SalesforceCaseIssueType) as Array<
                  keyof typeof SalesforceCaseIssueType
                >
              ).map((value) => (
                <MenuItem key={value} value={value}>
                  {getIssueTypeLabel(value)}
                </MenuItem>
              ))}
            </SelectField>
            <SelectField
              id="priority"
              required
              sx={{
                gap: 1,
                '& .MuiSelect-select': {
                  display: 'flex',
                  alignItems: 'center',
                  maxHeight: 24,
                },
                '&& .MuiListItemIcon-root': {
                  minWidth: 'fit-content',
                  pr: 1,
                },
              }}
              label={
                <Trans context="Form label for support case priority">
                  Priority
                </Trans>
              }
              data-testid={TestIds.SupportCasePrioritySelect}
              renderValue={(value) =>
                getPriorityLabel(value as GQSupportCasePriority)
              }
            >
              <MenuItem
                value={GQSupportCasePriority.Low}
                sx={{
                  height: 40,
                  gap: 1,
                  '&& .MuiListItemIcon-root': { minWidth: 'fit-content' },
                }}
                onMouseEnter={() =>
                  setSelectedPriority(GQSupportCasePriority.Low)
                }
                onMouseLeave={() => setSelectedPriority(null)}
                onFocus={() => setSelectedPriority(GQSupportCasePriority.Low)}
                onBlur={() => setSelectedPriority(null)}
              >
                <ListItemIcon>
                  <SupportCasePriorityIcon
                    priority={GQSupportCasePriority.Low}
                  />
                </ListItemIcon>
                <ListItemText>
                  {getPriorityLabel(GQSupportCasePriority.Low)}
                </ListItemText>
              </MenuItem>

              <MenuItem
                value={GQSupportCasePriority.Medium}
                sx={{
                  height: 40,
                  gap: 1,
                  '&& .MuiListItemIcon-root': { minWidth: 'fit-content' },
                }}
                onMouseEnter={() =>
                  setSelectedPriority(GQSupportCasePriority.Medium)
                }
                onMouseLeave={() => setSelectedPriority(null)}
                onFocus={() =>
                  setSelectedPriority(GQSupportCasePriority.Medium)
                }
                onBlur={() => setSelectedPriority(null)}
              >
                <ListItemIcon>
                  <SupportCasePriorityIcon
                    priority={GQSupportCasePriority.Medium}
                  />
                </ListItemIcon>
                <ListItemText>
                  {getPriorityLabel(GQSupportCasePriority.Medium)}
                </ListItemText>
              </MenuItem>
              <MenuItem
                value={GQSupportCasePriority.High}
                sx={{
                  height: 40,
                  gap: 1,
                  '&& .MuiListItemIcon-root': { minWidth: 'fit-content' },
                }}
                onMouseEnter={() =>
                  setSelectedPriority(GQSupportCasePriority.High)
                }
                onMouseLeave={() => setSelectedPriority(null)}
                onFocus={() => setSelectedPriority(GQSupportCasePriority.High)}
                onBlur={() => setSelectedPriority(null)}
              >
                <ListItemIcon>
                  <SupportCasePriorityIcon
                    priority={GQSupportCasePriority.High}
                  />
                </ListItemIcon>
                <ListItemText>
                  {getPriorityLabel(GQSupportCasePriority.High)}
                </ListItemText>
              </MenuItem>
              <Stack
                sx={{
                  height: '44px',
                  position: 'relative',
                  alignItems: 'center',
                  justifyContent: 'center',
                  bgcolor: 'grey05',
                  borderTop: `1px solid ${theme.palette.divider}`,
                }}
                role="status" // Indicates this is a status that may update
                aria-live="polite" // Announces changes politely
                aria-atomic="true" // Reads the entire content when it changes
              >
                {[
                  { priority: null, text: getPriorityDescription(null) },
                  {
                    priority: GQSupportCasePriority.High,
                    text: getPriorityDescription(GQSupportCasePriority.High),
                  },
                  {
                    priority: GQSupportCasePriority.Medium,
                    text: getPriorityDescription(GQSupportCasePriority.Medium),
                  },
                  {
                    priority: GQSupportCasePriority.Low,
                    text: getPriorityDescription(GQSupportCasePriority.Low),
                  },
                ].map(({ priority, text }) => (
                  <Typography
                    key={priority ?? 'default'}
                    sx={{
                      fontFamily: CODE_FONT_FAMILY,
                      fontSize: 12,
                      textAlign: 'center',
                      textWrap: 'pretty',
                      transition: 'opacity 0.15s ease-out',
                      opacity: selectedPriority === priority ? 1 : 0,
                      position: 'absolute',
                      top: '0',
                      left: 0,
                      right: 0,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%',
                      // Hide from screen readers when not visible
                      visibility:
                        selectedPriority === priority ? 'visible' : 'hidden',
                      // Prevent interaction when not visible
                      pointerEvents:
                        selectedPriority === priority ? 'auto' : 'none',
                    }}
                    variant="body3"
                    color="text.secondary"
                    aria-hidden={selectedPriority !== priority} // Hide from screen readers when not visible
                  >
                    {text}
                  </Typography>
                ))}
                {/* Add a visually hidden element that announces the current selection */}
                <Box component="span" sx={{ display: 'none' }} role="status">
                  {selectedPriority
                    ? `Selected priority: ${selectedPriority}. ${getPriorityDescription(
                        selectedPriority
                      )}`
                    : getPriorityDescription(null)}
                </Box>
              </Stack>
            </SelectField>
            <TextFieldMultilineForMentions
              required
              id="description"
              label={
                <Trans context="Form label for support case description">
                  Message
                </Trans>
              }
              rows={2}
              message={form.values.description ?? ''}
              setMessageText={(value) =>
                form.setFieldValue('description', value)
              }
              placeholder={t({
                message: 'Add more details here… Use @ to mention users',
                context: 'Placeholder for support case description',
              })}
              style={{
                gap: 1,
                minHeight: 96,
                maxHeight: 256,
                overflowY: 'scroll',
                paddingTop: '8px',
              }}
              maxLength={MAX_SUPPORT_CASE_DESCRIPTION_LENGTH}
              data-testid={TestIds.SupportCaseDescriptionInput}
              onAddMentionedUser={(user: MentionedUser) => {
                setMentionedUsers((prev) => [...prev, user]);
              }}
            />
            <Stack direction="column" gap={0.5}>
              <div>
                <Button
                  color="secondary"
                  onClick={() => setShowAttachmentDialog(true)}
                  data-test={TestIds.SupportAttachmentButton}
                  startIcon={<AttachmentIcon />}
                >
                  <Trans context="Button copy to include an attached file in a support case creation dialog">
                    Include attachment
                  </Trans>
                </Button>
              </div>
              {files.map((file) => (
                <Well key={file.fileId}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        gap: 0.5,
                        alignItems: 'center',
                      }}
                    >
                      <DocumentIcon
                        size={14}
                        color={(theme) => theme.palette.grey50}
                        sx={{ flexShrink: 0 }}
                      />
                      <Typography>{file.filename}</Typography>
                    </Box>
                    <IconButton
                      onClick={async () => {
                        await removeUpload(file.fileId);
                      }}
                    >
                      <CloseIcon color={(theme) => theme.palette.grey50} />
                    </IconButton>
                  </Stack>
                </Well>
              ))}
            </Stack>
          </DialogForm>
        )}
      </Formik>
      <SupportCaseAttachmentDialog
        open={showAttachmentDialog}
        onClose={async () => {
          setShowAttachmentDialog(false);
          await removeAllUploads();
        }}
        onSubmit={(files: Array<UploadFile>) => {
          setFiles(files);
          setShowAttachmentDialog(false);
        }}
        removeUpload={removeUpload}
        files={files}
        getDownloadFileUrl={getDownloadFileUrl}
      />
    </>
  );
}

export const useSupportCaseDialog =
  createDialogHook<SupportCaseDialogProps>(NewSupportCaseDialog);
