// from https://gist.github.com/zenazn/d4fae0f502622f08363a3662e881bbcc
export const DEFAULT_TARGET_MS = 2000;

/**
 * Use YieldController in for loops to periodically yield to
 * the event loop when blocked time hits a threshold (2000ms by default).
 *
 * e.g
 * const yc = new YieldController();
 * for (a in arr) {
 *   if (yc.shouldYield()){
 *     await yc.yield();
 *   }
 * }
 */
export class YieldController {
  private lastYield: number;
  constructor(private targetMs: number = DEFAULT_TARGET_MS) {
    this.lastYield = Date.now();
  }
  // Yields if the event loop has been blocked for more than 2 seconds.
  shouldYield(): boolean {
    return Date.now() - this.lastYield > this.targetMs;
  }
  yield(): Promise<void> {
    this.lastYield = Date.now();
    return new Promise((resolve) => {
      // Note: another option here is setImmediate (only available in nodejs,
      // not in browser) setImmediate would resume execution earlier than
      // setTimeout(0) in some cases.
      setTimeout(resolve, 0);
    });
  }
}
