import { GQBiEmptyStringType, GQFlags } from '../generated/graphql';
import { msg } from '@lingui/core/macro';
import {
  BUSINESS_CATEGORY_LABELS,
  GHG_CATEGORY_LABELS,
} from '../utils/BusinessCategory';
import {
  BiBooleanFieldMeta,
  BiDateFieldMeta,
  BiDimensionFieldMeta,
  BiFieldType,
  BiMeasureFieldMeta,
  BiNumberFieldMeta,
  BiStringFieldMeta,
  BiYearMonthFieldMeta,
  FieldMetaTypeConditioningProperties,
} from './types';
import IntensityDenominators, {
  IntensityDenominatorKinds,
} from '../utils/intensityDenominators';
import { UNIT_LABELS } from '../utils/unitLabels';
import { BART_LEASE_TYPE_LABELS } from '../utils/buildingLeaseTypeLabels';
import { z } from 'zod';
import {
  AGE_GROUP_LABELS,
  CAS_NUMBER_LABELS,
  EMPLOYEE_GROUP_TYPE_LABELS,
  EMPLOYMENT_TYPE_LABELS,
  GENDER_LABELS,
  HEALTH_AND_SAFETY_INCIDENT_EMPLOYEE_TYPE_LABELS,
  INCIDENT_TYPE_LABEL_MAPPING,
  LOBBYING_CONTRIBUTION_KIND_LABEL_MAPPING,
  LOBBYING_EXPENSE_KIND_LABEL_MAPPING,
  MICROPLASTICS_OUTFLOW_MEDIUM_LABELS,
  NON_EMPLOYEE_TYPE_LABELS,
  OWN_WORKFORCE_TYPE_LABELS,
  POLLUTANT_GROUP_LABELS,
  SUBSTANCE_AND_MICROPLASTICS_INFLOW_MEDIUM_LABELS,
  SUBSTANCE_OF_CONCERN_LABELS,
  SUBSTANCE_OUTFLOW_MEDIUM_LABELS,
  COUNTRY_LABEL_OBJECTS,
  WASTE_TREATMENT_LABELS,
  GHG_REMOVAL_UNDERTAKING_SCOPE_LABELS,
} from '../canonicalSchemas/constants';
import { MARTA_SUPPORTED_BART_TABLES } from '../marta/constants';
import {
  BatMetadata,
  MetadataDefinedPrimaryTypes,
} from '../batSchemas/BatMetadata';
import {
  TRANSPORTATION_MODES_LABEL_MAPPING,
  DISTANCE_METHOD_LABEL_MAPPING,
  LOGISTICS_POSITION_LABEL_MAPPING,
  FLIGHT_CABIN_CLASSES_LABEL_MAPPING,
  TRIP_KIND_LABEL_MAPPING,
  WASH_METHOD_LABEL_MAPPING,
  DRY_METHOD_LABEL_MAPPING,
} from '../batSchemas/constants';
import { Collapse } from '../types/Collapse';
import must from '../utils/must';
import { I18n, MessageDescriptor } from '@lingui/core';
import { i18n } from '@watershed/intl';
import omit from 'lodash/omit';
import { CLEAN_POWER_CONTRACTUAL_INSTRUMENT_LABELS } from '../constants';
import { FinanceHoldingTypesList } from '../finance/types/FinanceFootprintTypes';

export const BIODIVERSITY_FAMILY = msg({
  message: 'Biodiversity',
  context: 'The grouping for a collection of dimensions',
});
export const BUILDING_FAMILY = msg({
  message: 'Facility',
  context: 'The grouping for a collection of dimensions',
});
export const CLOUD_FAMILY = msg({
  message: 'Cloud',
  context: 'The grouping for a collection of dimensions',
});
export const EMPLOYEE_FAMILY = msg({
  message: 'Employees',
  context:
    'Heading of a section of data describing information about the customer’s employees',
});
export const CATEGORY_FAMILY = msg({
  message: 'Watershed categorization',
  context: 'The grouping for a collection of dimensions',
});
export const SUPPLIER_FAMILY = msg({
  message: 'Suppliers',
  context: 'The grouping for a collection of dimensions',
});
export const LOCATION_FAMILY = msg({
  message: 'Location',
  context: 'The grouping for a collection of dimensions',
});
export const LOGISTICS_FAMILY = msg({
  message: 'Logistics',
  context: 'The grouping for a collection of dimensions',
});
export const GHG_FAMILY = msg({
  message: 'GHG Protocol',
  context: 'The grouping for a collection of dimensions',
});
export const WASTE_FAMILY = msg({
  message: 'Waste',
  context: 'The grouping for a collection of dimensions',
});
// TODO(CSRD): Change this to be about `Substances` instead of `Pollution`. Verify with
// product that we want to do this. Pollution is a subset of substances.
export const POLLUTION_FAMILY = msg({
  message: 'Pollution',
  context: 'The grouping for a collection of dimensions',
});
export const WATER_FAMILY = msg({
  message: 'Water',
  context: 'The grouping for a collection of dimensions',
});
export const FINANCIALS_FAMILY = msg({
  message: 'Financials',
  context: 'The grouping for a collection of dimensions',
});
export const FOOD_FAMILY = msg({
  message: 'Food',
  context: 'The grouping for a collection of dimensions',
});
export const OTHER_FAMILY = msg({
  message: 'Other',
  context: 'The grouping for a collection of dimensions',
});
export const TIME_FAMILY = msg({
  message: 'Time',
  context: 'The grouping for a collection of dimensions',
});
export const ENERGY_FAMILY = msg({
  message: 'Energy',
  context: 'The grouping for a collection of dimensions',
});
export const PRODUCT_CIRCULARITY_FAMILY = msg({
  message: 'Product circularity',
  context: 'The grouping for a collection of dimensions',
});
export const INTERNAL_FAMILY = msg({
  message: 'Internal',
  context: 'The grouping for a collection of dimensions',
});
export const LODGING_FAMILY = msg({
  message: 'Lodging',
  context: 'The grouping for a collection of dimensions',
});
export const CUSTOM_FAMILY = msg({
  message: 'Custom',
  context: 'The grouping for a collection of dimensions',
});
export const ORG_UNITS_FAMILY = msg({
  message: 'Org units',
  context: 'The grouping for a collection of dimensions',
});
export const WORKPLACE_REPORTS_FAMILY = msg({
  message: 'Workplace reports',
  context: 'The grouping for a collection of dimensions',
});
export const LOBBYING_FAMILY = msg({
  message: 'Lobbying activity',
  context: 'The grouping for a collection of dimensions',
});
export const ANTI_BRIBERY_AND_CORRUPTION_FAMILY = msg({
  message: 'Anti-bribery and corruption',
  context: 'The grouping for a collection of dimensions',
});
export const HEALTH_AND_SAFETY_FAMILY = msg({
  message: 'Health and safety',
  context: 'The grouping for a collection of dimensions',
});
export const PAYMENT_ORDER_FAMILY = msg({
  message: 'Payment order',
  context: 'The grouping for a collection of dimensions',
});
export const SEVERE_HUMAN_RIGHTS_INCIDENT_FAMILY = msg({
  message: 'Severe human rights incident',
  context: 'The grouping for a collection of dimensions',
});
export const VEHICLES_FAMILY = msg({
  message: 'Vehicles',
  context: 'The grouping for a collection of dimensions',
});
export const FLIGHTS_FAMILY = msg({
  message: 'Flights',
  context: 'The grouping for a collection of dimensions',
});
export const CARBON_CREDITS_FAMILY = msg({
  message: 'Carbon credits and removals',
  context: 'The grouping for a collection of dimensions',
});
export const REFRIGERANT_FAMILY = msg({
  message: 'Refrigerants',
  context: 'The grouping for a collection of dimensions',
});
export const PRODUCT_FAMILY = msg({
  message: 'Product',
  context: 'The grouping for a collection of dimensions',
});
export const INVESTMENTS_FAMILY = msg({
  message: 'Investments',
  context: 'The grouping for a collection of dimensions',
});
export const WORKFORCE_FAMILY = msg({
  message: 'Workforce social metrics',
  context: 'The grouping for a collection of dimensions',
});
const SCOPE_3_15_FAMILY = msg({
  message: 'Scope 3.15',
  context: 'The grouping for a collection of dimensions',
});
const FUND_FAMILY = msg({
  message: 'Fund attributes',
  context: 'The grouping for a collection of dimensions',
});
const ASSET_PROPERTIES_FAMILY = msg({
  message: 'Asset properties',
  context: 'The grouping for a collection of dimensions',
});
const ANNUAL_DATA_FAMILY = msg({
  message: 'Annual data',
  context: 'The grouping for a collection of dimensions',
});
const HOLDING_PROPERTIES_FAMILY = msg({
  message: 'Holding properties',
  context: 'The grouping for a collection of dimensions',
});
const GHG_REMOVALS_FAMILY = msg({
  message: 'GHG removals',
  context: 'The grouping for a collection of dimensions',
});

const DIMENSION_META_STRING_DEFAULTS = {
  type: BiFieldType.string,
  shouldSentenceCaseValues: true,
  shouldNullifyPseudoNulls: true,
  // all our current usages of dimensinos want this behavior so default to it for dimensions only
  // we can override to Empty string instead if say we display the dimension value in a flat column rather than a tree
  emptyStringType: GQBiEmptyStringType.UnspecifiedWithDisplayName,
  isMultiValue: false,
} satisfies Partial<BiStringFieldMeta>;

const DIMENSION_META_BOOLEAN_DEFAULTS = {
  type: BiFieldType.boolean,
  emptyStringType: GQBiEmptyStringType.UnspecifiedWithDisplayName,
  isMultiValue: false,
} satisfies Partial<BiBooleanFieldMeta>;
const DIMENSION_META_DATE_DEFAULTS = {
  type: BiFieldType.date,
  emptyStringType: GQBiEmptyStringType.UnspecifiedWithDisplayName,
  isMultiValue: false,
} satisfies Partial<BiDateFieldMeta>;
const DIMENSION_META_YEAR_MONTH_DEFAULTS = {
  type: BiFieldType.yearmonth,
  emptyStringType: GQBiEmptyStringType.UnspecifiedWithDisplayName,
  isMultiValue: false,
} satisfies Partial<BiYearMonthFieldMeta>;

const DIMENSION_META_NUMBER_DEFAULTS = {
  type: BiFieldType.number,
  emptyStringType: GQBiEmptyStringType.UnspecifiedWithDisplayName,
  isMultiValue: false,
} satisfies Partial<BiNumberFieldMeta>;

const MEASURE_META_DEFAULTS = {
  fieldFamily: undefined,
  type: BiFieldType.number,
  emptyStringType: GQBiEmptyStringType.Emdash,
  isMultiValue: false as const,
} satisfies Partial<BiMeasureFieldMeta>;

export const DIVIDE_BY = {
  ...Object.fromEntries(
    Object.entries(IntensityDenominators).map(
      ([intensityKind, { scaleFactor }]) => [intensityKind, scaleFactor]
    )
  ),
  antiBriberyAndCorruptionCurrencyQuantity: 1_000_000,
  assetRevenueQuantity: 1_000_000,
  buildingAreaScaledQuantity: 1_000,
  gmvQuantity: 1_000_000,
  grossProfitQuantity: 1_000_000,
  lobbyingCurrencyQuantity: 1_000_000,
  merchantsQuantity: 1_000,
  monthlyActiveUsersQuantity: 1_000_000,
  netRevenueQuantity: 1_000_000,
  nightsStayedQuantity: 1_000_000,
  operationalRevenueQuantity: 1_000_000,
  ordersQuantity: 1_000,
  patientsQuantity: 1_000,
  payingSitesUnderManagementQuantity: 1_000,
  revenueQuantity: 1_000_000,
  severeHumanRightsIncidentCurrencyQuantity: 1_000_000,
  shipmentsQuantity: 1_000,
  supplierSpendQuantity: 1_000_000,
  unitsQuantity: 1_000,
  workersHoursWorkedQuantity: 1_000_000,
};

const INTENSITY_DENOMINATOR_DISPLAY_NAME_MESSAGE_DESCRIPTORS: Record<
  string,
  MessageDescriptor
> = {
  revenue: msg({
    message: 'Revenue',
    context: 'Intensity denominator display name',
  }),
  headcount: msg({
    message: 'Headcount',
    context: 'Intensity denominator display name',
  }),
  nights_stayed: msg({
    message: 'Nights stayed',
    context: 'Intensity denominator display name',
  }),
  gross_profit: msg({
    message: 'Gross profit',
    context: 'Intensity denominator display name',
  }),
  net_revenue: msg({
    message: 'Net revenue',
    context: 'Intensity denominator display name',
  }),
  monthly_active_users: msg({
    message: 'Monthly active users',
    context: 'Intensity denominator display name',
  }),
  orders: msg({
    message: 'Orders',
    context: 'Intensity denominator display name',
  }),
  orders_kg: msg({
    message: 'Orders kg',
    context: 'Intensity denominator display name',
  }),
  merchants: msg({
    message: 'Merchants',
    context: 'Intensity denominator display name',
  }),
  paying_sites_under_management: msg({
    message: 'Paying sites under management',
    context: 'Intensity denominator display name',
  }),
  operational_revenue: msg({
    message: 'Operational revenue',
    context: 'Intensity denominator display name',
  }),
  megawatts: msg({
    message: 'Megawatts',
    context: 'Intensity denominator display name',
  }),
  shipments: msg({
    message: 'Shipments',
    context: 'Intensity denominator display name',
  }),
  gmv: msg({
    message: 'GMV',
    context: 'Intensity denominator display name',
  }),
  units: msg({
    message: 'Units',
    context: 'Intensity denominator display name',
  }),
  supplier_spend: msg({
    message: 'Supplier spend',
    context: 'Intensity denominator display name',
  }),
  patients: msg({
    message: 'Patient',
    context: 'Intensity denominator display name',
  }),
  square_feet: msg({
    message: 'Square footage',
    context: 'Intensity denominator display name',
  }),
};

const legacyIntensities: Record<
  string,
  Omit<BiMeasureFieldMeta, 'fieldId' | 'displayName'>
> = Object.fromEntries(
  IntensityDenominatorKinds.map((intensityKind) => [
    intensityKind,
    measureMeta({
      displayNameMessageDescriptor:
        INTENSITY_DENOMINATOR_DISPLAY_NAME_MESSAGE_DESCRIPTORS[intensityKind],
      divideBy: IntensityDenominators[intensityKind].scaleFactor,
      unitUserFacing: IntensityDenominators[intensityKind].unitUserFacing,
      isCurrency: IntensityDenominators[intensityKind].isCurrency,
    }),
  ])
);

export function getMonetaryIntensityV2ForLegacyIntensityKind(
  intensityKind: string
): string {
  switch (intensityKind) {
    case 'revenue':
      return 'revenueQuantity';
    case 'gross_profit':
      return 'grossProfitQuantity';
    case 'net_revenue':
      return 'netRevenueQuantity';
    case 'operational_revenue':
      return 'operationalRevenueQuantity';
    case 'gmv':
      return 'gmvQuantity';
    case 'supplier_spend':
      return 'supplierSpendQuantity';
    default:
      return intensityKind;
  }
}

export function getCustomIntensityV2ForLegacyIntensityKind(
  intensityKind: string
): string {
  switch (intensityKind) {
    case 'nights_stayed':
      return 'nightsStayedQuantity' as const;
    case 'megawatts':
      return 'megawattsQuantity';
    case 'monthly_active_users':
      return 'monthlyActiveUsersQuantity';
    case 'orders':
      return 'ordersQuantity';
    case 'orders_kg':
      return 'ordersKgQuantity';
    case 'merchants':
      return 'merchantsQuantity';
    case 'patients':
      return 'patientsQuantity';
    case 'paying_sites_under_management':
      return 'payingSitesUnderManagementQuantity';
    case 'shipments':
      return 'shipmentsQuantity';
    case 'units':
      return 'unitsQuantity';
    default:
      return intensityKind;
  }
}

// TODO i18n accept only Record<string, MessageDescriptor>
// when all label mappings are instrumented
const recordToStaticListOptions = (
  scopedI18n: I18n,
  record: Record<string, MessageDescriptor | string>
) =>
  Object.entries(record).map(([value, label]) => ({
    value,
    label: typeof label === 'string' ? label : scopedI18n.t(label),
  }));

const BI_DIMENSION_FIELDS_NO_ID = {
  attributionFactor: dimensionNumberMeta({
    displayNameMessageDescriptor: msg({
      message: 'Attribution factor',
      context:
        'The name of a data column describing the attribution factor of an investment.',
      comment:
        'Attribution factor is a number between 0-1 describing what fraction of another company the customer owns. e.g. if they own 20% of another company, the attribution factor is 0.2',
    }),
    type: BiFieldType.number,
    fieldFamilyMessageDescriptor: INVESTMENTS_FAMILY,
  }),
  assetName: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Asset name',
      context:
        'A data column describing the name of an investment asset, which is typically another company name',
    }),
    type: BiFieldType.string,
    fieldFamilyMessageDescriptor: INVESTMENTS_FAMILY,
  }),
  month: dimensionYearMonthMeta({
    displayNameMessageDescriptor: msg({
      message: 'Month',
      context: 'The display name for a dimension',
    }),
    hiddenInFilters: true,
    fieldFamilyMessageDescriptor: TIME_FAMILY,
    format: {
      month: 'short',
      year: 'numeric',
    },
  }),
  calendarYear: dimensionNumberMeta({
    displayNameMessageDescriptor: msg({
      message: 'Calendar year',
      context: 'The display name for a dimension',
    }),
    hiddenInFilters: true,
    disableCommas: true,
    fieldFamilyMessageDescriptor: TIME_FAMILY,
  }),
  fiscalYear: dimensionNumberMeta({
    displayNameMessageDescriptor: msg({
      message: 'Fiscal year',
      context: 'The display name for a dimension',
    }),
    hiddenInFilters: true,
    disableCommas: true,
    fieldFamilyMessageDescriptor: TIME_FAMILY,
  }),
  productId: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Sold product ID',
      context: 'The display name for a dimension',
    }),
    shouldSentenceCaseValues: false,
    fieldFamilyMessageDescriptor: PRODUCT_FAMILY,
  }),
  product: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Sold product name',
      context: 'The display name for a dimension',
    }),
    shouldSentenceCaseValues: false,
    fieldFamilyMessageDescriptor: PRODUCT_FAMILY,
  }),
  purchasedMaterialId: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Purchased material ID',
      context: 'The display name for a dimension',
    }),
    shouldSentenceCaseValues: false,
    fieldFamilyMessageDescriptor: PRODUCT_FAMILY,
  }),
  purchasedMaterialName: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Purchased material name',
      context: 'The display name for a dimension',
    }),
    shouldSentenceCaseValues: false,
    fieldFamilyMessageDescriptor: PRODUCT_FAMILY,
  }),
  uncollapsedCompanyId: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Supplier - Standardized',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: SUPPLIER_FAMILY,
  }),
  vendor: dimensionStringMeta({
    shouldSentenceCaseValues: false,
    displayNameMessageDescriptor: msg({
      message: 'Supplier',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: SUPPLIER_FAMILY,
  }),
  rawVendor: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Supplier - Imported',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: SUPPLIER_FAMILY,
  }),
  locationCountry: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Country',
      context: 'The display name for a dimension',
    }),
    shouldSentenceCaseValues: false,
    staticListOptionsProvider: (i18n: I18n) => COUNTRY_LABEL_OBJECTS,
    fieldFamilyMessageDescriptor: LOCATION_FAMILY,
  }),
  locationCity: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'City',
      context: 'The display name for a dimension',
    }),
    shouldSentenceCaseValues: false,
    fieldFamilyMessageDescriptor: LOCATION_FAMILY,
  }),
  locationState: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'State',
      context: 'The display name for a dimension',
    }),
    shouldSentenceCaseValues: false,
    fieldFamilyMessageDescriptor: LOCATION_FAMILY,
  }),
  locationGrid: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Electrical grid',
      context: 'The display name for a dimension',
    }),
    shouldSentenceCaseValues: false,
    fieldFamilyMessageDescriptor: LOCATION_FAMILY,
  }),
  locationPostalCode: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Postal code',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: LOCATION_FAMILY,
  }),
  location: dimensionStringMeta({
    displayNameMessageDescriptor: LOCATION_FAMILY,
    shouldSentenceCaseValues: false,
    fieldFamilyMessageDescriptor: LOCATION_FAMILY,
  }),
  categoryId: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Category',
      context: 'The display name for a dimension',
    }),
    staticListOptionsProvider: (i18n: I18n) =>
      recordToStaticListOptions(i18n, BUSINESS_CATEGORY_LABELS),
    fieldFamilyMessageDescriptor: CATEGORY_FAMILY,
  }),
  subcategoryId: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Subcategory',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: CATEGORY_FAMILY,
  }),
  description: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Description',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: CATEGORY_FAMILY,
  }),
  ghgCategoryId: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'GHG category',
      context: 'The display name for a dimension',
    }),
    staticListOptionsProvider: (i18n: I18n) =>
      recordToStaticListOptions(i18n, GHG_CATEGORY_LABELS),
    fieldFamilyMessageDescriptor: GHG_FAMILY,
  }),
  ghgScope: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'GHG scope',
      context: 'The display name for a dimension',
    }),
    shouldSentenceCaseValues: false,
    fieldFamilyMessageDescriptor: GHG_FAMILY,
  }),
  ghgEmissionReason: dimensionStringMeta({
    ...getBatMetadataProperties('direct_ghg_emissions', 'ghg_emission_reason'),
    fieldFamilyMessageDescriptor: GHG_FAMILY,
  }),
  gasName: dimensionStringMeta({
    ...getBatMetadataProperties('direct_ghg_emissions', 'gas_name'),
    fieldFamilyMessageDescriptor: GHG_FAMILY,
  }),
  sourceUnit: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Source unit',
      context: 'The display name for a dimension',
    }),
    shouldSentenceCaseValues: false,
    staticListOptionsProvider: (i18n: I18n) =>
      recordToStaticListOptions(i18n, UNIT_LABELS),
  }),
  inputUnit: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Source unit',
      context: 'The display name for a dimension',
    }),
    shouldSentenceCaseValues: false,
    staticListOptionsProvider: (i18n: I18n) =>
      recordToStaticListOptions(i18n, UNIT_LABELS),
  }),
  isSupplierSpecific: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Is supplier specific',
      context: 'The display name for a dimension',
    }),
  }),
  isCustomerProvidedEmissionsFactor: dimensionBooleanMeta({
    displayNameMessageDescriptor: msg({
      message: 'Is customer-provided emissions factor',
      context: 'The display name for a dimension',
    }),
  }),
  hasKwhConsumed: dimensionBooleanMeta({
    displayNameMessageDescriptor: msg({
      message: 'Has energy consumption',
      context: 'The display name for a dimension',
    }),

    fieldFamilyMessageDescriptor: ENERGY_FAMILY,
  }),
  fuelKind: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Fuel kind',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ENERGY_FAMILY,
  }),
  energyKind: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Energy kind',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ENERGY_FAMILY,
  }),
  isRenewable: dimensionBooleanMeta({
    displayNameMessageDescriptor: msg({
      message: 'Is renewable',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ENERGY_FAMILY,
  }),
  isEmissive: dimensionBooleanMeta({
    displayNameMessageDescriptor: msg({
      message: 'Is emissive',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ENERGY_FAMILY,
  }),
  isOnsite: dimensionBooleanMeta({
    displayNameMessageDescriptor: msg({
      message: 'Is onsite',
      context: 'The display name for a dimension',
    }),

    fieldFamilyMessageDescriptor: ENERGY_FAMILY,
  }),
  isCogeneration: dimensionBooleanMeta({
    displayNameMessageDescriptor: msg({
      message: 'Is co-generation',
      context: 'The display name for a dimension',
    }),

    fieldFamilyMessageDescriptor: ENERGY_FAMILY,
  }),
  isEnergyStar: dimensionBooleanMeta({
    displayNameMessageDescriptor: msg({
      message: 'Is energy star',
      context: 'The display name for a dimension',
      comment: 'Whether this product is energy star certified',
    }),
    fieldFamilyMessageDescriptor: PRODUCT_FAMILY,
  }),
  refrigerantName: dimensionStringMeta({
    ...getBatMetadataProperties('refrigerants', 'refrigerant_name'),
    fieldFamilyMessageDescriptor: REFRIGERANT_FAMILY,
  }),
  refrigerantLeakageSource: dimensionStringMeta({
    ...getBatMetadataProperties('refrigerants', 'refrigerant_leakage_source'),
    fieldFamilyMessageDescriptor: REFRIGERANT_FAMILY,
  }),
  foodProcessingSector: dimensionStringMeta({
    ...getBatMetadataProperties('food_mass', 'food_processing_sector'),
    fieldFamilyMessageDescriptor: FOOD_FAMILY,
  }),
  foodItem: dimensionStringMeta({
    ...getBatMetadataProperties('food_mass', 'food_item'),
    fieldFamilyMessageDescriptor: FOOD_FAMILY,
  }),
  foodUseCase: dimensionStringMeta({
    ...getBatMetadataProperties('food_mass', 'food_use_case'),
    fieldFamilyMessageDescriptor: FOOD_FAMILY,
  }),
  fuelCombustionReason: dimensionStringMeta({
    ...getBatMetadataProperties('fuel_combustion', 'fuel_combustion_reason'),
    fieldFamilyMessageDescriptor: ENERGY_FAMILY,
  }),
  fuelCombustionKind: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Fuel combustion kind',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ENERGY_FAMILY,
  }),

  electricityConsumptionReason: dimensionStringMeta({
    ...getBatMetadataProperties(
      'non_building_electricity_consumption',
      'electricity_consumption_reason'
    ),
    fieldFamilyMessageDescriptor: ENERGY_FAMILY,
  }),
  cleanPowerPercent: dimensionNumberMeta({
    ...getBatMetadataProperties(
      'non_building_electricity_consumption',
      'clean_power_percent'
    ),
    fieldFamilyMessageDescriptor: ENERGY_FAMILY,
  }),
  onsiteCleanPowerPercent: dimensionNumberMeta({
    ...getBatMetadataProperties(
      'tier_1_factories_activity_data',
      'onsite_clean_power_percent'
    ),
    fieldFamilyMessageDescriptor: ENERGY_FAMILY,
  }),
  // Note: This same field is re-used across multiple BATs
  isThirdParty: dimensionBooleanMeta({
    ...getBatMetadataProperties('fuel_combustion', 'is_third_party'),
    fieldFamilyMessageDescriptor: ENERGY_FAMILY,
  }),
  heatingValueKind: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Heating value kind',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ENERGY_FAMILY,
  }),
  cleanPowerContractualInstrument: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Contractual instrument',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ENERGY_FAMILY,
    staticListOptionsProvider: (i18n: I18n) =>
      recordToStaticListOptions(
        i18n,
        CLEAN_POWER_CONTRACTUAL_INSTRUMENT_LABELS
      ),
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  buildingName: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Facility ID',
      context: 'The display name for a dimension',
    }),
    shouldSentenceCaseValues: false,
    fieldFamilyMessageDescriptor: BUILDING_FAMILY,
  }),
  buildingKind: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Facility kind',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: BUILDING_FAMILY,
  }),
  buildingLeaseType: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Facility lease type',
      context: 'The display name for a dimension',
    }),
    shouldSentenceCaseValues: false,
    staticListOptionsProvider: (i18n: I18n) =>
      recordToStaticListOptions(i18n, BART_LEASE_TYPE_LABELS),
    fieldFamilyMessageDescriptor: BUILDING_FAMILY,
  }),
  measurementProjectIds: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Measurement project',
      context: 'The display name for a dimension',
    }),
    isMultiValue: true,
  }),
  businessActivityType: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Activity type',
      context: 'The display name for a dimension',
    }),
    staticListOptionsProvider: (i18n: I18n) =>
      MARTA_SUPPORTED_BART_TABLES.map((table) => ({
        value: table,
        label: BatMetadata.getDisplayName(table, i18n),
      })),
  }),
  assetHoldingCategory: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Asset category',
      context: 'The display name for a dimension',
    }),
    staticListOptionsProvider: (i18n: I18n) =>
      FinanceHoldingTypesList.map((table) => ({
        value: table,
        label: BatMetadata.getDisplayName(table, i18n),
      })),
  }),
  assetHoldingClass: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Asset class',
      context: 'The display name for a dimension',
    }),
  }),
  fileIds: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'File',
      context: 'The display name for a dimension',
    }),
    isMultiValue: true,
  }),
  approvalStatus: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Approval status',
      context: 'The display name for a dimension',
    }),
  }),
  isBuildingEmpty: dimensionBooleanMeta({
    displayNameMessageDescriptor: msg({
      message: 'Is facility empty',
      context: 'The display name for a dimension',
    }),

    fieldFamilyMessageDescriptor: BUILDING_FAMILY,
  }),
  dataOwners: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Data owners',
      context: 'The display name for a dimension',
    }),
    isMultiValue: true,
    shouldSentenceCaseValues: false,
  }),
  wasteTreatment: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Waste treatment',
      context: 'The display name for a dimension',
    }),
    staticListOptionsProvider: (i18n: I18n) =>
      recordToStaticListOptions(i18n, WASTE_TREATMENT_LABELS),
    fieldFamilyMessageDescriptor: WASTE_FAMILY,
  }),
  wasteMaterial: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Waste material',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: WASTE_FAMILY,
  }),
  wasteLifecycle: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Waste lifecycle',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: WASTE_FAMILY,
  }),
  isWasteHazardous: dimensionBooleanMeta({
    displayNameMessageDescriptor: msg({
      message: 'Waste is hazardous',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: WASTE_FAMILY,
  }),
  isWasteRadioactive: dimensionBooleanMeta({
    displayNameMessageDescriptor: msg({
      message: 'Waste is radioactive',
      context: 'The display name for a dimension',
    }),

    fieldFamilyMessageDescriptor: WASTE_FAMILY,
  }),
  areaUnit: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Area unit',
      context: 'The display name for a dimension',
    }),
  }),
  volumeUnit: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Volume unit',
      context: 'The display name for a dimension',
    }),
  }),
  massUnit: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Weight unit',
      context: 'The display name for a dimension',
    }),
  }),
  energyUnit: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Energy unit',
      context: 'The display name for a dimension',
    }),
  }),
  distanceUnit: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Distance unit',
      context: 'The display name for a dimension',
    }),
  }),
  fuelUnit: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Fuel unit',
      context: 'The display name for a dimension',
    }),
  }),
  aggregatedFuelUnit: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Aggregated fuel unit',
      context: 'The display name for a dimension',
    }),
  }),
  aggregatedCustomUnit: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Aggregated custom unit',
      context:
        'The unit associated with the quantity field where the quantity can represent any unit type (i.e. volume, mass, etc).',
    }),
  }),
  customUnit: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Custom unit',
      context:
        'The unit associated with the quantity field where the quantity can represent any unit type (i.e. volume, mass, etc).',
    }),
  }),
  casNumber: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'CAS number',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: POLLUTION_FAMILY,
    staticListOptionsProvider: (i18n: I18n) =>
      recordToStaticListOptions(i18n, CAS_NUMBER_LABELS),
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  euPollutantReleaseAndTransferRegisterPollutantGroup: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'EU Pollutant Release and Transfer Register group',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: POLLUTION_FAMILY,
    staticListOptionsProvider: (i18n: I18n) =>
      recordToStaticListOptions(i18n, POLLUTANT_GROUP_LABELS),
    isMultiValue: true,
    hiddenInGroupBy: true,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  euPollutantReleaseAndTransferRegisterPollutantGroupAll: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'EU Pollutant Release and Transfer Register group (All)',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: POLLUTION_FAMILY,
    staticListOptionsProvider: (i18n: I18n) =>
      recordToStaticListOptions(i18n, POLLUTANT_GROUP_LABELS),
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  pollutantSubstanceOfConcern: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Substance of concern',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: POLLUTION_FAMILY,
    staticListOptionsProvider: (i18n: I18n) =>
      recordToStaticListOptions(i18n, SUBSTANCE_OF_CONCERN_LABELS),
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  pollutantSubstanceOfConcernHazardClass: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Hazard class',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: POLLUTION_FAMILY,
    isMultiValue: true,
    hiddenInGroupBy: true,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  substanceInflowMedium: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Substance inflow medium',
      context: 'The display name for a dimension',
    }),
    staticListOptionsProvider: (i18n: I18n) =>
      recordToStaticListOptions(
        i18n,
        SUBSTANCE_AND_MICROPLASTICS_INFLOW_MEDIUM_LABELS
      ),
    fieldFamilyMessageDescriptor: POLLUTION_FAMILY,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  substanceOutflowMedium: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Substance outflow medium',
      context: 'The display name for a dimension',
    }),
    staticListOptionsProvider: (i18n: I18n) =>
      recordToStaticListOptions(i18n, SUBSTANCE_OUTFLOW_MEDIUM_LABELS),
    fieldFamilyMessageDescriptor: POLLUTION_FAMILY,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  microplasticsInflowMedium: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Microplastics inflow medium',
      context: 'The display name for a dimension',
    }),
    staticListOptionsProvider: (i18n: I18n) =>
      recordToStaticListOptions(
        i18n,
        SUBSTANCE_AND_MICROPLASTICS_INFLOW_MEDIUM_LABELS
      ),
    fieldFamilyMessageDescriptor: POLLUTION_FAMILY,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  microplasticsOutflowMedium: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Microplastics outflow medium',
      context: 'The display name for a dimension',
    }),
    staticListOptionsProvider: (i18n: I18n) =>
      recordToStaticListOptions(i18n, MICROPLASTICS_OUTFLOW_MEDIUM_LABELS),
    fieldFamilyMessageDescriptor: POLLUTION_FAMILY,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  financialAccountId: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Financial account ID',
      context: 'The display name for a dimension',
    }),
    shouldSentenceCaseValues: false,
    fieldFamilyMessageDescriptor: FINANCIALS_FAMILY,
  }),
  financialAccountName: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Financial account name',
      context: 'The display name for a dimension',
    }),
    shouldSentenceCaseValues: false,
    fieldFamilyMessageDescriptor: FINANCIALS_FAMILY,
  }),
  uploaderIds: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Uploader',
      context: 'The display name for a dimension',
    }),
    isMultiValue: true,
  }),
  datasetId: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Dataset',
      context: 'The display name for a dimension',
    }),
  }),
  beaCode: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'BEA code',
      context: 'The display name for a dimension',
    }),
    shouldSentenceCaseValues: false,
    fieldFamilyMessageDescriptor: FINANCIALS_FAMILY,
  }),
  electricityType: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Electricity type',
      context: 'The display name for a dimension',
    }),
  }),
  material: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Material',
      context: 'The display name for a dimension',
    }),
  }),
  releaseId: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Calculation method',
      context: 'The display name for a dimension',
    }),
  }),
  excludeRadiativeForcing: dimensionStringMeta({
    ...getBatMetadataProperties('travel_air', 'exclude_radiative_forcing'),
    fieldFamilyMessageDescriptor: FLIGHTS_FAMILY,
  }),
  flightCabinClass: dimensionStringMeta({
    ...getBatMetadataProperties('travel_air', 'flight_cabin_class'),
    staticListOptionsProvider: (i18n: I18n) =>
      recordToStaticListOptions(i18n, FLIGHT_CABIN_CLASSES_LABEL_MAPPING),
    fieldFamilyMessageDescriptor: FLIGHTS_FAMILY,
  }),
  flightRoute: dimensionStringMeta({
    ...getBatMetadataProperties('travel_air', 'flight_route'),
    fieldFamilyMessageDescriptor: FLIGHTS_FAMILY,
  }),
  tripKind: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Trip kind',
      context: 'The display name for a dimension',
    }),
    staticListOptionsProvider: (i18n: I18n) =>
      recordToStaticListOptions(i18n, TRIP_KIND_LABEL_MAPPING),
  }),
  perPassengerDistanceQuantity: dimensionNumberMeta({
    displayNameMessageDescriptor: msg({
      message: 'Per-passenger distance',
      context:
        'The display name for a dimension. Sometimes we ask for distance for a single trip as well as a passenger count, and in that case the distance refers to the distance that a single passenger traveled.',
    }),
  }),
  perLodgingUnitNightsStayed: dimensionNumberMeta({
    displayNameMessageDescriptor: msg({
      message: 'Per-room nights',
      context:
        'The display name for a dimension. We ask for lodging data in the form (room count, number of nights), so the total nights stayed is room count x number of nights.',
    }),
  }),
  batMatcherFilter: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'BAT matcher filter',
      context: 'The display name for a dimension',
    }),
    shouldSentenceCaseValues: false,
  }),
  'aqueduct40.waterStressLabel': dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Water stress',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: WATER_FAMILY,
    type: BiFieldType.string,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  'aqueduct40.waterDepletionLabel': dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Water depletion',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: WATER_FAMILY,
    type: BiFieldType.string,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  'aqueduct40.waterRiskLabel': dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Water risk',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: WATER_FAMILY,
    type: BiFieldType.string,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  'aqueduct40.isHighWaterStressOrHighWaterRisk': dimensionBooleanMeta({
    displayNameMessageDescriptor: msg({
      message: 'Is high water stress or high water risk',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: WATER_FAMILY,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  durability: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Product durability',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: PRODUCT_CIRCULARITY_FAMILY,
    type: BiFieldType.string,
    shouldSentenceCaseValues: false,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  industryAverageDurability: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Product durability industry average',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: PRODUCT_CIRCULARITY_FAMILY,
    type: BiFieldType.string,
    shouldSentenceCaseValues: false,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  repairability: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Product repairability',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: PRODUCT_CIRCULARITY_FAMILY,
    type: BiFieldType.string,
    shouldSentenceCaseValues: false,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  repairabilityRatingSystem: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Product repairability rating system',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: PRODUCT_CIRCULARITY_FAMILY,
    type: BiFieldType.string,
    shouldSentenceCaseValues: false,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  sustainableSourcingScheme: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Sustainable sourcing scheme',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: PRODUCT_CIRCULARITY_FAMILY,
    type: BiFieldType.string,
    shouldSentenceCaseValues: false,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  applicationOfCascadingPrinciple: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Application of cascading principle',
      context: 'The display name for a dimension',
    }),
    type: BiFieldType.string,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  isBiological: dimensionBooleanMeta({
    displayNameMessageDescriptor: msg({
      message: 'Is biological material',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: PRODUCT_CIRCULARITY_FAMILY,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  isSecondary: dimensionBooleanMeta({
    displayNameMessageDescriptor: msg({
      message: 'Is secondary material',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: PRODUCT_CIRCULARITY_FAMILY,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  componentIsRecyclable: dimensionBooleanMeta({
    displayNameMessageDescriptor: msg({
      message: 'Component is recyclable',
      context: 'The display name for a dimension',
      comment: 'Whether the component of the product is recyclable',
    }),
    fieldFamilyMessageDescriptor: PRODUCT_CIRCULARITY_FAMILY,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  componentIsPackaging: dimensionBooleanMeta({
    displayNameMessageDescriptor: msg({
      message: 'Component is packaging',
      context: 'The display name for a dimension',
      comment: 'Whether the component of the product is packaging',
    }),
    fieldFamilyMessageDescriptor: PRODUCT_CIRCULARITY_FAMILY,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  // #endregion product circularity field

  // #region  Biodiversity fields
  siteIdentifier: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Site identifier',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: BIODIVERSITY_FAMILY,
    type: BiFieldType.string,
    shouldSentenceCaseValues: false,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  isInOrNearBiodiversitySensitiveArea: dimensionBooleanMeta({
    displayNameMessageDescriptor: msg({
      message: 'Is in or near a biodiversity sensitive area',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: BIODIVERSITY_FAMILY,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  natura2000SiteCodes: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Natura 2000 site codes',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: BIODIVERSITY_FAMILY,
    type: BiFieldType.string,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
    isMultiValue: true,
  }),
  natura2000SiteNames: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Natura 2000 site names',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: BIODIVERSITY_FAMILY,
    type: BiFieldType.string,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
    isMultiValue: true,
  }),
  hasCsrdNegativelyAffectingActivities: dimensionBooleanMeta({
    displayNameMessageDescriptor: msg({
      message: 'Has activities negatively affecting biodiversity',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: BIODIVERSITY_FAMILY,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  // #endregion Biodiversity fields

  // This field exists in vehicles and travel_private_jets schemas and ideally has different
  // descriptions per field.
  isCompanyOwnedOrLeased: dimensionBooleanMeta({
    displayNameMessageDescriptor: msg({
      message: 'Asset is owned or leased by the company',
      context: 'The display name for a dimension',
    }),
  }),
  privatePlaneModel: dimensionStringMeta({
    ...getBatMetadataProperties('travel_private_jets', 'private_plane_model'),
  }),
  vehicleType: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Vehicle type',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: VEHICLES_FAMILY,
    type: BiFieldType.string,
  }),
  vehicleUsageReason: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Vehicle usage reason',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: VEHICLES_FAMILY,
    type: BiFieldType.string,
  }),
  fuelConsumedTimeOfSale: dimensionNumberMeta({
    ...getBatMetadataProperties('sold_vehicle', 'fuel_consumed_time_of_sale'),
    fieldFamilyMessageDescriptor: VEHICLES_FAMILY,
  }),
  fuelConsumptionUnit: dimensionStringMeta({
    ...getBatMetadataProperties('sold_vehicle', 'fuel_consumption_unit'),
    fieldFamilyMessageDescriptor: VEHICLES_FAMILY,
  }),
  lifetimeDistanceTraveled: dimensionNumberMeta({
    ...getBatMetadataProperties('sold_vehicle', 'lifetime_distance_traveled'),
    fieldFamilyMessageDescriptor: VEHICLES_FAMILY,
  }),
  lifetimeDistanceTraveledUnit: dimensionStringMeta({
    ...getBatMetadataProperties(
      'sold_vehicle',
      'lifetime_distance_traveled_unit'
    ),
    fieldFamilyMessageDescriptor: VEHICLES_FAMILY,
  }),
  lifetimeFuelConsumption: dimensionNumberMeta({
    ...getBatMetadataProperties('sold_vehicle', 'lifetime_fuel_consumption'),
    fieldFamilyMessageDescriptor: VEHICLES_FAMILY,
  }),
  odometerReadingTimeOfSale: dimensionNumberMeta({
    ...getBatMetadataProperties(
      'sold_vehicle',
      'odometer_reading_time_of_sale'
    ),
    fieldFamilyMessageDescriptor: VEHICLES_FAMILY,
  }),
  odometerReadingTimeOfSaleUnit: dimensionStringMeta({
    ...getBatMetadataProperties(
      'sold_vehicle',
      'odometer_reading_time_of_sale_unit'
    ),
    fieldFamilyMessageDescriptor: VEHICLES_FAMILY,
  }),

  // Activity with custom EF
  emissionsSpecifier: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Emissions specifier',
      context: 'The display name for a dimension',
    }),
    type: BiFieldType.string,
  }),

  // #region Workplace report fields
  reportId: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Report ID',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: WORKPLACE_REPORTS_FAMILY,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  incidentId: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Incident ID',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: WORKPLACE_REPORTS_FAMILY,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  isFiledByOwnWorkforce: dimensionBooleanMeta({
    displayNameMessageDescriptor: msg({
      message: 'Is filed by own workforce',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: WORKPLACE_REPORTS_FAMILY,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  isFiledToNationalContactPointsForOecd: dimensionBooleanMeta({
    displayNameMessageDescriptor: msg({
      message:
        'Is filed to National Contact Points for OECD Multinational Enterprises',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: WORKPLACE_REPORTS_FAMILY,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  isDiscrimination: dimensionBooleanMeta({
    displayNameMessageDescriptor: msg({
      message: 'Is discrimination',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: WORKPLACE_REPORTS_FAMILY,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  monetaryRemediationCurrency: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Monetary remediation currency',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: WORKPLACE_REPORTS_FAMILY,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  // #endregion Workplace report fields

  // #region lobbying fields
  recipient: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Recipient',
      context: 'The display name for a dimension for lobbying',
    }),
    fieldFamilyMessageDescriptor: LOBBYING_FAMILY,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  expenseKind: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Expense kind',
      context: 'The display name for a dimension for lobbying',
    }),
    staticListOptionsProvider: (i18n: I18n) =>
      recordToStaticListOptions(i18n, LOBBYING_EXPENSE_KIND_LABEL_MAPPING),
    fieldFamilyMessageDescriptor: LOBBYING_FAMILY,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  contributionKind: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Contribution kind',
      context: 'The display name for a dimension for lobbying',
    }),
    staticListOptionsProvider: (i18n: I18n) =>
      recordToStaticListOptions(i18n, LOBBYING_CONTRIBUTION_KIND_LABEL_MAPPING),
    fieldFamilyMessageDescriptor: LOBBYING_FAMILY,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  // #endregion lobbying fields

  // #region social fields
  employeeType: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Health and safety incident employee type',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: HEALTH_AND_SAFETY_FAMILY,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
    staticListOptionsProvider: (i18n: I18n) =>
      recordToStaticListOptions(
        i18n,
        HEALTH_AND_SAFETY_INCIDENT_EMPLOYEE_TYPE_LABELS
      ),
  }),
  incidentType: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Incident type',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: HEALTH_AND_SAFETY_FAMILY,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
    staticListOptionsProvider: (i18n: I18n) =>
      recordToStaticListOptions(i18n, INCIDENT_TYPE_LABEL_MAPPING),
  }),
  didUndertakingPlayRoleInSecuringRemedy: dimensionBooleanMeta({
    displayNameMessageDescriptor: msg({
      message: 'Did undertaking play role in securing remedy',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: SEVERE_HUMAN_RIGHTS_INCIDENT_FAMILY,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  isCaseOfNonRespect: dimensionBooleanMeta({
    displayNameMessageDescriptor: msg({
      message: 'Is case of non-respect',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: SEVERE_HUMAN_RIGHTS_INCIDENT_FAMILY,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  hasConviction: dimensionBooleanMeta({
    displayNameMessageDescriptor: msg({
      message: 'Has conviction',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ANTI_BRIBERY_AND_CORRUPTION_FAMILY,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  wasOwnWorkersDismissed: dimensionBooleanMeta({
    displayNameMessageDescriptor: msg({
      message: 'Was own workers dismissed',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ANTI_BRIBERY_AND_CORRUPTION_FAMILY,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  wasRelatedToContractsThatWereNegativelyAffected: dimensionBooleanMeta({
    displayNameMessageDescriptor: msg({
      message: 'Was related to contracts that were negatively affected',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ANTI_BRIBERY_AND_CORRUPTION_FAMILY,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  isAlignedToStandardTerms: dimensionBooleanMeta({
    displayNameMessageDescriptor: msg({
      message: 'Is aligned to standard terms',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: PAYMENT_ORDER_FAMILY,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  vendorCategoryType: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Vendor category type',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: PAYMENT_ORDER_FAMILY,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  // #endregion social fields

  paymentDate: dimensionDateMeta({
    displayNameMessageDescriptor: msg({
      message: 'Payment date',
      context: 'The display name for a dimension',
      comment: 'The date when a payment order was made.',
    }),
    fieldFamilyMessageDescriptor: PAYMENT_ORDER_FAMILY,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  workerGender: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Gender',
      context: 'The display name for a dimension',
      comment: 'The gender of the worker',
    }),
    staticListOptionsProvider: (i18n: I18n) =>
      recordToStaticListOptions(i18n, GENDER_LABELS),
    fieldFamilyMessageDescriptor: WORKFORCE_FAMILY,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  employeeGroupType: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Employee group type',
      context: 'The display name for a dimension',
      comment:
        'The type of employment for the group (e.g. permanent, temporary)',
    }),
    staticListOptionsProvider: (i18n: I18n) =>
      recordToStaticListOptions(i18n, EMPLOYEE_GROUP_TYPE_LABELS),
    fieldFamilyMessageDescriptor: WORKFORCE_FAMILY,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  employmentType: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Employment type',
      context: 'The display name for a dimension',
      comment:
        'The type of employment for the group (e.g. full-time, part-time, non-guaranteed hours)',
    }),
    staticListOptionsProvider: (i18n: I18n) =>
      recordToStaticListOptions(i18n, EMPLOYMENT_TYPE_LABELS),
    fieldFamilyMessageDescriptor: WORKFORCE_FAMILY,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  employeeCategory: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Employee category',
      context: 'The display name for a dimension',
      comment:
        'The category of employee (e.g. middle managent, individual contributor)',
    }),
    fieldFamilyMessageDescriptor: WORKFORCE_FAMILY,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  region: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Region',
      context: 'The display name for a dimension',
      comment: 'The region of the worker (e.g. EMEA)',
    }),
    fieldFamilyMessageDescriptor: WORKFORCE_FAMILY,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  ownWorkforceType: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Own workforce type',
      context: 'The display name for a dimension',
      comment:
        'Whether the group is made up of employees or non-employees (i.e. contractors)',
    }),
    staticListOptionsProvider: (i18n: I18n) =>
      recordToStaticListOptions(i18n, OWN_WORKFORCE_TYPE_LABELS),
    fieldFamilyMessageDescriptor: WORKFORCE_FAMILY,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  isTopLevelManagement: dimensionBooleanMeta({
    displayNameMessageDescriptor: msg({
      message: 'Is top level management',
      context: 'The display name for a dimension',
      comment: 'True/False for whether the worker is in top level management',
    }),
    fieldFamilyMessageDescriptor: WORKFORCE_FAMILY,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  ageGroup: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Age group',
      context: 'The display name for a dimension',
      comment: 'The age range of the workers (e.g. under 30, 30-50, over 50)',
    }),
    staticListOptionsProvider: (i18n: I18n) =>
      recordToStaticListOptions(i18n, AGE_GROUP_LABELS),
    fieldFamilyMessageDescriptor: WORKFORCE_FAMILY,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  nonEmployeeType: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Non-employee type',
      context: 'The display name for a dimension',
      comment: 'The non-employee type.',
    }),
    staticListOptionsProvider: (i18n: I18n) =>
      recordToStaticListOptions(i18n, NON_EMPLOYEE_TYPE_LABELS),
    fieldFamilyMessageDescriptor: WORKFORCE_FAMILY,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),

  // #region Internal recat fields.
  //  These should have the same metadata as the corresponding external fields.
  internalCategoryId: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Category',
      context: 'The display name for a dimension',
    }),
    hiddenInGroupBy: true,
    hiddenInFilters: true,
    staticListOptionsProvider: (i18n: I18n) =>
      recordToStaticListOptions(i18n, BUSINESS_CATEGORY_LABELS),
    fieldFamilyMessageDescriptor: CATEGORY_FAMILY,
  }),
  internalSubcategoryId: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Subcategory',
      context: 'The display name for a dimension',
    }),
    hiddenInGroupBy: true,
    hiddenInFilters: true,
    fieldFamilyMessageDescriptor: CATEGORY_FAMILY,
  }),
  internalDescription: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Description',
      context: 'The display name for a dimension',
    }),
    hiddenInGroupBy: true,
    hiddenInFilters: true,
    fieldFamilyMessageDescriptor: CATEGORY_FAMILY,
  }),
  internalGhgCategoryId: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'GHG category',
      context: 'The display name for a dimension',
    }),
    hiddenInGroupBy: true,
    hiddenInFilters: true,
    staticListOptionsProvider: (i18n: I18n) =>
      recordToStaticListOptions(i18n, GHG_CATEGORY_LABELS),
    fieldFamilyMessageDescriptor: CATEGORY_FAMILY,
  }),
  // #endregion Internal recat fields

  destinationLocationCountry: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Destination country',
      context: 'The display name for a dimension',
    }),
    shouldSentenceCaseValues: false,
    staticListOptionsProvider: (i18n: I18n) => COUNTRY_LABEL_OBJECTS,
    fieldFamilyMessageDescriptor: LOCATION_FAMILY,
  }),
  destinationLocationPostalCode: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Destination postal code',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: LOCATION_FAMILY,
  }),
  destinationLocationCity: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Destination city',
      context: 'The display name for a dimension',
    }),
    shouldSentenceCaseValues: false,
    fieldFamilyMessageDescriptor: LOCATION_FAMILY,
  }),
  destinationLocationSubdivision: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Destination subdivision',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: LOCATION_FAMILY,
  }),
  originLocationCountry: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Origin country',
      context: 'The display name for a dimension',
    }),
    shouldSentenceCaseValues: false,
    staticListOptionsProvider: (i18n: I18n) => COUNTRY_LABEL_OBJECTS,
    fieldFamilyMessageDescriptor: LOCATION_FAMILY,
  }),
  originLocationPostalCode: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Origin postal code',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: LOCATION_FAMILY,
  }),
  originLocationCity: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Origin city',
      context: 'The display name for a dimension',
    }),
    shouldSentenceCaseValues: false,
    fieldFamilyMessageDescriptor: LOCATION_FAMILY,
  }),
  originLocationSubdivision: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Origin subdivision',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: LOCATION_FAMILY,
  }),
  logisticsPosition: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Logistics position',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: LOGISTICS_FAMILY,
    staticListOptionsProvider: (i18n: I18n) =>
      recordToStaticListOptions(i18n, LOGISTICS_POSITION_LABEL_MAPPING),
  }),
  excludeFirstMile: dimensionBooleanMeta({
    ...getBatMetadataProperties('logistics', 'exclude_first_mile'),
    fieldFamilyMessageDescriptor: LOGISTICS_FAMILY,
  }),
  excludeLastMile: dimensionBooleanMeta({
    ...getBatMetadataProperties('logistics', 'exclude_last_mile'),
    fieldFamilyMessageDescriptor: LOGISTICS_FAMILY,
  }),
  numberOfTrips: dimensionStringMeta({
    ...getBatMetadataProperties('logistics', 'number_of_trips'),
    fieldFamilyMessageDescriptor: LOGISTICS_FAMILY,
  }),
  transportationMode: dimensionStringMeta({
    ...getBatMetadataProperties('logistics', 'transportation_mode'),
    fieldFamilyMessageDescriptor: LOGISTICS_FAMILY,
    staticListOptionsProvider: (i18n: I18n) =>
      recordToStaticListOptions(i18n, TRANSPORTATION_MODES_LABEL_MAPPING),
  }),
  distanceMethod: dimensionStringMeta({
    ...getBatMetadataProperties('logistics', 'distance_method'),
    fieldFamilyMessageDescriptor: LOGISTICS_FAMILY,
    staticListOptionsProvider: (i18n: I18n) =>
      recordToStaticListOptions(i18n, DISTANCE_METHOD_LABEL_MAPPING),
  }),
  emissionsModelFlatRegionsAttr: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Emissions Model Flat Regions Attribute JSON',
      context: 'The display name for a dimension',
    }),
    isInternal: true,
    type: BiFieldType.string, // except it's json...
  }),

  // #region Carbon Credit fields
  contractId: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Contract ID',
      context: 'The display name for a dimension',
    }),
    type: BiFieldType.string,
    fieldFamilyMessageDescriptor: CARBON_CREDITS_FAMILY,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  tco2eContractedAmount: dimensionNumberMeta({
    displayNameMessageDescriptor: msg({
      message: 'tCO₂e contracted amount',
      context: 'The display name for a dimension',
    }),
    type: BiFieldType.number,
    fieldFamilyMessageDescriptor: CARBON_CREDITS_FAMILY,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  carbonRemovalType: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Carbon removal type',
      context: 'The display name for a dimension',
    }),
    type: BiFieldType.string,
    fieldFamilyMessageDescriptor: CARBON_CREDITS_FAMILY,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  is3rdPartyVerified: dimensionBooleanMeta({
    displayNameMessageDescriptor: msg({
      message: 'Is third party verified',
      context: 'The display name for a dimension',
    }),
    type: BiFieldType.boolean,
    fieldFamilyMessageDescriptor: CARBON_CREDITS_FAMILY,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  qualityStandard: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Quality standard',
      context: 'The display name for a dimension',
    }),
    type: BiFieldType.string,
    fieldFamilyMessageDescriptor: CARBON_CREDITS_FAMILY,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  qualifiesAsCorrespondingAdjustment: dimensionBooleanMeta({
    displayNameMessageDescriptor: msg({
      message: 'Qualifies as corresponding adjustment',
      context: 'The display name for a dimension',
    }),
    type: BiFieldType.boolean,
    fieldFamilyMessageDescriptor: CARBON_CREDITS_FAMILY,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  retirementDate: dimensionDateMeta({
    displayNameMessageDescriptor: msg({
      message: 'Retirement date',
      context: 'The display name for a dimension',
    }),
    type: BiFieldType.date,
    fieldFamilyMessageDescriptor: CARBON_CREDITS_FAMILY,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
    format: {
      month: 'short',
      year: 'numeric',
      day: 'numeric',
    },
  }),
  // #endregion Carbon Credit fields
  // #region GHG removals fields
  ghgRemovalProjectId: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Removal project ID',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: GHG_REMOVALS_FAMILY,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  ghgRemovalScope: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Undertaking scope',
      context: 'The display name for a dimension',
    }),
    staticListOptionsProvider: (i18n: I18n) =>
      recordToStaticListOptions(i18n, GHG_REMOVAL_UNDERTAKING_SCOPE_LABELS),
    fieldFamilyMessageDescriptor: GHG_REMOVALS_FAMILY,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  ghgConcerned: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'GHG concerned',
      context: 'The display name for a dimension',
      comment: 'The GHG type removed in the project (e.g. CO2)',
    }),
    fieldFamilyMessageDescriptor: GHG_REMOVALS_FAMILY,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  ghgRemovalType: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Removal type',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: GHG_REMOVALS_FAMILY,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
    shouldSentenceCaseValues: true,
  }),
  ghgRemovalActivity: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Removal activity',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: GHG_REMOVALS_FAMILY,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
    shouldSentenceCaseValues: true,
  }),
  ghgRemovalStoragePool: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Storage pool',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: GHG_REMOVALS_FAMILY,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
    shouldSentenceCaseValues: true,
  }),
  ghgRemovalIsNatureBased: dimensionBooleanMeta({
    displayNameMessageDescriptor: msg({
      message: 'Is nature based',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: GHG_REMOVALS_FAMILY,
    gatingFeatureFlag: GQFlags.PricingFy25CsrdReportBuilderModuleTemp,
  }),
  // #endregion GHG removals

  // #region Fund fields
  fundId: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Fund ID',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: FUND_FAMILY,
  }),
  fundName: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Fund name',
      context: 'The display name for a dimension',
    }),
    shouldSentenceCaseValues: false,
    fieldFamilyMessageDescriptor: FUND_FAMILY,
  }),
  fundGroup: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Fund group',
      context: 'The display name for a dimension',
    }),
    shouldSentenceCaseValues: false,
    fieldFamilyMessageDescriptor: FUND_FAMILY,
  }),
  fundCategory: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Fund category',
      context: 'The display name for a dimension',
    }),
    shouldSentenceCaseValues: false,
    fieldFamilyMessageDescriptor: FUND_FAMILY,
  }),
  // #endregion

  // #region Asset properties
  assetIdFinance: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Asset ID',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ASSET_PROPERTIES_FAMILY,
  }),
  assetNameFinance: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Asset name',
      context: 'The display name for a dimension',
    }),
    shouldSentenceCaseValues: false,
    fieldFamilyMessageDescriptor: ASSET_PROPERTIES_FAMILY,
  }),
  industryCode: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Industry',
      context: 'The display name for a dimension',
    }),
    shouldSentenceCaseValues: false,
    fieldFamilyMessageDescriptor: ASSET_PROPERTIES_FAMILY,
  }),
  locationCountryFinance: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Country',
      context: 'The display name for a dimension',
    }),
    shouldSentenceCaseValues: false,
    fieldFamilyMessageDescriptor: ASSET_PROPERTIES_FAMILY,
  }),
  companyTicker: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Company ticker',
      context: 'The display name for a dimension',
    }),
    shouldSentenceCaseValues: false,
    fieldFamilyMessageDescriptor: ASSET_PROPERTIES_FAMILY,
  }),
  companyWebsiteUrl: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Company website URL',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ASSET_PROPERTIES_FAMILY,
  }),
  isinNumber: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'ISIN number',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ASSET_PROPERTIES_FAMILY,
  }),
  leiCode: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'LEI code',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ASSET_PROPERTIES_FAMILY,
  }),
  watershedCompanyId: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Watershed company ID',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ASSET_PROPERTIES_FAMILY,
  }),
  buildingKindFinance: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Building kind',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ASSET_PROPERTIES_FAMILY,
  }),
  buildingSubkindFinance: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Building subkind',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ASSET_PROPERTIES_FAMILY,
  }),
  locationCityFinance: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'City',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ASSET_PROPERTIES_FAMILY,
    shouldSentenceCaseValues: false,
  }),
  locationStateFinance: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'State',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ASSET_PROPERTIES_FAMILY,
    shouldSentenceCaseValues: false,
  }),
  locationPostalCodeFinance: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Postal code',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ASSET_PROPERTIES_FAMILY,
    shouldSentenceCaseValues: false,
  }),
  percentRenewable: dimensionNumberMeta({
    displayNameMessageDescriptor: msg({
      message: 'Renewable energy %',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ASSET_PROPERTIES_FAMILY,
  }),
  sovereignEntity: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Sovereign entity',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ASSET_PROPERTIES_FAMILY,
    shouldSentenceCaseValues: false,
  }),
  iciAlignmentStatus: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'iCI alignment status',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ASSET_PROPERTIES_FAMILY,
  }),
  sAndPId: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'S&P ID',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ASSET_PROPERTIES_FAMILY,
  }),
  dunsNumber: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'DUNS number',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ASSET_PROPERTIES_FAMILY,
  }),
  einNumber: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'EIN number',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ASSET_PROPERTIES_FAMILY,
  }),
  vatId: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'VAT ID',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ASSET_PROPERTIES_FAMILY,
  }),
  localRegistryId: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Local registry ID',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ASSET_PROPERTIES_FAMILY,
  }),
  sbtCommitmentStage: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'SBT commitment stage',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ASSET_PROPERTIES_FAMILY,
  }),
  // #endregion

  // #region Annual data
  committedToNearTermSbtAlignedZero: dimensionBooleanMeta({
    displayNameMessageDescriptor: msg({
      message: 'Committed to near-term SBT-aligned net zero target',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ANNUAL_DATA_FAMILY,
  }),
  hasInitiatedDecarbonizationPlan: dimensionBooleanMeta({
    displayNameMessageDescriptor: msg({
      message: 'Has initiated decarbonization plan',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ANNUAL_DATA_FAMILY,
  }),
  iciAlignmentStatusOverride: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'iCI alignment status override',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ANNUAL_DATA_FAMILY,
  }),
  demonstratingYearOnYearNetZeroPathwayEmissionsProfile: dimensionBooleanMeta({
    displayNameMessageDescriptor: msg({
      message: 'Demonstrating year-on-year net zero pathway emissions profile',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ANNUAL_DATA_FAMILY,
  }),
  isMeasuringMaterialScope3Emissions: dimensionBooleanMeta({
    displayNameMessageDescriptor: msg({
      message: 'Measuring material Scope 3 emissions',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ANNUAL_DATA_FAMILY,
  }),
  isMeasuringScope1And2Emissions: dimensionBooleanMeta({
    displayNameMessageDescriptor: msg({
      message: 'Measuring Scope 1 and 2 emissions',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ANNUAL_DATA_FAMILY,
  }),
  buildingSizeFinance: dimensionNumberMeta({
    displayNameMessageDescriptor: msg({
      message: 'Building size',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ANNUAL_DATA_FAMILY,
  }),
  buildingSizeUnitFinance: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Building size unit',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ANNUAL_DATA_FAMILY,
  }),
  headcountFinance: dimensionNumberMeta({
    displayNameMessageDescriptor: msg({
      message: 'Headcount',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ANNUAL_DATA_FAMILY,
  }),
  cloudSpendFinance: dimensionNumberMeta({
    displayNameMessageDescriptor: msg({
      message: 'Cloud spend',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ANNUAL_DATA_FAMILY,
  }),
  electricityConsumptionFinance: dimensionNumberMeta({
    displayNameMessageDescriptor: msg({
      message: 'Electricity consumption',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ANNUAL_DATA_FAMILY,
  }),
  flightMilesFinance: dimensionNumberMeta({
    displayNameMessageDescriptor: msg({
      message: 'Flight miles',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ANNUAL_DATA_FAMILY,
  }),
  scope1EmissionsOverride: dimensionNumberMeta({
    displayNameMessageDescriptor: msg({
      message: 'Scope 1 emissions override',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ANNUAL_DATA_FAMILY,
  }),
  scope1PcafOverride: dimensionNumberMeta({
    displayNameMessageDescriptor: msg({
      message: 'Scope 1 PCAF override',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ANNUAL_DATA_FAMILY,
  }),
  scope2LocationEmissionsOverride: dimensionNumberMeta({
    displayNameMessageDescriptor: msg({
      message: 'Scope 2 location emissions override',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ANNUAL_DATA_FAMILY,
  }),
  scope2MarketEmissionsOverride: dimensionNumberMeta({
    displayNameMessageDescriptor: msg({
      message: 'Scope 2 market emissions override',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ANNUAL_DATA_FAMILY,
  }),
  scope2LocationPcafOverride: dimensionNumberMeta({
    displayNameMessageDescriptor: msg({
      message: 'Scope 2 location PCAF override',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ANNUAL_DATA_FAMILY,
  }),
  scope2MarketPcafOverride: dimensionNumberMeta({
    displayNameMessageDescriptor: msg({
      message: 'Scope 2 market PCAF override',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ANNUAL_DATA_FAMILY,
  }),
  scope3EmissionsOverride: dimensionNumberMeta({
    displayNameMessageDescriptor: msg({
      message: 'Scope 3 emissions override',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ANNUAL_DATA_FAMILY,
  }),
  scope3PcafOverride: dimensionNumberMeta({
    displayNameMessageDescriptor: msg({
      message: 'Scope 3 PCAF override',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ANNUAL_DATA_FAMILY,
  }),
  scope3OverrideKind: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Scope 3 override kind',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ANNUAL_DATA_FAMILY,
  }),
  scope301EmissionsOverride: dimensionNumberMeta({
    displayNameMessageDescriptor: msg({
      message: 'Scope 3.01 emissions override',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ANNUAL_DATA_FAMILY,
  }),
  scope302EmissionsOverride: dimensionNumberMeta({
    displayNameMessageDescriptor: msg({
      message: 'Scope 3.02 emissions override',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ANNUAL_DATA_FAMILY,
  }),
  scope303EmissionsOverride: dimensionNumberMeta({
    displayNameMessageDescriptor: msg({
      message: 'Scope 3.03 emissions override',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ANNUAL_DATA_FAMILY,
  }),
  scope304EmissionsOverride: dimensionNumberMeta({
    displayNameMessageDescriptor: msg({
      message: 'Scope 3.04 emissions override',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ANNUAL_DATA_FAMILY,
  }),
  scope305EmissionsOverride: dimensionNumberMeta({
    displayNameMessageDescriptor: msg({
      message: 'Scope 3.05 emissions override',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ANNUAL_DATA_FAMILY,
  }),
  scope306EmissionsOverride: dimensionNumberMeta({
    displayNameMessageDescriptor: msg({
      message: 'Scope 3.06 emissions override',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ANNUAL_DATA_FAMILY,
  }),
  scope307EmissionsOverride: dimensionNumberMeta({
    displayNameMessageDescriptor: msg({
      message: 'Scope 3.07 emissions override',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ANNUAL_DATA_FAMILY,
  }),
  scope308EmissionsOverride: dimensionNumberMeta({
    displayNameMessageDescriptor: msg({
      message: 'Scope 3.08 emissions override',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ANNUAL_DATA_FAMILY,
  }),
  scope309EmissionsOverride: dimensionNumberMeta({
    displayNameMessageDescriptor: msg({
      message: 'Scope 3.09 emissions override',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ANNUAL_DATA_FAMILY,
  }),
  scope310EmissionsOverride: dimensionNumberMeta({
    displayNameMessageDescriptor: msg({
      message: 'Scope 3.10 emissions override',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ANNUAL_DATA_FAMILY,
  }),
  scope311EmissionsOverride: dimensionNumberMeta({
    displayNameMessageDescriptor: msg({
      message: 'Scope 3.11 emissions override',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ANNUAL_DATA_FAMILY,
  }),
  scope312EmissionsOverride: dimensionNumberMeta({
    displayNameMessageDescriptor: msg({
      message: 'Scope 3.12 emissions override',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ANNUAL_DATA_FAMILY,
  }),
  scope313EmissionsOverride: dimensionNumberMeta({
    displayNameMessageDescriptor: msg({
      message: 'Scope 3.13 emissions override',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ANNUAL_DATA_FAMILY,
  }),
  scope314EmissionsOverride: dimensionNumberMeta({
    displayNameMessageDescriptor: msg({
      message: 'Scope 3.14 emissions override',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ANNUAL_DATA_FAMILY,
  }),
  scope315EmissionsOverride: dimensionNumberMeta({
    displayNameMessageDescriptor: msg({
      message: 'Scope 3.15 emissions override',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ANNUAL_DATA_FAMILY,
  }),
  estimationMethodology: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Estimation methodology',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ANNUAL_DATA_FAMILY,
  }),
  footprintSource: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Footprint source',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ANNUAL_DATA_FAMILY,
  }),
  revenueSource: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Revenue source',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ANNUAL_DATA_FAMILY,
  }),
  percentEmployeesWfh: dimensionNumberMeta({
    displayNameMessageDescriptor: msg({
      message: 'Percentage of employees working from home / remotely.',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: ANNUAL_DATA_FAMILY,
  }),
  // #endregion

  // #region Holding properties
  assetHoldingId: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Asset holding ID',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: HOLDING_PROPERTIES_FAMILY,
  }),
  yearOfInitialInvestment: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Year of initial investment',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: HOLDING_PROPERTIES_FAMILY,
    disableCommas: true,
  }),
  outstandingAmountCurrencyQuantity: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Outstanding amount',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: HOLDING_PROPERTIES_FAMILY,
  }),
  // #endregion
  // #region Scope 3.15 footprint snapshot fields - used by WS Finance
  pcafDataQualityScoreDimension: dimensionNumberMeta({
    displayNameMessageDescriptor: msg({
      message: 'PCAF data quality score',
      context: 'The display name for a dimension',
    }),
    fieldFamilyMessageDescriptor: SCOPE_3_15_FAMILY,
  }),
  scope315GhgCategoryId: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Scope 3.15 GHG category',
      context: 'The display name for a dimension',
    }),
    staticListOptionsProvider: (i18n: I18n) =>
      recordToStaticListOptions(i18n, GHG_CATEGORY_LABELS),
    fieldFamilyMessageDescriptor: SCOPE_3_15_FAMILY,
  }),
  lulucfCategory: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'LULUCF category',
      context: 'The display name for a dimension',
    }),
    staticListOptionsProvider: (i18n: I18n) =>
      recordToStaticListOptions(i18n, {
        'Is LULUCF': msg({
          context: 'LULUCF category label',
          message: 'Is LULUCF',
        }),
        'Non LULUCF': msg({
          context: 'LULUCF category label',
          message: 'Non LULUCF',
        }),
      }),
    fieldFamilyMessageDescriptor: SCOPE_3_15_FAMILY,
  }),
  // #endregion Scope 3.15 footprint snapshot fields - used by WS Finance

  // From here down should be internal fields that are here mostly for TS typing, they won't be exposed to the UI
  'marketEmissionsTrace.fingerprint': dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Market Emissions Trace Fingerprint',
      context: 'The display name for a dimension',
      comment: '@skip',
    }),
    isInternal: true,
  }),
  'locationEmissionsTrace.fingerprint': dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Location Emissions Trace Fingerprint',
      context: 'The display name for a dimension',
      comment: '@skip',
    }),
    isInternal: true,
  }),
  'biogenicCo2EmissionsTrace.fingerprint': dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Biogenic CO₂ Emissions Trace Fingerprint',
      context: 'The display name for a dimension',
      comment: '@skip',
    }),
    isInternal: true,
  }),
  'biogenicCo2LocationEmissionsTrace.fingerprint': dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Biogenic CO₂ Location Emissions Trace Fingerprint',
      context: 'The display name for a dimension',
      comment: '@skip',
    }),
    isInternal: true,
  }),
  'avoidedEmissionsTrace.fingerprint': dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Avoided Emissions Trace Fingerprint',
      context: 'The display name for a dimension',
      comment: '@skip',
    }),
    isInternal: true,
  }),
  'kwhEmissionsTrace.fingerprint': dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Kwh Emissions Trace Fingerprint',
      context: 'The display name for a dimension',
      comment: '@skip',
    }),
    isInternal: true,
  }),
  'wasteEmissionsTrace.fingerprint': dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Waste Emissions Trace Fingerprint',
      context: 'The display name for a dimension',
      comment: '@skip',
    }),
    isInternal: true,
  }),
  'waterUsedEmissionsTrace.fingerprint': dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Water Used Emissions Trace Fingerprint',
      context: 'The display name for a dimension',
      comment: '@skip',
    }),
    isInternal: true,
  }),
  'waterRecycledEmissionsTrace.fingerprint': dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Water Recycled Emissions Trace Fingerprint',
      context: 'The display name for a dimension',
      comment: '@skip',
    }),
    isInternal: true,
  }),
  'waterConsumedEmissionsTrace.fingerprint': dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Water Consumed Emissions Trace Fingerprint',
      context: 'The display name for a dimension',
      comment: '@skip',
    }),
    isInternal: true,
  }),
  'waterStoredEmissionsTrace.fingerprint': dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Water Stored Emissions Trace Fingerprint',
      context: 'The display name for a dimension',
      comment: '@skip',
    }),
    isInternal: true,
  }),
  'waterDischargedEmissionsTrace.fingerprint': dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Water Discharged Emissions Trace Fingerprint',
      context: 'The display name for a dimension',
      comment: '@skip',
    }),
    isInternal: true,
  }),
  'microplasticsInflowEmissionsTrace.fingerprint': dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Microplastics Inflow Emissions Trace Fingerprint',
      context: 'The display name for a dimension',
      comment: '@skip',
    }),
    isInternal: true,
  }),
  'microplasticsOutflowEmissionsTrace.fingerprint': dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Microplastics Outflow Emissions Trace Fingerprint',
      context: 'The display name for a dimension',
      comment: '@skip',
    }),
    isInternal: true,
  }),
  'substanceInflowEmissionsTrace.fingerprint': dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Substance Inflow Emissions Trace Fingerprint',
      context: 'The display name for a dimension',
      comment: '@skip',
    }),
    isInternal: true,
  }),
  'substanceOutflowEmissionsTrace.fingerprint': dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Substance Outflow Emissions Trace Fingerprint',
      context: 'The display name for a dimension',
      comment: '@skip',
    }),
    isInternal: true,
  }),
  emissionsModelEvaluationId: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Emissions Model Evaluation Id',
      context: 'The display name for a dimension',
      comment: '@skip',
    }),
    isInternal: true,
  }),
  convertedUnit: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Converted Unit',
      context: 'The display name for a dimension',
      comment: '@skip',
    }),
    isInternal: true,
  }),
  footprintExclusionRuleId: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Footprint Exclusion Rule Id',
      context: 'The display name for a dimension',
      comment: '@skip',
    }),
    isInternal: true,
  }),
  emissionsModelEvaluationRowId: dimensionNumberMeta({
    displayNameMessageDescriptor: msg({
      message: 'Emissions Model Evaluation Row Id',
      context: 'The display name for a dimension',
      comment: '@skip',
    }),
    isInternal: true,
  }),
  cloudRegion: dimensionStringMeta({
    ...getBatMetadataProperties('cloud_compute_usage', 'cloud_region'),
    fieldFamilyMessageDescriptor: CLOUD_FAMILY,
  }),
  cloudVmInstanceType: dimensionStringMeta({
    ...getBatMetadataProperties(
      'cloud_compute_usage',
      'cloud_vm_instance_type'
    ),
    fieldFamilyMessageDescriptor: CLOUD_FAMILY,
  }),
  cloudService: dimensionStringMeta({
    ...getBatMetadataProperties('cloud_compute_usage', 'cloud_service'),
    fieldFamilyMessageDescriptor: CLOUD_FAMILY,
  }),
  conversionFactorId: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Conversion Factor ID',
      context: 'The display name for a dimension',
      comment: '@skip',
    }),
    isInternal: true,
  }),
  'ef.id': dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Emissions Factor ID',
      context: 'The display name for a dimension',
      comment: '@skip',
    }),
    isInternal: true,
  }),
  'locEf.id': dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Location Emissions Factor ID',
      context: 'The display name for a dimension',
      comment: '@skip',
    }),
    isInternal: true,
  }),
  emissionsModelVersionId: dimensionStringMeta({
    displayNameMessageDescriptor: msg({
      message: 'Emissions Model Version ID',
      context: 'The display name for a dimension',
      comment: '@skip',
    }),
    isInternal: true,
  }),
  bartInstanceId: dimensionStringMeta({
    isInternal: true,
    displayNameMessageDescriptor: msg({
      message: 'Bart Instance Id',
      context: 'The display name for a dimension',
      comment: '@skip',
    }),
  }),
  bartRowId: dimensionNumberMeta({
    isInternal: true,
    displayNameMessageDescriptor: msg({
      message: 'Bart Row Id',
      context: 'The display name for a dimension',
      comment: '@skip',
    }),
  }),
  extBartInstanceId: dimensionStringMeta({
    isInternal: true,
    displayNameMessageDescriptor: msg({
      message: 'Ext Bart Instance Id',
      context: 'The display name for a dimension',
      comment: '@skip',
    }),
  }),
  extBartRowId: dimensionNumberMeta({
    isInternal: true,
    displayNameMessageDescriptor: msg({
      message: 'Ext Bart Row Id',
      context: 'The display name for a dimension',
      comment: '@skip',
    }),
  }),
  fileMetadataIds: dimensionStringMeta({
    isInternal: true,
    displayNameMessageDescriptor: msg({
      message: 'File Metadata Ids',
      context: 'The display name for a dimension',
      comment: '@skip',
    }),
    isMultiValue: true,
  }),
  dryMethod: dimensionStringMeta({
    ...getBatMetadataProperties(
      'consumer_goods_textile_product_use_phase',
      'dry_method'
    ),
    staticListOptionsProvider: (i18n: I18n) =>
      recordToStaticListOptions(i18n, DRY_METHOD_LABEL_MAPPING),
    fieldFamilyMessageDescriptor: PRODUCT_FAMILY,
  }),
  fabricCategory: dimensionStringMeta({
    ...getBatMetadataProperties(
      'consumer_goods_textile_product_use_phase',
      'fabric_category'
    ),
    fieldFamilyMessageDescriptor: PRODUCT_FAMILY,
  }),
  productCategory: dimensionStringMeta({
    ...getBatMetadataProperties(
      'consumer_goods_textile_product_use_phase',
      'product_category'
    ),
    fieldFamilyMessageDescriptor: PRODUCT_FAMILY,
  }),
  washMethod: dimensionStringMeta({
    ...getBatMetadataProperties(
      'consumer_goods_textile_product_use_phase',
      'wash_method'
    ),
    staticListOptionsProvider: (i18n: I18n) =>
      recordToStaticListOptions(i18n, WASH_METHOD_LABEL_MAPPING),
    fieldFamilyMessageDescriptor: PRODUCT_FAMILY,
  }),
  productWeight: dimensionNumberMeta({
    ...getBatMetadataProperties(
      'consumer_goods_textile_product_use_phase',
      'product_weight'
    ),
    fieldFamilyMessageDescriptor: PRODUCT_FAMILY,
  }),
  productWeightUnit: dimensionStringMeta({
    ...getBatMetadataProperties(
      'consumer_goods_textile_product_use_phase',
      'product_weight_unit'
    ),
    shouldSentenceCaseValues: false,
    fieldFamilyMessageDescriptor: PRODUCT_FAMILY,
  }),

  // Employees
  employeeRemoteTimePercent: dimensionNumberMeta({
    ...getBatMetadataProperties('employees', 'employee_remote_time_percent'),
    fieldFamilyMessageDescriptor: EMPLOYEE_FAMILY,
  }),
  employeeFullTimeEquivalent: dimensionNumberMeta({
    ...getBatMetadataProperties('employees', 'employee_full_time_equivalent'),
    fieldFamilyMessageDescriptor: EMPLOYEE_FAMILY,
  }),
  commuteDistanceUnit: dimensionStringMeta({
    ...getBatMetadataProperties('employees', 'commute_distance_unit'),
    fieldFamilyMessageDescriptor: EMPLOYEE_FAMILY,
  }),
  commuteBikeDistance: dimensionNumberMeta({
    ...getBatMetadataProperties('employees', 'commute_bike_distance'),
    fieldFamilyMessageDescriptor: EMPLOYEE_FAMILY,
  }),
  commuteCarDistance: dimensionNumberMeta({
    ...getBatMetadataProperties('employees', 'commute_car_distance'),
    fieldFamilyMessageDescriptor: EMPLOYEE_FAMILY,
  }),
  commuteTransitDistance: dimensionNumberMeta({
    ...getBatMetadataProperties('employees', 'commute_transit_distance'),
    fieldFamilyMessageDescriptor: EMPLOYEE_FAMILY,
  }),
  commuteWalkDistance: dimensionNumberMeta({
    ...getBatMetadataProperties('employees', 'commute_walk_distance'),
    fieldFamilyMessageDescriptor: EMPLOYEE_FAMILY,
  }),
  commuteBikePercent: dimensionNumberMeta({
    ...getBatMetadataProperties('employees', 'commute_bike_percent'),
    fieldFamilyMessageDescriptor: EMPLOYEE_FAMILY,
  }),
  commuteCarPercent: dimensionNumberMeta({
    ...getBatMetadataProperties('employees', 'commute_car_percent'),
    fieldFamilyMessageDescriptor: EMPLOYEE_FAMILY,
  }),
  commuteTransitPercent: dimensionNumberMeta({
    ...getBatMetadataProperties('employees', 'commute_transit_percent'),
    fieldFamilyMessageDescriptor: EMPLOYEE_FAMILY,
  }),
  commuteWalkPercent: dimensionNumberMeta({
    ...getBatMetadataProperties('employees', 'commute_walk_percent'),
    fieldFamilyMessageDescriptor: EMPLOYEE_FAMILY,
  }),
};

export const BI_DIMENSION_FIELD_METAS = Object.fromEntries(
  Object.entries(BI_DIMENSION_FIELDS_NO_ID).map(([fieldId, meta]) => [
    fieldId,
    { fieldId, ...meta },
  ])
) as unknown as {
  [K in keyof typeof BI_DIMENSION_FIELDS_NO_ID]: Collapse<
    Pick<
      (typeof BI_DIMENSION_FIELDS_NO_ID)[K] & {
        fieldId: K;
      },
      FieldMetaTypeConditioningProperties
    >
  >;
};

export type KnownDimensionFieldIds = keyof typeof BI_DIMENSION_FIELD_METAS;

/**
 * Assumes the following defaults:
 * type: string
 * Please use getDimensionMeta to access any values from this record
 * as there is a step that happens within that function to translate
 * field family and display name.
 */
export const BI_DIMENSION_FIELDS: Record<
  string,
  Omit<
    BiDimensionFieldMeta,
    'fieldId' | 'displayName' | 'fieldFamily' | 'description'
  > & {
    displayNameMessageDescriptor: MessageDescriptor;
    descriptionMessageDescriptor?: MessageDescriptor;
    fieldFamilyMessageDescriptor?: MessageDescriptor;
    unitUserFacingMessageDescriptor?: MessageDescriptor;
    staticListOptionsProvider?: (
      i18n: I18n
    ) => BiStringFieldMeta['staticListOptions'];
  }
> = BI_DIMENSION_FIELDS_NO_ID;

export const BiChartTimeDimensions = z.enum([
  'month',
  'calendarYear',
  'fiscalYear',
]);
export type BiChartTimeDimensionType = z.infer<typeof BiChartTimeDimensions>;
export const BiChartTimeGranularity = z.enum(['month', 'year', 'fiscalYear']);
export type BiChartTimeDimensionMeta = {
  timeGranularity: z.infer<typeof BiChartTimeGranularity>;
};

export const BI_SUPPORTED_TIME_DIMENSIONS: Record<
  BiChartTimeDimensionType,
  BiChartTimeDimensionMeta
> = {
  month: {
    timeGranularity: 'month',
  },
  calendarYear: {
    timeGranularity: 'year',
  },
  fiscalYear: {
    timeGranularity: 'fiscalYear',
  },
};

export function isValidBiTimeDimension(
  dim: string
): dim is BiChartTimeDimensionType {
  return Object.values(BiChartTimeDimensions.Values).includes(
    dim as BiChartTimeDimensionType
  );
}

/**
 * Note: Metrics families map very closely to the
 * field meta groups we have above.
 *
 * We alias them out here to start to give us some
 * flexibility to explicitly see groupings we use for
 * metrics (aka measures), but it's a fair
 * consideration to see if we want to completely merge.
 *
 * https://www.notion.so/watershedclimate/Metrics-list-81ed2a3788c24d7b89488ba9968d8c8b?pvs=4
 */
export const METRICS_FAMILY = {
  CUSTOM: msg({
    message: 'Custom',
    context: 'The grouping for a collection of metrics',
  }),
  SOCIAL: msg({
    message: 'Social',
    context: 'The grouping for a collection of metrics',
  }),
  GOVERNANCE: msg({
    message: 'Governance',
    context: 'The grouping for a collection of metrics',
  }),
  EMISSIONS: msg({
    message: 'Emissions',
    context: 'The grouping for a collection of metrics',
  }),
  ENERGY: msg({
    message: 'Energy',
    context: 'The grouping for a collection of metrics',
  }),
  ACTIVITY: msg({
    message: 'Activity',
    context: 'The grouping for a collection of metrics',
  }),
  POLLUTION: msg({
    message: 'Pollution',
    context: 'The grouping for a collection of metrics',
  }),
  PRODUCT: msg({
    message: 'Product',
    context: 'The grouping for a collection of metrics',
  }),
  WATER: msg({
    message: 'Water',
    context: 'The grouping for a collection of metrics',
  }),
  WASTE: msg({
    message: 'Waste',
    context: 'The grouping for a collection of metrics',
  }),
  SCOPE_3_15: msg({
    message: 'Scope 3.15',
    context: 'The grouping for a collection of metrics',
  }),
  OTHER: msg({
    message: 'Other',
    context: 'The grouping for a collection of metrics',
  }),
};
const BI_MEASURE_FIELDS_MAP_WITHOUT_ID = {
  // BEGIN FOOTPRINT FIELDS

  kgco2eQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Emissions: market-based',
      context: 'The display name for a metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.EMISSIONS,
  }),
  kgco2eLocationQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Emissions: location-based',
      context: 'The display name for a metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.EMISSIONS,
  }),
  originalTco2eQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Original Emissions: market-based',
      context: 'The display name for a metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.EMISSIONS,
  }),
  tco2eQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Emissions: market-based',
      context: 'The display name for a metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.EMISSIONS,
  }),
  tco2eLocationQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Emissions: location-based',
      context: 'The display name for a metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.EMISSIONS,
  }),
  kgco2eBiogenicCo2Quantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Emissions: market-based biogenic CO₂',
      context: 'The display name for a metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.EMISSIONS,
  }),
  tco2eBiogenicCo2Quantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Emissions: market-based biogenic CO₂',
      context: 'The display name for a metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.EMISSIONS,
  }),
  kgco2eBiogenicCo2LocationQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Emissions: location-based biogenic CO₂',
      context: 'The display name for a metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.EMISSIONS,
  }),
  tco2eBiogenicCo2LocationQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Emissions: location-based biogenic CO₂',
      context: 'The display name for a metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.EMISSIONS,
  }),
  kgco2eAvoidedQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Emissions: avoided',
      context: 'The display name for a metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.EMISSIONS,
  }),
  tco2eAvoidedQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Emissions: avoided',
      context: 'The display name for a metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.EMISSIONS,
  }),
  kgco2eEmissionsPlusAvoidedEmissionsQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Emissions: market-based plus avoided',
      context: 'The display name for a metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.EMISSIONS,
  }),
  tco2eEmissionsPlusAvoidedEmissionsQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Emissions: market-based plus avoided',
      context: 'The display name for a metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.EMISSIONS,
  }),
  mwhConsumed: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Energy consumption',
      context: 'The display name for a metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.ENERGY,
  }),
  // ORIGINAL quantity columns pre-de-duplication
  originalMwhConsumed: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Original Energy consumption',
      context: 'The display name for a metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.ENERGY,
  }),
  originalTco2eAvoidedQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Original Emissions: avoided',
      context: 'The display name for a metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.EMISSIONS,
  }),
  originalTco2eBiogenicCo2Quantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Original Emissions: market-based biogenic CO₂',
      context: 'The display name for a metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.EMISSIONS,
  }),
  originalTco2eBiogenicCo2LocationQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Original Emissions: location-based biogenic CO₂',
      context: 'The display name for a metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.EMISSIONS,
  }),
  originalLitersWaterUsedQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Original Water withdrawal volume',
      context: 'The display name for a metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.WATER,
  }),
  originalLitersWaterRecycledQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Original Water recycling volume',
      context: 'The display name for a metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.WATER,
  }),
  originalLitersWaterConsumedQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Original Water consumption volume',
      context: 'The display name for a metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.WATER,
  }),
  originalLitersWaterStoredQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Original Water storage volume',
      context: 'The display name for a metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.WATER,
  }),
  originalLitersWaterDischargedQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Original Water discharge volume',
      context: 'The display name for a metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.WATER,
  }),
  originalKgSubstanceInflowQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Original Substance inflow',
      context: 'The display name for a metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.POLLUTION,
  }),
  originalKgSubstanceOutflowQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Original Substance outflow',
      context: 'The display name for a metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.POLLUTION,
  }),
  originalKgMicroplasticsInflowQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Original Microplastics inflow',
      context: 'The display name for a metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.POLLUTION,
  }),
  originalKgMicroplasticsOutflowQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Original Microplastics outflow',
      context: 'The display name for a metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.POLLUTION,
  }),
  originalKgWasteQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Original Waste',
      context: 'The display name for a metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.WASTE,
  }),
  /** [DO NOT USE] This is for #incident-may-20-2024-electricity-deduplication-incorrect
   *  remediation and will be removed. */
  mwhConsumedWithoutDeduplication: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Energy consumption (without deduplication)',
      context: 'The display name for a metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.ENERGY,
  }),
  kgWasteQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Waste',
      context: 'The display name for a metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.WASTE,
  }),
  kgMicroplasticsInflowQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Microplastics inflow',
      context: 'The display name for a metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.POLLUTION,
  }),
  kgMicroplasticsOutflowQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Microplastics outflow',
      context: 'The display name for a metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.POLLUTION,
  }),
  kgSubstanceInflowQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Pollution inflow',
      context: 'The display name for a metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.POLLUTION,
  }),
  kgSubstanceOutflowQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Pollution outflow',
      context: 'The display name for a metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.POLLUTION,
  }),
  litersWaterUsedQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Water withdrawal volume',
      context: 'The display name for a metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.WATER,
  }),
  litersWaterConsumedQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Water consumption volume',
      context: 'The display name for a metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.WATER,
  }),
  litersWaterStoredQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Water storage volume',
      context: 'The display name for a metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.WATER,
  }),
  litersWaterRecycledQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Water recycling volume',
      context: 'The display name for a metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.WATER,
  }),
  litersWaterDischargedQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Water discharge volume',
      context: 'The display name for a metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.WATER,
  }),
  measuredMicroplasticsOutflowQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Microplastics outflow',
      context: 'The display name for a metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.POLLUTION,
  }),
  measuredSubstanceOutflowQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Substance outflow',
      context: 'The display name for a metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.POLLUTION,
  }),
  customQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Custom quantity',
      context: 'The display name for a metric',
    }),
  }),
  ...legacyIntensities,

  // #region Scope 3.15 footprint fields - used by WS Finance
  totalAssetTco2eQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Total asset emissions',
      context: 'The display name for a metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.SCOPE_3_15,
  }),
  pcafDataQualityScoreMeasure: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'PCAF data quality score',
      context: 'The display name for a metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.SCOPE_3_15,
  }),
  weightedAverageCarbonIntensity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'WACI (weighted average carbon intensity)',
      context: 'The display name for a metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.SCOPE_3_15,
  }),
  revenueIntensity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Revenue intensity',
      context: 'The display name for a metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.SCOPE_3_15,
  }),
  economicIntensity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Economic intensity',
      context: 'The display name for a metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.SCOPE_3_15,
  }),
  assetValue: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Asset value',
      context: 'The display name for a metric',
    }),
  }),
  revenueValue: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Revenue',
      context: 'The display name for a metric',
    }),
  }),
  // #endregion Scope 3.15 footprint fields - used by WS Finance

  // END FOOTPRINT FIELDS

  // BEGIN ACTIVITY DATA FIELDS

  buildingAreaQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Facility area',
      context: 'The display name for a metric',
    }),
    descriptionMessageDescriptor: msg({
      message: 'The area of the facility.',
      context: 'The description name for a metric',
    }),
  }),
  // FP-5204: This is a separate measure because we want the main measure
  // to be sq ft but we want the intensity measure to be able to use thousands
  // of square feet. Someday, with custom measure intensities and specifying units
  // we can hopefully get rid of this.
  buildingAreaScaledQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Facility area',
      context: 'The display name for a metric',
    }),
    descriptionMessageDescriptor: msg({
      message: 'The area of the facility in thousands of square feet.',
      context: 'The description name for a metric',
    }),
    divideBy: DIVIDE_BY.buildingAreaScaledQuantity,
    unit: 'square_feet',
    unitUserFacingMessageDescriptor: msg({
      message: '1,000 sq ft',
      comment: 'Label for unit in thousands of square feet',
    }),
  }),
  fuelQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Fuel consumption',
      context: 'The display name for a metric',
    }),
  }),
  electricityQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Electricity consumption',
      context: 'The display name for a metric',
    }),
  }),
  wasteQuantity: createMeasureFromBatMetadata('building_waste', 'quantity'),
  employeeQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Headcount',
      context: 'The display name for a metric',
    }),
    descriptionMessageDescriptor: msg({
      message: 'The number of employees.',
      context: 'The description name for a metric',
    }),
  }),

  buildingUniqueIdentifierCount: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Facilities count',
      context: 'The display name for a metric',
    }),
    descriptionMessageDescriptor: msg({
      message: 'The number of facilities.',
      context: 'The description name for a metric',
    }),
    gatingFeatureFlag: GQFlags.BiDrilldownFacilitiesMeasures,
  }),

  vendorCount: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Supplier count',
      context: 'The display name for a metric',
    }),
    descriptionMessageDescriptor: msg({
      message: 'The number of suppliers.',
      context: 'The description name for a metric',
    }),
    gatingFeatureFlag: GQFlags.BiDrilldownSupplierMeasures,
  }),

  waterRecyclingQuantity: createMeasureFromBatMetadata(
    'water_recycling',
    'quantity',
    {
      fieldFamilyMessageDescriptor: METRICS_FAMILY.WATER,
    }
  ),
  waterUsageQuantity: createMeasureFromBatMetadata('water_usage', 'quantity', {
    fieldFamilyMessageDescriptor: METRICS_FAMILY.WATER,
  }),
  waterStorageQuantity: createMeasureFromBatMetadata(
    'water_storage',
    'quantity',
    {
      fieldFamilyMessageDescriptor: METRICS_FAMILY.WATER,
    }
  ),
  waterConsumptionQuantity: createMeasureFromBatMetadata(
    'water_consumption',
    'quantity',
    {
      fieldFamilyMessageDescriptor: METRICS_FAMILY.WATER,
    }
  ),
  waterDischargeQuantity: createMeasureFromBatMetadata(
    'water_discharge',
    'quantity',
    {
      fieldFamilyMessageDescriptor: METRICS_FAMILY.WATER,
    }
  ),

  // Note: This same field is re-used across multiple BATs
  materialsQuantity: createMeasureFromBatMetadata(
    'consumer_goods_packaging_material',
    'quantity'
  ),
  productsSoldQuantity: createMeasureFromBatMetadata(
    'waste_products',
    'products_sold_quantity'
  ),
  productsSoldQuantityLifetimeUse: createMeasureFromBatMetadata(
    'lifetime_fuel_and_electricity_use_of_sold_products',
    'quantity'
  ),
  quantityComponentWeightProduced: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Products produced weight',
      context: 'The display name for a metric',
    }),
  }),
  // There are three "Product count" fields on different BATs. Including them here
  // with different names avoids conflating the fields
  productCount: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Product count',
      context: 'The display name for a metric',
    }),
  }),
  productCountWaste: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Product count',
      context: 'The display name for a metric',
    }),
  }),
  productCountLifetimeUse: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Product count',
      context: 'The display name for a metric',
    }),
  }),
  currencyQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Spend',
      context: 'The display name for a metric',
    }),
  }),
  siteAreaQuantity: createMeasureFromBatMetadata('site_land_use', 'quantity'),
  siteCount: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Site count',
      context: 'The display name for a metric',
    }),
  }),
  distanceQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Distance',
      context: 'The display name for a metric',
    }),
    descriptionMessageDescriptor: msg({
      message:
        'Distance is the measure of how far a vehicle traveled. Trip distance is multiplied by passenger quantity to get the total distance.',
      context: 'The description for distance metric',
    }),
  }),
  massQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Weight',
      context: 'The display name for a metric',
    }),
  }),
  passengerQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Passenger quantity',
      context: 'The display name for a metric',
    }),
  }),
  tripQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Trip quantity',
      context: 'Name of a metric that describes the number of trips taken',
    }),
  }),
  lodgingUnitQuantity: createMeasureFromBatMetadata(
    'lodging',
    'lodging_unit_quantity'
  ),
  nightsStayed: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Nights',
      context: 'The display name for a metric',
    }),
  }),
  assetRevenueQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Asset revenue',
      context:
        'The display name for a metric describing the revenue of another company that has been invested in.',
    }),
    divideBy: DIVIDE_BY['assetRevenueQuantity'],
    fieldFamilyMessageDescriptor: METRICS_FAMILY.ACTIVITY,
    isCurrency: true,
  }),
  revenueQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Revenue',
      context: 'The display name for a metric',
    }),
    divideBy: DIVIDE_BY['revenueQuantity'],
    fieldFamilyMessageDescriptor: METRICS_FAMILY.ACTIVITY,
    isCurrency: true,
  }),
  grossProfitQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Gross profit',
      context: 'The display name for a metric',
    }),
    divideBy: DIVIDE_BY['grossProfitQuantity'],
    isCurrency: true,
  }),
  netRevenueQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Net revenue',
      context: 'The display name for a metric',
    }),
    divideBy: DIVIDE_BY['netRevenueQuantity'],
    isCurrency: true,
  }),
  operationalRevenueQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Operational revenue',
      context: 'The display name for a metric',
    }),
    divideBy: DIVIDE_BY['operationalRevenueQuantity'],
    isCurrency: true,
  }),
  gmvQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'GMV',
      context: 'The display name for a metric',
    }),
    divideBy: DIVIDE_BY['gmvQuantity'],
    isCurrency: true,
  }),
  supplierSpendQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Supplier spend',
      context: 'The display name for a metric',
    }),
    divideBy: DIVIDE_BY['supplierSpendQuantity'],
    isCurrency: true,
  }),
  // custom intensity denominators
  nightsStayedQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Nights stayed',
      context: 'The display name for a metric',
    }),
    unitUserFacingMessageDescriptor: msg({
      message: 'Nights',
      context: 'Label for unit for metric',
    }),
    divideBy: DIVIDE_BY['nightsStayedQuantity'],
    fieldFamilyMessageDescriptor: METRICS_FAMILY.ACTIVITY,
    isCurrency: false,
  }),
  megawattsQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'MW',
      context: 'The display name for a metric',
    }),
    unitUserFacingMessageDescriptor: msg({
      message: 'Megawatt',
      context: 'Label for unit for metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.ACTIVITY,
    isCurrency: false,
  }),
  monthlyActiveUsersQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Monthly active users',
      context: 'The display name for a metric',
    }),
    divideBy: DIVIDE_BY['monthlyActiveUsersQuantity'],
    unitUserFacingMessageDescriptor: msg({
      message: 'MAUs (millions)',
      context: 'Label for unit for metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.ACTIVITY,
    isCurrency: false,
  }),
  ordersQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Orders',
      context: 'The display name for a metric',
    }),
    divideBy: DIVIDE_BY['ordersQuantity'],
    unitUserFacingMessageDescriptor: msg({
      message: 'orders (1000s)',
      context: 'Label for unit for metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.ACTIVITY,
    isCurrency: false,
  }),
  ordersKgQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Orders (kg)',
      context: 'The display name for a metric',
    }),
    unitUserFacingMessageDescriptor: msg({
      message: 'Kg orders',
      context: 'Label for unit for metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.ACTIVITY,
    isCurrency: false,
  }),
  merchantsQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Merchants',
      context: 'The display name for a metric',
    }),
    divideBy: DIVIDE_BY['merchantsQuantity'],
    unitUserFacingMessageDescriptor: msg({
      message: 'merchants (1000s)',
      context: 'Label for unit for metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.ACTIVITY,
    isCurrency: false,
  }),
  patientsQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Patients',
      context: 'The display name for a metric',
    }),
    divideBy: DIVIDE_BY['patientsQuantity'],
    unitUserFacingMessageDescriptor: msg({
      message: 'patients (1000s)',
      context: 'Label for unit for metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.ACTIVITY,
    isCurrency: false,
  }),
  payingSitesUnderManagementQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Paying sites under management',
      context: 'The display name for a metric',
    }),
    divideBy: DIVIDE_BY['payingSitesUnderManagementQuantity'],
    unitUserFacingMessageDescriptor: msg({
      message: 'PSUM (1000s)',
      context: 'Label for unit for metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.ACTIVITY,
    isCurrency: false,
  }),
  shipmentsQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Shipments',
      context: 'The display name for a metric',
    }),
    divideBy: DIVIDE_BY['shipmentsQuantity'],
    unitUserFacingMessageDescriptor: msg({
      message: 'shipments (1000s)',
      context: 'Label for unit for metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.ACTIVITY,
    isCurrency: false,
  }),
  unitsQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Units',
      context: 'The display name for a metric',
    }),
    divideBy: DIVIDE_BY['unitsQuantity'],
    unitUserFacingMessageDescriptor: msg({
      message: 'units (1000s)',
      context: 'Label for unit for metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.ACTIVITY,
    isCurrency: false,
  }),
  factoriesQuantity: createMeasureFromBatMetadata(
    'tier_1_factories_activity_data',
    'quantity'
  ),
  factoryElectricityQuantity: createMeasureFromBatMetadata(
    'tier_1_factories_activity_data',
    'electricity_mj'
  ),
  factoryWasteQuantity: createMeasureFromBatMetadata(
    'tier_1_factories_activity_data',
    'waste_kg'
  ),
  factoryWaterQuantity: createMeasureFromBatMetadata(
    'tier_1_factories_activity_data',
    'water_liters'
  ),
  factoryBiodieselFuelQuantity: createMeasureFromBatMetadata(
    'tier_1_factories_activity_data',
    'biodiesel_fuel_mj'
  ),
  factoryBiomassNonWoodQuantity: createMeasureFromBatMetadata(
    'tier_1_factories_activity_data',
    'biomass_non_wood_mj'
  ),
  factoryBiomassWoodQuantity: createMeasureFromBatMetadata(
    'tier_1_factories_activity_data',
    'biomass_wood_mj'
  ),
  factoryCoalQuantity: createMeasureFromBatMetadata(
    'tier_1_factories_activity_data',
    'coal_mj'
  ),
  factoryDieselFuelQuantity: createMeasureFromBatMetadata(
    'tier_1_factories_activity_data',
    'diesel_fuel_mj'
  ),
  factoryDistrictHeatQuantity: createMeasureFromBatMetadata(
    'tier_1_factories_activity_data',
    'district_heat_mj'
  ),
  factoryFuelOilQuantity: createMeasureFromBatMetadata(
    'tier_1_factories_activity_data',
    'fuel_oil_mj'
  ),
  factoryGasolineQuantity: createMeasureFromBatMetadata(
    'tier_1_factories_activity_data',
    'gasoline_mj'
  ),
  factoryLiquifiedNaturalGasQuantity: createMeasureFromBatMetadata(
    'tier_1_factories_activity_data',
    'liquified_natural_gas_mj'
  ),
  factoryLiquifiedPetroleumGasQuantity: createMeasureFromBatMetadata(
    'tier_1_factories_activity_data',
    'liquified_petroleum_gas_mj'
  ),
  factoryNaturalGasQuantity: createMeasureFromBatMetadata(
    'tier_1_factories_activity_data',
    'natural_gas_mj'
  ),

  factoryPropaneQuantity: createMeasureFromBatMetadata(
    'tier_1_factories_activity_data',
    'propane_mj'
  ),
  factorySteamQuantity: createMeasureFromBatMetadata(
    'tier_1_factories_activity_data',
    'steam_mj'
  ),
  instanceHours: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Cloud instance hours',
      context: 'The display name for a metric',
    }),
  }),
  reportCount: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Workplace report count',
      context: 'The display name for a metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.SOCIAL,
  }),
  monetaryRemediationQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Workplace report remediation spend',
      context: 'The display name for a metric',
    }),
    isCurrency: true,
    fieldFamilyMessageDescriptor: METRICS_FAMILY.SOCIAL,
  }),
  lobbyingCurrencyQuantity: measureMeta({
    fieldFamilyMessageDescriptor: METRICS_FAMILY.GOVERNANCE,
    displayNameMessageDescriptor: msg({
      message: 'Lobbying spend',
      context: 'The display name for a metric',
    }),
    divideBy: DIVIDE_BY.lobbyingCurrencyQuantity,
    isCurrency: true,
  }),
  antiBriberyAndCorruptionCurrencyQuantity: measureMeta({
    fieldFamilyMessageDescriptor: METRICS_FAMILY.GOVERNANCE,
    displayNameMessageDescriptor: msg({
      message: 'Anti-bribery and corruption monetary remediation spend',
      context: 'The display name for a metric',
    }),
    divideBy: DIVIDE_BY.antiBriberyAndCorruptionCurrencyQuantity,
    isCurrency: true,
  }),
  antiBriberyAndCorruptionIncidentCount: measureMeta({
    fieldFamilyMessageDescriptor: METRICS_FAMILY.GOVERNANCE,
    displayNameMessageDescriptor: msg({
      message: 'Anti-bribery and corruption incident count',
      context: 'The display name for a metric',
    }),
  }),
  severeHumanRightsIncidentCurrencyQuantity: measureMeta({
    fieldFamilyMessageDescriptor: METRICS_FAMILY.SOCIAL,
    displayNameMessageDescriptor: msg({
      message: 'Severe human rights monetary remediation spend',
      context: 'The display name for a metric',
    }),
    divideBy: DIVIDE_BY.severeHumanRightsIncidentCurrencyQuantity,
    isCurrency: true,
  }),
  severeHumanRightsIncidentCount: measureMeta({
    fieldFamilyMessageDescriptor: METRICS_FAMILY.SOCIAL,
    displayNameMessageDescriptor: msg({
      message: 'Severe human rights incident count',
      context: 'The display name for a metric',
    }),
  }),
  healthAndSafetyIncidentCount: measureMeta({
    fieldFamilyMessageDescriptor: METRICS_FAMILY.SOCIAL,
    isCurrency: false,
    displayNameMessageDescriptor: msg({
      message: 'Health and safety incident count',
      context: 'The display name for a metric',
    }),
  }),
  paymentOrderCount: measureMeta({
    fieldFamilyMessageDescriptor: METRICS_FAMILY.SOCIAL,
    displayNameMessageDescriptor: msg({
      message: 'Payment order count',
      context: 'The display name for a metric',
    }),
  }),
  daysFromTermStartToPayment: measureMeta({
    fieldFamilyMessageDescriptor: METRICS_FAMILY.SOCIAL,
    displayNameMessageDescriptor: msg({
      message: 'Days from term start to payment',
      context: 'The display name for a metric',
      comment:
        "The number of days from the payment order's term start to the actual payment.",
    }),
  }),
  workDaysLostCount: measureMeta({
    fieldFamilyMessageDescriptor: METRICS_FAMILY.SOCIAL,
    displayNameMessageDescriptor: msg({
      message: 'Work days lost count',
      context: 'The display name for a metric',
    }),
  }),
  fatalityCount: measureMeta({
    fieldFamilyMessageDescriptor: METRICS_FAMILY.SOCIAL,
    displayNameMessageDescriptor: msg({
      message: 'Fatalities count',
      context: 'The display name for a metric',
    }),
  }),

  kgco2eRetiredQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Emissions: retired',
      context: 'The display name for a metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.EMISSIONS,
  }),
  tco2eRetiredQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Emissions: retired',
      context: 'The display name for a metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.EMISSIONS,
  }),
  productQuantity: createMeasureFromBatMetadata(
    'consumer_goods_textile_product_use_phase',
    'quantity'
  ),
  workersCount: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Workers count',
      context: 'The display name for a metric',
      comment:
        'A count of all the workers in the workforce (employees and non-employees)',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.SOCIAL,
  }),
  workersCoveredUnderCollectiveBargainingCount: createMeasureFromBatMetadata(
    'workers_social',
    'num_covered_under_collective_bargaining',
    {
      fieldFamilyMessageDescriptor: METRICS_FAMILY.SOCIAL,
    }
  ),
  workersCoveredByWorkersRepresentationCount: createMeasureFromBatMetadata(
    'workers_social',
    'num_covered_by_workers_representation',
    {
      fieldFamilyMessageDescriptor: METRICS_FAMILY.SOCIAL,
    }
  ),
  workersPaidBelowAdequateWageCount: createMeasureFromBatMetadata(
    'workers_social',
    'num_paid_below_adequate_wage',
    {
      fieldFamilyMessageDescriptor: METRICS_FAMILY.SOCIAL,
    }
  ),
  workersWeightedTotalAverageTrainingHours: measureMeta({
    displayNameMessageDescriptor: msg({
      message: "Workers' weighted total average training hours",
      context: 'The display name for a metric',
      comment:
        'The average number of training hours for a group of workers, multiplied by the number of workers in the group.',
    }),
  }),
  workersWeightedTotalAverageHourlyWage: measureMeta({
    displayNameMessageDescriptor: msg({
      message: "Workers' weighted total average hourly wage",
      context: 'The display name for a metric',
      comment:
        'The average hourly wage for a group of workers, multiplied by the number of workers in the group.',
    }),
  }),
  workersWithDisabilitiesCount: createMeasureFromBatMetadata(
    'workers_social',
    'num_with_disabilities',
    {
      fieldFamilyMessageDescriptor: METRICS_FAMILY.SOCIAL,
    }
  ),
  workersEntitledToFamilyRelatedLeaveCount: createMeasureFromBatMetadata(
    'workers_social',
    'num_entitled_to_take_family_related_leave',
    {
      fieldFamilyMessageDescriptor: METRICS_FAMILY.SOCIAL,
    }
  ),
  workersTakenFamilyRelatedLeaveCount: createMeasureFromBatMetadata(
    'workers_social',
    'num_taken_family_related_leave',
    {
      fieldFamilyMessageDescriptor: METRICS_FAMILY.SOCIAL,
    }
  ),
  workersTerminatedContractCount: createMeasureFromBatMetadata(
    'workers_social',
    'quantity_terminated_contract',
    {
      fieldFamilyMessageDescriptor: METRICS_FAMILY.SOCIAL,
    }
  ),
  workersHoursWorkedQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Hours worked',
      context: 'The display name for a metric',
      comment: 'Total hours worked by the workforce',
    }),
    divideBy: DIVIDE_BY.workersHoursWorkedQuantity,
    unitUserFacingMessageDescriptor: msg({
      message: 'Hours (1M)',
      context: 'Label for unit for metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.SOCIAL,
  }),
  workersParticipatedInPerformanceReviewsCount: createMeasureFromBatMetadata(
    'workers_social',
    'num_participated_in_reviews',
    {
      fieldFamilyMessageDescriptor: METRICS_FAMILY.SOCIAL,
    }
  ),
  workersCoveredByHealthAndSafetyManagementSystemCount:
    createMeasureFromBatMetadata(
      'workers_social',
      'num_covered_by_health_and_safety_management_system',
      {
        fieldFamilyMessageDescriptor: METRICS_FAMILY.SOCIAL,
      }
    ),
  vehicleQuantity: createMeasureFromBatMetadata(
    'sold_vehicle',
    'vehicle_quantity'
  ),
  ghgRemovalQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Emissions: removed',
      context: 'The display name for a metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.EMISSIONS,
  }),
  ghgRemovalEmissionsQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Emissions: from removal',
      context: 'The display name for a metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.EMISSIONS,
  }),
  ghgReversalsQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Emissions: reversed',
      context: 'The display name for a metric',
    }),
    fieldFamilyMessageDescriptor: METRICS_FAMILY.EMISSIONS,
  }),

  // From here down should be internal fields that are here mostly for TS typing, they won't be exposed to the UI
  kwhConsumed: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Energy consumption',
      context: 'The display name for a metric',
      comment: '@skip',
    }),
    isInternal: true,
  }),
  // BEGIN these actually *have* to be marked internal because they don't have units and will error in marta otherwise
  inputQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Input Quantity',
      context: 'The display name for a metric',
      comment: '@skip',
    }),
    isInternal: true,
  }),
  convertedQuantity: measureMeta({
    displayNameMessageDescriptor: msg({
      message: 'Converted Quantity',
      context: 'The display name for a metric',
      comment: '@skip',
    }),
    isInternal: true,
  }),
  // END these actually *have* to be marked internal because they don't have units and will error in marta otherwise

  // END ACTIVITY DATA FIELDS
};

export const BI_MEASURE_FIELD_METAS = Object.fromEntries(
  Object.entries(BI_MEASURE_FIELDS_MAP_WITHOUT_ID).map(([fieldId, meta]) => [
    fieldId,
    { fieldId, ...meta },
  ])
) as unknown as {
  [K in keyof typeof BI_MEASURE_FIELDS_MAP_WITHOUT_ID]: Collapse<
    Pick<
      (typeof BI_MEASURE_FIELDS_MAP_WITHOUT_ID)[K] & {
        fieldId: K;
      },
      FieldMetaTypeConditioningProperties
    >
  >;
};

export type KnownMeasureFieldIds = keyof typeof BI_MEASURE_FIELD_METAS;

/**
 * Please get field metadata through the BI metadata interface which
 * merges data from here with data from the marta schema. Do not read
 * directly from this constant.
 */
export const BI_MEASURE_FIELDS: Record<
  string,
  Omit<BiMeasureFieldMeta, 'fieldId' | 'displayName' | 'description'> & {
    displayNameMessageDescriptor: MessageDescriptor;
    descriptionMessageDescriptor?: MessageDescriptor;
    unitUserFacingMessageDescriptor?: MessageDescriptor;
    fieldFamilyMessageDescriptor?: MessageDescriptor;
  }
> = BI_MEASURE_FIELDS_MAP_WITHOUT_ID;

/**
 * Please get field metadata through the BI metadata interface which
 * merges data from here with data from the marta schema. Do not
 * call this function directly unless you know what you're doing.
 */
export function getMeasureMeta(
  measure: string
): BiMeasureFieldMeta & { isInternal?: true } {
  const biMeasureMeta = BI_MEASURE_FIELDS[measure];
  if (biMeasureMeta) {
    const {
      descriptionMessageDescriptor,
      displayNameMessageDescriptor,
      unitUserFacingMessageDescriptor,
      fieldFamily,
      fieldFamilyMessageDescriptor,
    } = biMeasureMeta;
    const translatedDisplayName = i18n.t(displayNameMessageDescriptor);
    const possiblyTranslatedDescription = descriptionMessageDescriptor
      ? i18n.t(descriptionMessageDescriptor)
      : undefined;

    const possiblyTranslatedUnitUserFacing = unitUserFacingMessageDescriptor
      ? i18n.t(unitUserFacingMessageDescriptor)
      : undefined;
    const possiblyTranslatedFieldFamily = fieldFamilyMessageDescriptor
      ? i18n.t(fieldFamilyMessageDescriptor)
      : fieldFamily;
    return {
      ...omit(biMeasureMeta, 'displayName', 'unitUserFacing', 'description'),
      fieldId: measure,
      description: possiblyTranslatedDescription,
      displayName: translatedDisplayName,
      unitUserFacing: possiblyTranslatedUnitUserFacing,
      fieldFamily: possiblyTranslatedFieldFamily,
    };
  }
  return Object.assign(
    {
      fieldId: measure,
      displayName: measure,
    },
    MEASURE_META_DEFAULTS
  );
}

export function getDimensionMeta(
  dimension: string,
  scopedI18n: I18n = i18n
): BiDimensionFieldMeta & { isInternal?: true } {
  const biDimensionMeta = BI_DIMENSION_FIELDS[dimension];
  if (biDimensionMeta) {
    const {
      displayNameMessageDescriptor,
      descriptionMessageDescriptor,
      fieldFamilyMessageDescriptor,
      staticListOptionsProvider,
    } = biDimensionMeta;

    const staticListOptions = staticListOptionsProvider
      ? staticListOptionsProvider(scopedI18n)
      : undefined;

    const translatedDisplayName = scopedI18n.t(displayNameMessageDescriptor);
    const possiblyTranslatedDescription = descriptionMessageDescriptor
      ? scopedI18n.t(descriptionMessageDescriptor)
      : undefined;
    const fieldFamily = fieldFamilyMessageDescriptor
      ? scopedI18n.t(fieldFamilyMessageDescriptor)
      : undefined;
    return {
      fieldId: dimension,
      displayName: translatedDisplayName,
      description: possiblyTranslatedDescription,
      fieldFamily,
      staticListOptions,
      ...omit(
        biDimensionMeta,
        'displayName',
        'descriptionMessageDescriptor',
        'description',
        'displayNameMessageDescriptor',
        'fieldFamilyMessageDescriptor'
      ),
    };
  } else {
    return {
      ...DIMENSION_META_STRING_DEFAULTS,
      fieldId: dimension,
      displayName: dimension,
      fieldFamily: scopedI18n.t(OTHER_FAMILY),
    };
  }
}

export function getCustomDimensionMeta({
  fieldId,
  description,
  type,
  displayName,
  fieldFamily,
}: Pick<
  BiDimensionFieldMeta,
  'fieldId' | 'description' | 'type' | 'displayName' | 'fieldFamily'
>): BiDimensionFieldMeta {
  return {
    ...DIMENSION_META_STRING_DEFAULTS,
    fieldId,
    displayName,
    fieldFamily,
    type,
    description,
    isCustomField: true,
    shouldSentenceCaseValues: false,
  };
}

function dimensionStringMeta<
  M extends Partial<
    Omit<BiStringFieldMeta, 'fieldFamily' | 'staticListOptions'>
  > & {
    displayNameMessageDescriptor: MessageDescriptor;
    descriptionMessageDescriptor?: MessageDescriptor;
    fieldFamilyMessageDescriptor?: MessageDescriptor;
    unitUserFacingMessageDescriptor?: MessageDescriptor;
    staticListOptionsProvider?: (
      i18n: I18n
    ) => BiStringFieldMeta['staticListOptions'];
    isInternal?: true;
  },
>(meta: M): Collapse<Omit<typeof DIMENSION_META_STRING_DEFAULTS, keyof M> & M> {
  return { ...DIMENSION_META_STRING_DEFAULTS, ...meta };
}

function dimensionBooleanMeta<
  M extends Partial<Omit<BiBooleanFieldMeta, 'fieldFamily'>> & {
    displayNameMessageDescriptor: MessageDescriptor;
    descriptionMessageDescriptor?: MessageDescriptor;
    fieldFamilyMessageDescriptor?: MessageDescriptor;
    unitUserFacingMessageDescriptor?: MessageDescriptor;
    isInternal?: true;
  },
>(
  meta: M
): Collapse<Omit<typeof DIMENSION_META_BOOLEAN_DEFAULTS, keyof M> & M> {
  return { ...DIMENSION_META_BOOLEAN_DEFAULTS, ...meta };
}

function dimensionDateMeta<
  M extends Partial<Omit<BiDateFieldMeta, 'fieldFamily'>> & {
    displayNameMessageDescriptor: MessageDescriptor;
    descriptionMessageDescriptor?: MessageDescriptor;
    fieldFamilyMessageDescriptor?: MessageDescriptor;
    unitUserFacingMessageDescriptor?: MessageDescriptor;
    isInternal?: true;
  },
>(meta: M): Collapse<Omit<typeof DIMENSION_META_DATE_DEFAULTS, keyof M> & M> {
  return { ...DIMENSION_META_DATE_DEFAULTS, ...meta };
}

function dimensionYearMonthMeta<
  M extends Partial<Omit<BiYearMonthFieldMeta, 'fieldFamily'>> & {
    displayNameMessageDescriptor: MessageDescriptor;
    descriptionMessageDescriptor?: MessageDescriptor;
    fieldFamilyMessageDescriptor?: MessageDescriptor;
    unitUserFacingMessageDescriptor?: MessageDescriptor;
    isInternal?: true;
  },
>(
  meta: M
): Collapse<Omit<typeof DIMENSION_META_YEAR_MONTH_DEFAULTS, keyof M> & M> {
  return { ...DIMENSION_META_YEAR_MONTH_DEFAULTS, ...meta };
}

function dimensionNumberMeta<
  M extends Partial<Omit<BiNumberFieldMeta, 'fieldFamily'>> & {
    displayNameMessageDescriptor: MessageDescriptor;
    descriptionMessageDescriptor?: MessageDescriptor;
    fieldFamilyMessageDescriptor?: MessageDescriptor;
    unitUserFacingMessageDescriptor?: MessageDescriptor;
    isInternal?: true;
  },
>(meta: M): Collapse<Omit<typeof DIMENSION_META_NUMBER_DEFAULTS, keyof M> & M> {
  return { ...DIMENSION_META_NUMBER_DEFAULTS, ...meta };
}

function measureMeta<
  M extends Partial<
    Omit<BiMeasureFieldMeta, 'isMultiValue' | 'displayName' | 'description'>
  > & {
    displayNameMessageDescriptor: MessageDescriptor;
    descriptionMessageDescriptor?: MessageDescriptor;
    // As with displayName above, support a message descriptor
    fieldFamilyMessageDescriptor?: MessageDescriptor;
    isInternal?: true;
    unitUserFacingMessageDescriptor?: MessageDescriptor;
  },
>(meta: M): Collapse<Omit<typeof MEASURE_META_DEFAULTS, keyof M> & M> {
  return { ...MEASURE_META_DEFAULTS, ...meta };
}

function getBatMetadataProperties(
  batName: MetadataDefinedPrimaryTypes,
  fieldName: string
) {
  const meta = must(
    BatMetadata.getFieldMetadataMessageDescriptors(batName, fieldName),
    `No corresponding field '${fieldName}' found in BatMetadata for BAT '${batName}'`
  );
  return {
    displayNameMessageDescriptor: meta.displayName,
    descriptionMessageDescriptor: meta.externalDescription,
  };
}

function createMeasureFromBatMetadata(
  batName: MetadataDefinedPrimaryTypes,
  fieldName: string,
  other?: Omit<
    BiMeasureFieldMeta,
    'type' | 'fieldId' | 'displayName' | 'description'
  > & {
    fieldFamilyMessageDescriptor?: MessageDescriptor;
  }
) {
  const meta = must(
    BatMetadata.getFieldMetadataMessageDescriptors(batName, fieldName),
    `No corresponding field '${fieldName}' found in BatMetadata for BAT '${batName}'`
  );

  return measureMeta({
    displayNameMessageDescriptor: meta.displayName,
    descriptionMessageDescriptor: meta.externalDescription,
    ...other,
  });
}

type ExternalFieldKeys = keyof typeof BI_DIMENSION_FIELDS_NO_ID;
type ExternalFields = {
  [K in ExternalFieldKeys]: (typeof BI_DIMENSION_FIELDS_NO_ID)[K] extends {
    isInternal: true;
  }
    ? never
    : K;
}[ExternalFieldKeys];

export function isKnownDimensionFieldId(
  fieldId: string
): fieldId is KnownDimensionFieldIds {
  return fieldId in BI_DIMENSION_FIELD_METAS;
}

export function isKnownExternalDimensionFieldId(
  fieldId: string
): fieldId is ExternalFields {
  return (
    isKnownDimensionFieldId(fieldId) &&
    !getDimensionMeta(fieldId).isInternal === true
  );
}

export function getExternalDimensionFields(): Array<ExternalFieldKeys> {
  return Object.keys(BI_DIMENSION_FIELDS_NO_ID).filter(
    (key): key is ExternalFieldKeys => isKnownExternalDimensionFieldId(key)
  );
}
