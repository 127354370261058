import { useRef, useState } from 'react';
import { Trans } from '@lingui/react/macro';

import { Stack, Box, Tooltip, useTheme } from '@mui/material';
import isEqual from 'lodash/isEqual';

import BoxLink from '@watershed/ui-core/components/BoxLink';
import Button from '@watershed/ui-core/components/Button';
import { Analytics } from '@watershed/analytics/analyticsUtils';
import { mixinSx, sxType } from '@watershed/style/styleUtils';
import { transition, NavSectionData, NavSectionItem } from './utils';
import NavChip from './NavChip';
import NavMenu from './NavMenu';
import { getSharedSidebarStyles } from './variants/sharedSidebarStyles';
import AddIcon from '@watershed/icons/components/Add';

function isActiveNavLink(
  entry: NavSectionItem,
  activeEntry: NavSectionItem | null
) {
  return isEqual(entry, activeEntry);
}

function useSx() {
  const theme = useTheme();
  const { COLORS, BOX_SHADOW_FOCUS, iconButtonSxProps } =
    getSharedSidebarStyles(theme);
  return {
    stickyNavSection: sxType({
      position: 'sticky',
      top: 0,
      backgroundColor: COLORS.BACKGROUND,
      zIndex: 1,
      paddingBlockEnd: 1,
      borderBlockEnd: `1px solid ${COLORS.BACKGROUND_ACTIVE}`,
      marginBlockEnd: 1,
    }),
    navSectionName: sxType({
      display: 'block',
      whiteSpace: 'nowrap',
      transition: transition('opacity'),
      opacity: 1,
      color: COLORS.TEXT_SECONDARY,
      paddingInline: 1,
      paddingBlockStart: 1,
      paddingBlockEnd: 0.5,

      '&[data-appears-collapsed="true"]': {
        opacity: 0,
      },
    }),
    navItem: sxType({
      position: 'relative',
      textDecoration: 'none',

      padding: '4px 8px',
      border: '0.5px solid transparent',
      borderRadius: 1,
      height: '32px',
      lineHeight: '32px',
      marginBlockEnd: '2px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: 1,
      transition: transition(['background-color', 72], ['color', 72]),
      color: COLORS.TEXT_PRIMARY,
      outline: 'none',

      '& svg': {
        flexShrink: 0,
        width: 16,
        height: 16,
      },

      '&:not(:has(svg))': {
        height: 28,
        // Finance: In collapsed state, active items would otherwise just show glow without content
        '[data-appears-collapsed="true"] &': {
          opacity: 0,
        },
      },
      // Note: This is a workaround for the fact that the BoxLink component.
      '& > div > span': {
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        fontWeight: theme.typography.fontWeightBold,
      },

      '&[aria-current="page"]': {
        borderColor: theme.palette.grey70,
        background: theme.palette.grey80,
        boxShadow: '0px 1px 0.5px 0px rgba(79, 89, 110, 0.08)',
      },

      '&[aria-disabled="true"]': {
        color: COLORS.TEXT_DISABLED,
        cursor: 'not-allowed',
      },
      '&:not([aria-disabled="true"])': {
        '&:hover, &:focus': {
          backgroundColor: COLORS.BACKGROUND_ACTIVE,
        },
        '&:focus-visible': {
          boxShadow: BOX_SHADOW_FOCUS,
        },
        '&:active': {
          backgroundColor: COLORS.BACKGROUND_ACTIVE,
          // Darken on click by 15% (down to 85%)
          // TODO: If we keep this, use a real color.
          filter: 'brightness(0.85)',
        },
      },

      // Finance: Nested nav groups
      'ul ul &': {
        paddingInlineStart: 4,
        '& > span': {
          fontWeight: theme.typography.fontWeightRegular,
        },
      },
      'ul ul ul &': {
        paddingInlineStart: 6,
      },
    }),
    permissionsTooltip: sxType({
      left: theme.spacing(-2),
    }),
    addButton: mixinSx(
      iconButtonSxProps,
      sxType({
        '[data-appears-collapsed="true"] + &': {
          display: 'none',
        },
      })
    ),
  };
}

export type NavSectionProps = {
  section: NavSectionData;
  activeEntry: NavSectionItem | null;
  appearsCollapsed: boolean;
  isSticky?: boolean;
  handleNavMenuHover?: (hovered: boolean) => void;
};

export default function NavSection({
  section,
  appearsCollapsed,
  activeEntry,
  isSticky,
  handleNavMenuHover,
}: NavSectionProps) {
  const sx = useSx();
  return (
    <Box
      component="section"
      sx={mixinSx(
        {
          paddingInline: 1,
          scrollSnapAlign: 'start',
        },
        isSticky && sx.stickyNavSection
      )}
    >
      {section.name && (
        <Box
          component="header"
          sx={
            section.onAdd
              ? {
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }
              : undefined
          }
        >
          <Box
            data-testid={section.dataTest}
            data-appears-collapsed={appearsCollapsed}
            component="span"
            sx={sx.navSectionName}
          >
            {section.name}
          </Box>
          {section.onAdd ? (
            <Button
              aria-label="Add"
              variant="text"
              size="small"
              isIcon
              data-testid={section.onAddDataTest}
              onClick={section.onAdd}
              startIcon={<AddIcon />}
              sx={sx.addButton}
            />
          ) : null}
        </Box>
      )}
      <NavSectionSubentries
        subentries={section.subentries}
        appearsCollapsed={appearsCollapsed}
        activeEntry={activeEntry}
        handleNavMenuHover={handleNavMenuHover}
      />
    </Box>
  );
}

function NavSectionSubentries({
  subentries,
  appearsCollapsed,
  activeEntry,
  handleNavMenuHover,
}: {
  subentries: Array<NavSectionItem>;
  appearsCollapsed: boolean;
  activeEntry: NavSectionItem | null;
  handleNavMenuHover?: (hovered: boolean) => void;
}) {
  return (
    <Box
      component="ul"
      data-appears-collapsed={appearsCollapsed}
      role="list"
      sx={{
        listStyleType: 'none',
        padding: 0,
        margin: 0,
      }}
    >
      {subentries.map((subentry) => (
        <NavSectionSubentry
          key={subentry.objectId ?? subentry.name}
          {...subentry}
          activeEntry={activeEntry}
          appearsCollapsed={appearsCollapsed}
          handleNavMenuHover={handleNavMenuHover}
        />
      ))}
    </Box>
  );
}

function NavSectionSubentry({
  activeEntry,
  appearsCollapsed,
  handleNavMenuHover,
  ...subentry
}: NavSectionItem & {
  activeEntry: NavSectionItem | null;
  appearsCollapsed: boolean;
  handleNavMenuHover?: (hovered: boolean) => void;
}) {
  const subentryRef = useRef<HTMLLIElement>(null);
  const sx = useSx();
  const isActive = isActiveNavLink(subentry, activeEntry);
  const [isNavItemHovered, setIsNavItemHovered] = useState(false);
  const lackingAccess = !subentry.hasPermission;
  const innard = (
    <Stack direction="row" gap={1} alignItems="center">
      {subentry.icon && <subentry.icon />}
      <Box
        component="span"
        sx={{
          '[data-appears-collapsed="true"] &': {
            opacity: 0,
          },
        }}
      >
        {subentry.name}
      </Box>
    </Stack>
  );

  const { COLORS } = getSharedSidebarStyles(useTheme());

  const handleMouseEnter = () => {
    if (handleNavMenuHover) {
      setIsNavItemHovered(true);
    }
  };

  const handleMouseLeave = () => {
    if (handleNavMenuHover) {
      setIsNavItemHovered(false);
      handleNavMenuHover(false);
    }
  };

  const navigationItemName = subentry.name;

  return (
    <li ref={subentryRef}>
      <Tooltip
        title={
          lackingAccess && subentry.name ? (
            <Trans>
              Your permissions don’t allow for accessing {navigationItemName}.
            </Trans>
          ) : (
            subentry.tooltip
          )
        }
        placement="right-start"
      >
        {lackingAccess || subentry.disabled ? (
          <Box component="span" sx={sx.navItem} aria-disabled>
            {innard}
          </Box>
        ) : (
          <BoxLink
            sx={mixinSx(
              sx.navItem,
              isNavItemHovered && {
                backgroundColor: COLORS.BACKGROUND_ACTIVE,
              }
            )}
            href={subentry.location}
            aria-current={isActive ? 'page' : undefined}
            onClick={() => {
              Analytics.action('navBarMenuItem', {
                label: subentry.name,
                to: subentry.location,
              });
            }}
            tabIndex={appearsCollapsed ? -1 : 0}
            newTab={subentry.openInNewTab}
            data-testid={`button-${subentry.name}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {innard}
            {subentry.menu && (
              <NavMenu
                name={subentry.menu.name}
                items={subentry.menu.items}
                handleNavMenuHover={handleNavMenuHover}
                loading={subentry.menu.loading}
                placeholderMessage={subentry.menu.placeholderMessage}
              />
            )}
            <NavChip
              chip={subentry.chip}
              chipTooltip={subentry.chipTooltip}
              isActive={isActive}
            />
          </BoxLink>
        )}
      </Tooltip>
      {!subentry.disabled && subentry.subentries && (
        <NavSectionSubentries
          subentries={subentry.subentries}
          appearsCollapsed={appearsCollapsed}
          activeEntry={activeEntry}
          handleNavMenuHover={handleNavMenuHover}
        />
      )}
    </li>
  );
}
