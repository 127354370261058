import { forwardRef } from 'react';
import Icon, { IconProps } from '../Icon';
const ChartHorizontalIcon = forwardRef<SVGSVGElement, IconProps>(
  (props, ref) => (
    <Icon ref={ref} viewBox="0 0 24 24" fill="currentColor" {...props}>
      <path d="M21 20a1 1 0 0 1 0 2H3a1 1 0 0 1-1-1V3a1 1 0 1 1 2 0v17h17Z" />
      <path d="M15 5a1 1 0 1 1 0 2H7a1 1 0 1 1 0-2h8Zm5 6a1 1 0 0 0-1-1H7a1 1 0 1 0 0 2h12a1 1 0 0 0 1-1Zm-10 4a1 1 0 1 1 0 2H7a1 1 0 1 1 0-2h3Z" />
    </Icon>
  )
);
export default ChartHorizontalIcon;
