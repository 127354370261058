import {
  CommonZodFieldProps,
  ListOption,
  ZodSelectFieldEditorType,
  getCommonFieldProps,
  makeZodField,
  makeZodForm,
  useFieldPropsFromTsController,
} from '@watershed/shared-frontend/components/ZodForm/ZodForm';
import financeFieldRegistry, {
  FINANCE_FILTER_COLUMNS_QUERY_PARAMS,
  getFinanceFieldLabel,
} from '@watershed/shared-universal/fund/financeFieldRegistry';
import {
  FinanceChartsConfigType,
  FinanceFiltersType,
  createFinanceSavedViewSchema,
  financeChartsConfig,
  financeFilterSchema,
} from '@watershed/shared-universal/apiSchemas/finance/zodFinance';
import {
  GQFinanceHighchartsChartKind,
  GQFinanceMetric,
  GQFlags,
  GQGridColumnFormat,
} from '@watershed/shared-universal/generated/graphql';
import z from 'zod';
import Button from '@watershed/ui-core/components/Button';
import { FinanceFilterSelector } from '../../fund/FinanceFilterSelector';
import Field from '@watershed/ui-core/components/Form/Field';
import { FinanceChartSelector } from '../../fund/FinanceChartSelector';
import { Stack } from '@mui/material';
import { useDialogConfirm } from '@watershed/ui-core/components/DialogConfirm';
import gql from 'graphql-tag';
import {
  useDeleteFinanceSavedViewMutation,
  useFinanceSavedViewFormQuery,
} from '@watershed/shared-frontend/generated/urql';
import { useRouter } from 'next/router';
import { routeForFinanceOverview } from '@watershed/shared-universal/dashboardRoutes';
import { getGqlResultDataBang } from '@watershed/shared-frontend/utils/errorUtils';
import isFetchingOrStale from '@watershed/shared-frontend/utils/isFetchingOrStale';
import omit from 'lodash/omit';
import TableIcon from '@watershed/icons/components/Table';
import SidebarRightIcon from '@watershed/icons/components/SidebarRight';
import DashboardIcon from '@watershed/icons/components/Dashboard';
import sortBy from 'lodash/sortBy';
import mapStrToEnum from '@watershed/shared-universal/utils/mapStrToEnum';
import { useFeatureFlag } from '../../../utils/FeatureFlag';
import { TestIds } from '@watershed/shared-universal/utils/testUtils';
import isNotNullish from '@watershed/shared-util/isNotNullish';

gql`
  mutation DeleteFinanceSavedView($input: DeleteFinanceSavedViewInput!) {
    deleteFinanceSavedView(input: $input) {
      id
    }
  }

  fragment FinanceTagForChartSelector on FinanceTag {
    id
    name
    columnFormat
  }

  query FinanceSavedViewForm {
    financeTags {
      id
      ...FinanceTagForChartSelector
    }
  }
`;

export const createFinanceSavedViewFormSchema =
  createFinanceSavedViewSchema.omit({
    orgId: true,
  });

export const updateFinanceSavedViewFormSchema =
  createFinanceSavedViewFormSchema.augment({
    id: z.string(),
  });

type CreateViewFormSchemaType = z.TypeOf<
  typeof createFinanceSavedViewFormSchema
>;
type UpdateViewFormSchemaType = z.TypeOf<
  typeof updateFinanceSavedViewFormSchema
>;

const [FinanceSavedViewZodForm] = makeZodForm([
  [financeFilterSchema, makeZodField(ZodFinanceFilterField)],
  [financeChartsConfig, makeZodField(ZodFinanceChartConfigField)],
]);

type LayoutOption =
  | 'FinanceLayoutChartsMetricsInPanel'
  | 'FinanceLayoutNoPanel'
  | 'FinanceLayoutChartFocus';

export const layoutOptions: Array<ListOption<LayoutOption>> = [
  {
    value: 'FinanceLayoutChartsMetricsInPanel',
    display: 'Table + sidebar',
    startIcon: <SidebarRightIcon />,
  },
  {
    value: 'FinanceLayoutNoPanel',
    display: 'Table only',
    startIcon: <TableIcon />,
  },
  {
    value: 'FinanceLayoutChartFocus',
    display: 'Dashboard',
    startIcon: <DashboardIcon />,
  },
];

const metricOptions = [
  {
    value: GQFinanceMetric.TotalEmissions,
    display: financeFieldRegistry.totalEmissions.headerName,
  },
  {
    value: GQFinanceMetric.FinancedEmissions,
    display: financeFieldRegistry.financedEmissions.headerName,
  },
  {
    value: GQFinanceMetric.EconomicIntensity,
    display: financeFieldRegistry.economicIntensity.headerName,
  },
  {
    value: GQFinanceMetric.RevenueIntensity,
    display: financeFieldRegistry.revenueIntensity.headerName,
  },
  {
    value: GQFinanceMetric.Waci,
    display: financeFieldRegistry.waci.headerName,
  },
  {
    value: GQFinanceMetric.Pcaf,
    display: financeFieldRegistry.pcaf.headerName,
  },
  { value: GQFinanceMetric.AssetCount, display: 'Asset count' },
  { value: GQFinanceMetric.HoldingCount, display: 'Holding count' },
  {
    value: GQFinanceMetric.TotalOutstandingAmount,
    display: financeFieldRegistry.outstandingAmountUsdMetric.headerName,
  },
];

const financeSavedViewZodFormProps = () => ({
  name: {
    label: 'Name',
    placeholder: 'Enter a view name…',
    fsUnmask: true,
  },
  filters: {
    label: 'Filters',
    fsUnmask: true,
  },
  layout: {
    label: 'Layout',
    editorType: ZodSelectFieldEditorType.SelectableTileGroup,
    listOptions: layoutOptions,
    fsUnmask: true,
    multiple: false,
    required: true,
    numColumns: 3,
  },
  metrics: {
    label: 'Metrics',
    editorType: ZodSelectFieldEditorType.SelectableTileGroup,
    multiple: true,
    listOptions: metricOptions,
    fsUnmask: true,
    hideSelectAffordance: false,
    numColumns: 2,
  },
  charts: {
    label: 'Charts',
    fsUnmask: true,
  },
});

export function CreateFinanceSavedViewForm({
  onSubmit,
  onClose,
  defaultValues,
}: {
  onSubmit: (input: CreateViewFormSchemaType) => void;
  onClose: () => void;
  defaultValues: Partial<CreateViewFormSchemaType>;
}) {
  return (
    <FinanceSavedViewZodForm
      defaultValues={defaultValues}
      schema={createFinanceSavedViewFormSchema}
      onSubmit={onSubmit}
      formProps={{
        submitButtonProps: {
          children: 'Save',
        },
        extraButtons: (
          <Button onClick={onClose} color="secondary">
            {/* // TODO: i18n (please resolve or remove this TODO line if legit) //  */}
            {/* eslint-disable-next-line @watershed/literals-must-be-i18n-ready  */}
            Cancel
          </Button>
        ),
        inline: true,
        sx: {
          rowGap: 2,
        },
      }}
      // @ts-expect-error Something funky with custom form defs
      props={financeSavedViewZodFormProps()}
    >
      {(fields) => {
        return (
          <>
            {fields.name}
            {fields.filters}
            {fields.layout}
            {fields.metrics}
            {fields.charts}
          </>
        );
      }}
    </FinanceSavedViewZodForm>
  );
}

export function UpdateFinanceSavedViewForm({
  isStandardView,
  initialValues,
  onSubmit,
  onClose,
}: {
  isStandardView: boolean;
  initialValues: UpdateViewFormSchemaType;
  onSubmit: (input: UpdateViewFormSchemaType) => void;
  onClose: () => void;
}) {
  const router = useRouter();
  const [, deleteFinanceSavedView] = useDeleteFinanceSavedViewMutation();
  const openConfirm = useDialogConfirm();
  const zodFormProps = financeSavedViewZodFormProps();

  return (
    // TODO: i18n (please resolve or remove this TODO line if legit)
    // eslint-disable-next-line @watershed/zodform-must-be-i18n-ready
    <FinanceSavedViewZodForm
      schema={updateFinanceSavedViewFormSchema}
      onSubmit={onSubmit}
      defaultValues={initialValues}
      formProps={{
        submitButtonProps: {
          children: 'Save',
        },
        extraButtons: (
          <Stack justifyContent="space-between" direction="row" width="100%">
            {!isStandardView && (
              <Button
                data-testid={TestIds.FinanceDeleteSavedViewButton}
                color="error"
                onClick={async () => {
                  const confirmed = await openConfirm({
                    title: `Are you sure?`,
                    message:
                      'This will delete the configured view for all users, but none of the underlying data will be deleted.',
                    confirmText: `Delete ${initialValues.name}`,
                  });

                  if (confirmed) {
                    await deleteFinanceSavedView({
                      input: { id: initialValues.id },
                    });
                    onClose();
                    await router.push(
                      routeForFinanceOverview(omit(router.query, 'viewId'))
                    );
                  }
                }}
              >
                {/* // TODO: i18n (please resolve or remove this TODO line if legit) //  */}
                {/* eslint-disable-next-line @watershed/literals-must-be-i18n-ready  */}
                Delete
              </Button>
            )}
            <Button sx={{ marginLeft: 'auto' }} onClick={onClose}>
              {/* // TODO: i18n (please resolve or remove this TODO line if legit) //  */}
              {/* eslint-disable-next-line @watershed/literals-must-be-i18n-ready  */}
              Cancel
            </Button>
          </Stack>
        ),
        inline: false,
      }}
      props={{
        ...zodFormProps,
        id: {
          hidden: true,
        },
        name: {
          ...zodFormProps.name,
          disabled: isStandardView,
        },
        // @ts-expect-error Something funky with custom form defs
        filters: {
          ...zodFormProps.filters,
          hidden: isStandardView,
        },
      }}
    >
      {(fields) => {
        return (
          <>
            {fields.name}
            {fields.filters}
            {fields.layout}
            {fields.metrics}
            {fields.charts}
          </>
        );
      }}
    </FinanceSavedViewZodForm>
  );
}

function ZodFinanceFilterField(props: CommonZodFieldProps) {
  const { onChange, value, ...tsProps } =
    useFieldPropsFromTsController<FinanceFiltersType>();

  const handleChange = (
    value: Array<{ field: string; value?: Array<string> | null }>
  ) => {
    // Flatten the value into object the API saves
    const newValue = value.reduce(
      (acc, record) => {
        acc[record.field] = record.value;
        return acc;
      },
      {} as Record<string, Array<string> | null | undefined>
    );

    onChange(newValue);
  };

  return (
    <Field {...props} inputId={tsProps.id} {...getCommonFieldProps(props)}>
      <FinanceFilterSelector
        onChange={handleChange}
        value={
          value
            ? Object.entries(value).map(([field, value]) => {
                return {
                  field,
                  value,
                };
              })
            : []
        }
        lockedFilters={{}} // TODO: Add locked filters? Is anything locked on an update?
        fullyLockedFilterFields={[]}
        sx={{
          paddingTop: 0,
          paddingX: 0,
        }}
        addButtonSx={{
          marginBottom: 0,
        }}
      />
    </Field>
  );
}

function ZodFinanceChartConfigField(props: CommonZodFieldProps) {
  const { onChange, value, ...tsProps } =
    useFieldPropsFromTsController<FinanceChartsConfigType>();

  const hasCurrencyDisplayChart = useFeatureFlag(
    GQFlags.AssetManagerCurrencyDisplay
  );
  const shouldRenameFund = useFeatureFlag(GQFlags.AssetManagerRenameFunds);

  const [result] = useFinanceSavedViewFormQuery();
  if (isFetchingOrStale(result)) {
    return null;
  }
  const data = getGqlResultDataBang(result);
  const { financeTags } = data;

  const handleChange = (
    value: Array<{
      primaryField: string;
      secondaryField: string;
      tertiaryField: string;
    }>
  ) => {
    // Turn this into the right shape for the API
    const newValue = value.map((record) => {
      return {
        metric: record.primaryField,
        dimension: record.secondaryField,
        chartKind:
          record.tertiaryField !== ''
            ? mapStrToEnum(record.tertiaryField, GQFinanceHighchartsChartKind)
            : null,
      };
    });

    onChange(newValue);
  };

  const removeUnhandled = (id: string, metric: string) => {
    // We currently do not the scope or subscope metrics
    // for most metrics, so we remove them from the options.
    if (id !== 'scope' && id !== 'subscope') {
      return true;
    }
    if (metric === 'totalEmissions' || metric === 'financedEmissions') {
      return true;
    }
    return false;
  };

  const mappedValue = (value ?? []).map((record) => {
    return {
      primaryField: record.metric ?? '',
      secondaryField: record.dimension ?? '',
      tertiaryField: record.chartKind ?? '',
    };
  });

  const CHART_METRICS = [
    financeFieldRegistry.totalEmissions.id,
    financeFieldRegistry.financedEmissions.id,
    financeFieldRegistry.economicIntensity.id,
    hasCurrencyDisplayChart
      ? financeFieldRegistry.economicIntensityDisplay.id
      : null,
    financeFieldRegistry.revenueIntensity.id,
    hasCurrencyDisplayChart
      ? financeFieldRegistry.revenueIntensityDisplay.id
      : null,
    financeFieldRegistry.waci.id,
    hasCurrencyDisplayChart ? financeFieldRegistry.waciDisplay.id : null,
    financeFieldRegistry.pcaf.id,
    financeFieldRegistry.assetCount.id,
    financeFieldRegistry.holdingCount.id,
    financeFieldRegistry.outstandingAmountTarget.id,
    hasCurrencyDisplayChart
      ? financeFieldRegistry.outstandingAmountDisplay.id
      : null,
  ].filter(isNotNullish);

  // TODO: I think this should be a resolve on the snapshot, similar to how we resolve the filter options, but
  // let's hold off on doing that until we are pulling the tags from the snapshot.
  const chartOptions = new Map(
    CHART_METRICS.map((metric) => {
      return [
        metric,
        [
          ...sortBy(
            FINANCE_FILTER_COLUMNS_QUERY_PARAMS.map((dimension) => {
              return {
                id: dimension,
                label: getFinanceFieldLabel(dimension, { shouldRenameFund }),
              };
            }).filter((filterOption) =>
              removeUnhandled(filterOption.id, metric)
            ),
            (o) => o.label
          ),
          ...sortBy(
            financeTags
              .filter((tag) => tag.columnFormat !== GQGridColumnFormat.Float)
              .map((tag) => {
                return { id: tag.id, label: tag.name };
              }),
            (o) => o.label
          ),
        ],
      ];
    })
  );

  return (
    <Field {...props} inputId={tsProps.id} {...getCommonFieldProps(props)}>
      <FinanceChartSelector
        onChange={handleChange}
        value={mappedValue}
        options={chartOptions}
      />
    </Field>
  );
}
