import { Box, SxProps, Theme } from '@mui/material';
import React, { useCallback } from 'react';
import { LOTTIE_SCRIPT_URL } from '../../constants/lottie';
import useLoadScript from '../../hooks/useLoadScript';

export type LottieContainerProps = {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
};

export default function LottieContainer({
  children,
  sx,
}: LottieContainerProps) {
  const isLottieLoaded = useCallback(() => {
    return !!window.lottie;
  }, []);

  const [lottieLoaded] = useLoadScript({
    scriptUrl: LOTTIE_SCRIPT_URL,
    isScriptLoaded: isLottieLoaded,
  });

  return <Box sx={sx}>{lottieLoaded ? children : null}</Box>;
}
