import { sleep } from '@watershed/shared-universal/utils/sleep';

export function isResponseOk(xhr: XMLHttpRequest) {
  return xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200;
}

export async function uploadWithRetries(
  url: string,
  file: File,
  fileId: string,
  setFileUploadProgress?: (progress: number) => void,
  { numRetries = 3, backoffMs = 0 } = {}
): Promise<XMLHttpRequest | null> {
  let xhr: XMLHttpRequest | null = null;

  for (let i = 0; i < numRetries; i++) {
    try {
      xhr = await makeUploadRequest(url, file, fileId, setFileUploadProgress);
    } catch (errOrXhr) {
      console.error('uploadWithRetries error', errOrXhr);
    }

    if (xhr && isResponseOk(xhr)) {
      return xhr;
    }

    if (backoffMs) {
      await sleep(backoffMs);
    }
  }

  // Return error response if we hit the retry limit
  return xhr;
}

async function makeUploadRequest(
  url: string,
  file: File,
  fileId: string,
  setFileUploadProgress?: (progress: number) => void
): Promise<XMLHttpRequest> {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.open('PUT', url);

    // Fires when readyState property has changed
    xhr.onreadystatechange = () => {
      if (isResponseOk(xhr)) {
        resolve(xhr);
      }

      // https://developer.mozilla.org/en-US/docs/Web/HTTP/Status
      if (xhr.status >= 400) {
        reject(xhr);
      }
    };

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentComplete = (event.loaded / event.total) * 100;
        setFileUploadProgress?.(percentComplete);
      }
    };

    xhr.upload.onerror = () => {
      reject(xhr);
    };

    xhr.send(file);
  });
}
