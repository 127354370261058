import { Box, Checkbox, Chip, Stack, Typography } from '@mui/material';
import { Trans } from '@lingui/react/macro';
import React from 'react';
import TextLink from '../../TextLink';
import { mixinSx } from '@watershed/style/styleUtils';
import usePaletteUtils from '../../../hooks/usePaletteUtils';
import { FileUploadStatus } from '../../../utils/FileUpload';

export type SheetsMap = Partial<Record<string, Array<SheetWithSelection>>>;

export type SheetWithSelection = {
  id: string;
  name: string;
  isSelected: boolean;
  status: FileUploadStatus;
};

/**
 * Internal component to `FileUploadDropzone`, used to show a list of sheets in an Excel file and
 * allow the user to select which ones they want to import.
 */
export function SheetSelection({
  sheets,
  onChange,
  tablePreviewer,
}: {
  sheets: Array<SheetWithSelection>;
  onChange: (sheets: Array<boolean>) => void;
  tablePreviewer?: (tableId: string) => React.ReactNode;
}) {
  const paletteUtils = usePaletteUtils();
  const selectedSheets = new Set(
    sheets.filter((s) => s.isSelected).map((s) => s.name)
  );
  const validSheets = sheets.filter((sheet) => sheet.status !== 'error');
  const selectedSheetsInFile = selectedSheets.size;
  const totalSheetsInFile = validSheets.length;
  return (
    <Stack paddingTop={1} paddingBottom={2}>
      <Stack direction="row" marginBottom={0.5}>
        <Typography sx={{ flexGrow: 1 }}>
          <Trans>
            Select sheets ({selectedSheetsInFile} of {totalSheetsInFile})
          </Trans>
        </Typography>
        <TextLink
          onClick={() => {
            if (selectedSheets.size === validSheets.length) {
              onChange(sheets.map(() => false));
            } else {
              onChange(sheets.map((sheet) => sheet.status !== 'error'));
            }
          }}
          // Add `type="button"` so that this doesn't submit the form if it's in one
          type="button"
        >
          {selectedSheets.size === validSheets.length ? (
            <Trans context="File sheet selection">Deselect all</Trans>
          ) : (
            <Trans context="File sheet selection">Select all</Trans>
          )}
        </TextLink>
      </Stack>
      <Box
        sx={mixinSx(
          {
            display: 'grid',
            gridTemplateColumns: sheets.length <= 4 ? '1fr' : 'repeat(2, 1fr)',
            gap: 1,
            padding: 1,
            borderRadius: 1,
            border: (theme) => `1px solid ${theme.palette.grey10}`,
            overflow: 'auto',
            maxHeight: 136,
          },
          paletteUtils.alwaysShowWebkitScrollbar
        )}
      >
        {sheets.map((sheet, index) => (
          <Box
            key={sheet.name}
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
            component="label"
          >
            <Checkbox
              checked={selectedSheets.has(sheet.name)}
              disabled={sheet.status === 'error'}
              onChange={(evt, checked) =>
                onChange([
                  ...sheets.slice(0, index).map((s) => s.isSelected),
                  checked,
                  ...sheets.slice(index + 1).map((s) => s.isSelected),
                ])
              }
            />
            <Chip sx={{ marginRight: 1 }} label={sheet.name} clickable />
            {tablePreviewer !== undefined && sheet.status !== 'error'
              ? tablePreviewer(sheet.id)
              : null}
          </Box>
        ))}
      </Box>
    </Stack>
  );
}
