import { Formik } from 'formik';
import { Trans, useLingui } from '@lingui/react/macro';
import DialogForm from '@watershed/ui-core/components/DialogForm';
import SelectField from '@watershed/ui-core/components/Form/SelectField';
import { MenuItem, Typography } from '@mui/material';
import { useAtom } from 'jotai';
import { atomLocale } from '@watershed/intl/frontend/atoms';
import { useSetPreferredLocaleMutation } from '@watershed/shared-frontend/generated/urql';
import BetaChip from '@watershed/ui-core/components/BetaChip';
import { useAvailableLocales } from '../../utils/useAvailableLocales';
import { LOCALES, SupportedLocale } from '@watershed/intl/constants';

interface LocaleDialogProps {
  open: boolean;
  onClose: () => void;
}

export const LocaleDialog: React.FC<LocaleDialogProps> = ({
  open,
  onClose,
}) => {
  const availableLocales = useAvailableLocales();
  const [, setPreferredLocale] = useSetPreferredLocaleMutation();
  const [locale, setLocale] = useAtom(atomLocale);
  const { t } = useLingui();

  const getLocaleName = (locale: string) => {
    // Intl.DisplayNames shows zh-CN as "Chinese (China)"
    // which is not what we want
    if (locale === 'zh-CN') {
      return '中文 （简体）'; // means  "Chinese (Simplified)"
    }

    return (
      new Intl.DisplayNames(locale, { type: 'language' }).of(locale) ??
      LOCALES[locale as SupportedLocale]
    );
  };

  return (
    <Formik
      initialValues={{ locale }}
      enableReinitialize
      onSubmit={async ({ locale }, { setSubmitting }) => {
        await setPreferredLocale({ input: { locale } }, { suspense: false });
        setLocale(locale);
        setSubmitting(false);
        onClose();
      }}
    >
      {({ isSubmitting }) => (
        <DialogForm
          open={open}
          onClose={onClose}
          header={{
            title: (
              <Trans context="The title of the dialog to switch the dashboard language">
                Change locale
              </Trans>
            ),
            actions: <BetaChip />,
          }}
          submit={t({
            message: 'Switch',
            context: 'The text of the button to switch the dashboard language',
          })}
          isSubmitting={isSubmitting}
        >
          <Typography>
            <Trans>
              Use Watershed in your preferred language, with professional
              translations from our translators. Since this feature is in beta,
              not all features may be fully translated yet.{' '}
              <a href="https://dashboard.watershedclimate.com/learn#changing-language-locale-on-watershed">
                Learn more
              </a>
            </Trans>
          </Typography>
          <SelectField id="locale">
            {availableLocales.map((locale) => (
              <MenuItem key={locale} value={locale}>
                {getLocaleName(locale)}
              </MenuItem>
            ))}
          </SelectField>
        </DialogForm>
      )}
    </Formik>
  );
};
