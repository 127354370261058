import { formatFileSize } from '@watershed/shared-universal/utils/helpers';
import { read as readXlsx } from 'xlsx';

export class RejectedFileError extends Error {}
export class EmptyFileError extends RejectedFileError {}
export class FileTooBigError extends RejectedFileError {}
export class PasswordProtectedExcelError extends RejectedFileError {}

export type FileUploadStatus = 'success' | 'error';
export enum FileType {
  Csv = '.csv',
  Excel = '.xlsx',
  Pdf = '.pdf',
  Word = '.docx',
  Jpeg = '.jpeg',
  Jpg = '.jpg',
  Png = '.png',
  Zip = '.zip',
  Json = '.json',
}

export const ALL_FILE_TYPES = Object.values(FileType);
export const FILE_TYPES_IMAGE = [FileType.Jpeg, FileType.Jpg, FileType.Png];

/**
 * Each file type has a corresponding MIME type. Check out MDN's common MIME types for details
 * @link https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
 */
const FILE_TYPE_TO_MIME_TYPE: Record<FileType, string> = {
  [FileType.Csv]: 'text/csv',
  [FileType.Excel]:
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  [FileType.Pdf]: 'application/pdf',
  [FileType.Word]:
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  [FileType.Jpeg]: 'image/jpeg',
  [FileType.Jpg]: 'image/jpeg',
  [FileType.Png]: 'image/png',
  [FileType.Zip]: 'application/zip',
  [FileType.Json]: 'application/json',
};

/**
 * Anything that uses react-dropzone uses a format for the files it accepts, mapping from
 * MIME type to file extension. This function generates that array for you!
 */
export function getAcceptObject(
  fileTypes: Array<FileType> | FileType | undefined
): Record<string, Array<string>> {
  const acceptObject: Record<string, Array<string>> = {};
  if (!fileTypes) {
    return acceptObject;
  }
  if (!Array.isArray(fileTypes)) {
    fileTypes = [fileTypes];
  }
  fileTypes.forEach((fileType) => {
    const mimeType = FILE_TYPE_TO_MIME_TYPE[fileType];
    if (!acceptObject[mimeType]) {
      acceptObject[mimeType] = [];
    }
    acceptObject[mimeType].push(fileType);
  });
  return acceptObject;
}

export type UploadFile = {
  fileId: string; // Despite the name, this is the UserUpload ID, not the FileMetadata ID
  filename: string;
  isImported: boolean;
  error?: string | null;
  uploadDate?: string | null;
};

export interface RejectedFile {
  message: string;
  name: string;
}

export async function validateFile(
  file: File,
  { maxSizeBytes }: { maxSizeBytes?: number }
) {
  if (file.size === 0) {
    throw new EmptyFileError(
      'This file appears to be empty. Please re-upload a valid file.'
    );
  } else if (maxSizeBytes && file.size > maxSizeBytes) {
    throw new FileTooBigError(
      `This file is too big. Please upload a file smaller than ${formatFileSize(
        maxSizeBytes
      )}`
    );
  } else if (file.name.endsWith(FileType.Excel)) {
    try {
      readXlsx(await file.arrayBuffer(), { bookProps: true });
    } catch (error) {
      if (
        error instanceof Error &&
        error.message?.includes('File is password-protected')
      ) {
        throw new PasswordProtectedExcelError(
          'This file is password-protected. Please upload an unlocked version'
        );
      }
    }
  }
}
