import { Box, Stack, Typography } from '@mui/material';
import CircularProgress from '@watershed/ui-core/components/CircularProgress';
import React from 'react';
import { mixinSx } from '@watershed/style/styleUtils';
import ErrorIcon from '@watershed/icons/components/Error';
import CheckmarkIcon from '@watershed/icons/components/Checkmark';

export type FileItemRowProps = React.PropsWithChildren<{
  icon: 'success' | 'error' | 'loading';
  title: LocalizedString;
  subtitle: LocalizedString;
  action: React.ReactNode;
  progress?: number;
}>;

/**
 * Internal component to `FileUploadDropzone`, used to show a generic file row with status in the
 * upload file table. Uses a status icon for success, error, or loading, and shows a progress bar
 * if the file is currently uploading. Also adds some metadata about the file, and allows for
 * actions/children to be passed in.
 */
export function FileItemRow({
  icon,
  title,
  subtitle,
  action,
  progress,
  children,
}: FileItemRowProps) {
  const inProgress =
    icon === 'loading' &&
    progress !== undefined &&
    progress > 0 &&
    progress < 100;

  const iconComponent = (
    <Box
      sx={{
        width: 24,
        height: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {icon === 'success' ? (
        <CheckmarkIcon size={16} color="success.main" />
      ) : icon === 'error' ? (
        <ErrorIcon size={16} color="error.dark" />
      ) : inProgress ? (
        <Box
          sx={{
            position: 'relative',
            display: 'inline-flex',
          }}
        >
          <CircularProgress
            sx={{ color: (theme) => theme.palette.divider }}
            size={20}
            thickness={4}
            value={100}
            variant="determinate"
          />
          <CircularProgress
            sx={{ position: 'absolute', left: 0 }}
            disableShrink
            size={20}
            thickness={4}
            variant="determinate"
            value={progress}
          />
        </Box>
      ) : (
        <Box
          sx={{
            position: 'relative',
            display: 'inline-flex',
          }}
        >
          <CircularProgress
            sx={{ color: (theme) => theme.palette.divider }}
            size={20}
            thickness={4}
            value={100}
            variant="determinate"
          />
          <CircularProgress
            sx={{ position: 'absolute', left: 0 }}
            disableShrink
            size={20}
            thickness={4}
            variant="indeterminate"
          />
        </Box>
      )}
    </Box>
  );

  return (
    <Box
      role="listitem"
      sx={{
        // Ensures we don't have a top double border
        '&:not(:first-child)': {
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        },
      }}
    >
      <Stack
        direction="row"
        gap={1.5}
        sx={mixinSx(
          {
            display: 'flex',
            alignItems: 'center',
            padding: '12px 20px',
          },
          icon === 'loading'
            ? {
                // When progress bar hides but we're still processing, add space to offset it
                backgroundColor: (theme) => theme.palette.primary.light,
              }
            : null
        )}
      >
        {iconComponent}
        <Box display="flex" flexDirection="column" flex="1" sx={{ mr: 2 }}>
          <Typography sx={{ wordWrap: 'break-word' }} variant="h4">
            {title}
          </Typography>
          <Typography variant="body3" sx={{ wordWrap: 'break-word' }}>
            {subtitle}
          </Typography>
        </Box>
        <Box marginLeft="auto">{action}</Box>
      </Stack>
      {children && (
        <Box
          sx={{
            paddingLeft: 8,
            paddingRight: 2,
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
}
