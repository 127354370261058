import SelectField, { SelectFieldProps } from './SelectField';
import {
  countries,
  Country,
  countryByCode,
} from '@watershed/shared-universal/countryConstants';
import { MenuItem } from '@mui/material';
import {
  AutocompleteField,
  AutocompleteFieldNonFormik,
  AutocompleteFieldProps,
} from './AutocompleteField';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { ReactNode } from 'react';

export function CountryAutocompleteFieldNonFormik<
  Multiple extends boolean | undefined = undefined,
  DisabledClearable extends boolean | undefined = undefined,
>({
  id,
  label = 'Country',
  countryFilter,
  displayCountryCode = false,
  ...autocompleteFieldProps
}: {
  id: string;
  label?: ReactNode;
  countryFilter?: (country: Country) => boolean;
  displayCountryCode?: boolean;
} & Omit<
  AutocompleteFieldProps<string, Multiple, DisabledClearable>,
  'options'
>) {
  // Allow searching by country and country code
  const filterOptions = createFilterOptions({
    stringify: (option: string) => {
      return Object.values(countryByCode[option]).join('\n');
    },
  });

  const filteredCountries = countryFilter
    ? countries.filter(countryFilter)
    : countries;
  const countryOptions = filteredCountries.map((country) => country['alpha-2']);

  return (
    <AutocompleteFieldNonFormik
      {...autocompleteFieldProps}
      id={id}
      label={label}
      autocompleteOff
      options={countryOptions}
      filterOptions={filterOptions}
      getOptionLabel={(option) => {
        if (!option || option === '') {
          return '';
        }
        const country = countryByCode[option];
        if (!country) {
          // TODO: Alert us without breaking the page
          return '';
        }
        const displaySuffix = displayCountryCode
          ? ` (${country['alpha-2']})`
          : '';
        return `${country.name}${displaySuffix}`;
      }}
    />
  );
}

export function CountryAutocompleteField<
  Multiple extends boolean | undefined = undefined,
  DisabledClearable extends boolean | undefined = undefined,
>({
  id,
  label = 'Country',
  countryFilter,
  displayCountryCode = false,
  ...autocompleteFieldProps
}: {
  id: string;
  label?: LocalizedString | ReactNode;
  countryFilter?: (country: Country) => boolean;
  displayCountryCode?: boolean;
} & Omit<
  AutocompleteFieldProps<string, Multiple, DisabledClearable>,
  'options'
>) {
  // Allow searching both by sector & full label
  const filterOptions = createFilterOptions({
    stringify: (option: string) => {
      return Object.values(countryByCode[option]).join('\n');
    },
  });
  const filteredCountries = countryFilter
    ? countries.filter(countryFilter)
    : countries;
  const countryOptions = filteredCountries.map((country) => country['alpha-2']);
  return (
    <AutocompleteField
      {...autocompleteFieldProps}
      id={id}
      label={label}
      autocompleteOff
      options={countryOptions}
      getOptionLabel={(option) => {
        // Seems reasonable
        if (!option || option === '') {
          return '';
        }

        const country = countryByCode[option];

        // We probably want
        if (!country) {
          // TODO: Alert us without breaking the page
          return '';
        }
        const displaySuffix = displayCountryCode
          ? ` (${country['alpha-2']})`
          : '';
        return `${country.name}${displaySuffix}`;
      }}
      filterOptions={filterOptions}
    />
  );
}

export default function CountrySelectField(
  props: Omit<SelectFieldProps, 'children'>
) {
  return (
    <SelectField {...props}>
      {countries.map((country) => (
        <MenuItem key={country['alpha-2']} value={country['alpha-2']}>
          {country.name}
        </MenuItem>
      ))}
    </SelectField>
  );
}
