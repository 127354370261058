import {
  Autocomplete as MUIAutocomplete,
  AutocompleteProps as MUIAutocompleteProps,
  AutocompleteValue as Value,
  TextField,
  TextFieldProps,
  useTheme,
  PopperProps,
  Popper,
} from '@mui/material';
import CircularProgress from '@watershed/ui-core/components/CircularProgress';
import clsx from 'clsx';
import mergeWith from 'lodash/mergeWith';
import { ValidationState } from './Field';
import usePaletteUtils from '../../hooks/usePaletteUtils';
import { mixinSx } from '@watershed/style/styleUtils';

export type AutocompleteProps<
  T,
  Multiple extends boolean | undefined = undefined,
  DisabledClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> = Omit<
  MUIAutocompleteProps<T, Multiple, DisabledClearable, FreeSolo>,
  'renderInput' | 'components' | 'componentsProps'
> & {
  variant?: TextFieldProps['variant'];
  InputProps?: TextFieldProps['InputProps'];
  fsUnmask?: boolean;
  placeholder?: TextFieldProps['placeholder'];
  validationState?: ValidationState;
  validationMessage?: React.ReactNode;
  autocompleteOff?: boolean;
  autoFocus?: boolean;
  openOnFocus?: boolean;
  textFieldSx?: TextFieldProps['sx'];
};

const FONT_SIZE_SMALL = 13;
const FONT_SIZE_LARGE = 14;
const SVG_SIZE_SMALL = 12;
const SVG_SIZE_LARGE = 16;
const SVG_RIGHT_PADDING_SMALL = 5;
const SVG_RIGHT_PADDING_LARGE = 9;

export function GrowingSelectPopper(props: PopperProps) {
  return (
    <Popper
      sx={(theme) => ({
        width: 'fit-content !important',
        // the popper receives the width of the autocomplete as style.width, this let's us keep that as a minimum but grow with content
        minWidth: props.style?.width,
        maxWidth: '800px',
      })}
      {...props}
    />
  );
}

export function FixedWidthSelectPopper(props: PopperProps) {
  return <Popper {...props} style={{ width: '350px' }} />;
}

export function Autocomplete<
  T,
  Multiple extends boolean | undefined = undefined,
  DisabledClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>(props: AutocompleteProps<T, Multiple, DisabledClearable, FreeSolo>) {
  const {
    validationState,
    validationMessage,
    autocompleteOff,
    InputProps: initialInputProps,
    fsUnmask,
    textFieldSx: textFieldSxInitial,
    variant,
    ...autocompleteProps
  } = props;
  const isErrorState = validationState === 'error';
  const theme = useTheme();
  const paletteUtils = usePaletteUtils();

  // Reduce the size of options when size is "small".
  const slotProps =
    props.size === 'small'
      ? {
          paper: {
            sx: {
              fontSize: FONT_SIZE_SMALL,
              '& .MuiAutocomplete-listbox': { paddingX: 0, paddingY: 0.5 },
              // Specificity boost.
              '& .MuiAutocomplete-option.MuiAutocomplete-option': {
                paddingX: 1,
                paddingY: 0.25,
              },
            },
          },
        }
      : props.slotProps;

  let inputPadding: string;
  if (props.size === 'small') {
    if (props.disableClearable) {
      inputPadding = `0 ${
        SVG_SIZE_SMALL + SVG_RIGHT_PADDING_SMALL + 4
      }px 0 8px`;
    } else {
      inputPadding = `0 ${
        SVG_SIZE_SMALL * 2 + SVG_RIGHT_PADDING_SMALL + 4
      }px 0 8px`;
    }
  } else {
    if (props.disableClearable) {
      inputPadding = `4px ${
        SVG_SIZE_LARGE + SVG_RIGHT_PADDING_LARGE + 4
      }px 4px 12px`;
    } else {
      inputPadding = `4px ${
        SVG_SIZE_LARGE * 2 + SVG_RIGHT_PADDING_LARGE + 4
      }px 4px 12px`;
    }
  }

  const textFieldSx = mixinSx(
    {
      '& input::placeholder': paletteUtils.inputPlaceholderStyles,
      padding: 0,
      // Specificity boost.
      '& .MuiAutocomplete-endAdornment.MuiAutocomplete-endAdornment.MuiAutocomplete-endAdornment':
        props.size === 'small'
          ? {
              '& svg': { height: SVG_SIZE_SMALL, width: SVG_SIZE_SMALL },
              right: SVG_RIGHT_PADDING_SMALL,
              top: 0,
              bottom: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }
          : {},
      '& .MuiAutocomplete-clearIndicator': { padding: '2px' },
    },
    textFieldSxInitial
  );

  const inputPropsStyle = {
    ...props.InputProps?.style,
    fontSize: props.size === 'small' ? FONT_SIZE_SMALL : FONT_SIZE_LARGE,
    padding: inputPadding,
    backgroundColor: theme.palette.background.paper,
    ...(isErrorState ? { boxShadow: paletteUtils.boxShadowField.error } : {}),
    ...(validationState === 'warning'
      ? { boxShadow: paletteUtils.boxShadowField.warning }
      : {}),
  };

  return (
    <MUIAutocomplete
      {...autocompleteProps}
      size="small"
      fullWidth
      slotProps={slotProps}
      renderInput={(params) => {
        // start adornments on InputProps have to be deep combined here since the default chips are start adornments
        const InputProps = mergeWith(
          {},
          params.InputProps,
          initialInputProps,
          {
            style: inputPropsStyle,
          },
          (objValue, srcValue, key) => {
            if (key === 'className') return clsx(objValue, srcValue);
            if (key === 'startAdornment') {
              // The order is backwards but needs to be that way so the chips are after whatever the user provided start adornments are
              return (
                <>
                  {srcValue}
                  {objValue}
                </>
              );
            } else if (key === 'endAdornment') {
              return (
                <>
                  {objValue}
                  {srcValue}
                </>
              );
            }
            // fall back otherwise to regular merge
          }
        );
        return (
          <>
            <TextField
              {...params}
              sx={textFieldSx}
              autoFocus={props.autoFocus ?? false}
              InputProps={InputProps}
              inputProps={{
                ...params.inputProps,
                className: clsx(
                  params.inputProps.className,
                  fsUnmask && 'fs-unmask'
                ),
                autoComplete: autocompleteOff && 'off',
                'aria-autocomplete': props['aria-autocomplete'],
              }}
              variant={variant ?? 'outlined'}
              placeholder={props.placeholder}
              error={isErrorState}
              helperText={isErrorState ? validationMessage : null}
            />
            {props.loading && (
              <CircularProgress
                size={props.size === 'small' ? 12 : 20}
                sx={{ position: 'absolute', left: 12, top: 6 }}
              />
            )}
          </>
        );
      }}
      onClose={(evt, reason) => {
        if (reason === 'blur') {
          if (!evt) {
            // This can happen if the user enters a search term that doesn't
            // match any of the happens, then hits <enter>.
            return;
          }
          const value = (evt.target as HTMLInputElement).value;

          if (props.freeSolo && props.onChange) {
            props.onChange(
              evt,
              value as Value<T, Multiple, DisabledClearable, FreeSolo>,
              reason
            );
          } else {
            const possibleOptions = [];

            for (const option of props.options) {
              const label = props.getOptionLabel
                ? props.getOptionLabel(option)
                : typeof option === 'string'
                  ? option
                  : '';
              if (label.toUpperCase().includes(value.toUpperCase())) {
                if (
                  !props.getOptionDisabled ||
                  !props.getOptionDisabled(option)
                ) {
                  possibleOptions.push(option);
                }
              }
            }

            if (
              possibleOptions.length === 1 &&
              possibleOptions[0] !== props.value &&
              // only autoselect the sole option if the autcomplete is not clearable (or if the value somehow is not null and still doesn't match the sole option)
              (props.disableClearable || props.value != null)
            ) {
              if (props.onChange) {
                props.onChange(
                  evt,
                  (autocompleteProps.multiple
                    ? possibleOptions
                    : possibleOptions[0]) as Value<
                    T,
                    Multiple,
                    DisabledClearable,
                    FreeSolo
                  >,
                  reason
                );
              }
            }
          }
        }
      }}
      openOnFocus={props.openOnFocus ?? true}
      autoHighlight={props.autoHighlight ?? true}
    />
  );
}
